import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiSelect from '../../componentes/formulario/MiSelect';
import { Alert } from '../../componentes/ui/Alert';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import UplmPlus from '../../componentes/formulario/UplmPlus/UplmPlus';
import UplmPlus2 from '../../componentes/formulario/UpmlPlus2/UplmPlus2';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';
import Informes from '../../componentes/formulario/informes/Informes';
import Button from '../../componentes/ui/Button';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getTitle } from '../../helpers/helperApp';

import { Fragment } from 'react';

function Api(props){

    const cookies = new Cookies();

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin } = useLogicForm();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{  
                    document.title = getTitle(confPantalla.config.trad, confPantalla.api.title);                                                          
                    if( respuesta == 1 ){
                        saveTieneNivel(true);   
                        saveEstadoFormPrin(confPantalla.api.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.api.hijo.estadoform);                         
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.api.padre.urls);
                                saveFormularioPrin(confPantalla.api.padre.modelform);
                                saveFormularioHijo(confPantalla.api.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.api.padre.urls, confPantalla.api.hijo.urls, confPantalla.api.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 
        
    },[confPantalla])
    
    
    // Funciones para el manejo de los data
    const handleAddColumn = (e, datos, clave) => {

        let newVariable;
        if(clave == "datatab"){
            newVariable = {"title" : "", "data": "" };
        }else{
            newVariable = {"key" : "", "value": "" };
        }
        let variables;
        if(datos != "" ){
            variables = JSON.parse(datos);            
        }else{
            variables = [];
        }       
        variables.push(newVariable)

        saveFormularioPrin( {...formularioPrin, [clave]: JSON.stringify(variables) } );

    }

    const handleInputVar = (e, indice, ele, datos, clave) => {

        const valor = e.target.value;

        let variables = JSON.parse(datos);
        const editVar = variables.filter( (variable, index) =>  index == indice );
        editVar[0][ele] = valor;

        saveFormularioPrin( {...formularioPrin, [clave]: JSON.stringify(variables) } );

    }

    
    const handleDeleteVarTabla = (indice, datos, clave) => {
        let variables = JSON.parse(datos);
        const newVariables = variables.filter( (variable, index) =>  index != indice );
        saveFormularioPrin( {...formularioPrin, [clave]: JSON.stringify(newVariables) } );
    
    }

 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.api.section.titulo } icono={ confPantalla.api.section.icono } col={ confPantalla.api.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.api.section.wiki } >
                                        <Formulario 
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.api.padre.btnform.new }
                                                    btnSave={ confPantalla.api.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.api.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.api.padre.btnform.delete }
                                                    btnClone={ confPantalla.api.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.api.padre.urls, confPantalla.api.padre.modelform, confPantalla.api.padre.tabla, confPantalla.api.hijo.modelform, confPantalla.api.title) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.api.padre.modelform, true, confPantalla.api.padre.tabla) }  
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.api.padre.urls, confPantalla.api.padre.modelform, confPantalla.api.padre.tabla, confPantalla.api.title) }
                                        >
                                            <Informes
                                                select =     { confPantalla.api.padre.controles.informes.select }
                                                formulario = { formularioPrin }
                                                loadReg =    { loadReg ? true : false }
                                                estadoForm=  { estadoFormPrin } 
                                                traduction=  { confPantalla.config.trad.components }
                                            /> 
                                            <hr className="separator" />                                                                                   
                                            <FormRow clases="" >
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormPrin }
                                                    value=      { formularioPrin.nom }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.api.padre.controles.nom.id }   
                                                    type=       { confPantalla.api.padre.controles.nom.type }  
                                                    name=       { confPantalla.api.padre.controles.nom.name }   
                                                    label=      { confPantalla.api.padre.controles.nom.label }                                                       
                                                    placeholder={ confPantalla.api.padre.controles.nom.placeholder }    
                                                    col=        { confPantalla.api.padre.controles.nom.col } 
                                                    required=   { confPantalla.api.padre.controles.nom.required }
                                                    readonly=   { confPantalla.api.padre.controles.nom.readonly }                                                
                                                    disabled=   { confPantalla.api.padre.controles.nom.disabled }
                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                    onBlur=     {null} 
                                                />                                                        
                                                <MiSelect  
                                                    estadoForm=  { estadoFormPrin }
                                                    val=         { formularioPrin.app  }   
                                                    loading=     { loadReg ? true : false }
                                                    id=          { confPantalla.api.padre.controles.app.id }    
                                                    name=        { confPantalla.api.padre.controles.app.name }   
                                                    label=       { confPantalla.api.padre.controles.app.label }    
                                                    placeholder= { confPantalla.api.padre.controles.app.placeholder }
                                                    col=         { confPantalla.api.padre.controles.app.col } 
                                                    isClearable= { confPantalla.api.padre.controles.app.isclearable }
                                                    isSearchable={ confPantalla.api.padre.controles.app.issearchable }
                                                    dataType=    { confPantalla.api.padre.controles.app.datatype }
                                                    data=        { confPantalla.api.padre.controles.app.data }
                                                    required=    { confPantalla.api.padre.controles.app.required } 
                                                    disabled=  { confPantalla.api.padre.controles.app.disabled }
                                                    readOnly =   { confPantalla.api.padre.controles.app.readonly }
                                                    disablethisid={confPantalla.api.padre.controles.app.disablethisid}
                                                    formulario= { formularioPrin }   
                                                    change= { handleSelectFormPrin }
                                                />
                                                <MiSelect  
                                                    estadoForm=  { estadoFormPrin }
                                                    val=         { formularioPrin.typ  }   
                                                    loading=     { loadReg ? true : false }
                                                    id=          { confPantalla.api.padre.controles.typ.id }    
                                                    name=        { confPantalla.api.padre.controles.typ.name }   
                                                    label=       { confPantalla.api.padre.controles.typ.label }    
                                                    placeholder= { confPantalla.api.padre.controles.typ.placeholder }
                                                    col=         { confPantalla.api.padre.controles.typ.col } 
                                                    isClearable= { confPantalla.api.padre.controles.typ.isclearable }
                                                    isSearchable={ confPantalla.api.padre.controles.typ.issearchable }
                                                    dataType=    { confPantalla.api.padre.controles.typ.datatype }
                                                    data=        { confPantalla.api.padre.controles.typ.data }
                                                    required=    { confPantalla.api.padre.controles.typ.required } 
                                                    disabled=  { confPantalla.api.padre.controles.typ.disabled }
                                                    readOnly =   { confPantalla.api.padre.controles.typ.readonly }
                                                    disablethisid={confPantalla.api.padre.controles.typ.disablethisid}
                                                    formulario= { formularioPrin }   
                                                    change= { handleSelectFormPrin }
                                                />                                            
                                            </FormRow>
                                            <hr data-content={confPantalla.api.separator.autenticacion.title} className="hr-titleinfwhite">                                                                    
                                            </hr>
                                            <FormRow>
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormPrin }
                                                    value=      { formularioPrin.urltok }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.api.padre.controles.urltok.id }   
                                                    type=       { confPantalla.api.padre.controles.urltok.type }  
                                                    name=       { confPantalla.api.padre.controles.urltok.name }   
                                                    label=      { confPantalla.api.padre.controles.urltok.label }                                                       
                                                    placeholder={ confPantalla.api.padre.controles.urltok.placeholder }    
                                                    col=        { confPantalla.api.padre.controles.urltok.col } 
                                                    required=   { confPantalla.api.padre.controles.urltok.required }
                                                    readonly=   { confPantalla.api.padre.controles.urltok.readonly }                                                
                                                    disabled=   { confPantalla.api.padre.controles.urltok.disabled }
                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                    onBlur=     {null} 
                                                />
                                                <MiSelect  
                                                    estadoForm=  { estadoFormPrin }
                                                    val=         { formularioPrin.typtok  }   
                                                    loading=     { loadReg ? true : false }
                                                    id=          { confPantalla.api.padre.controles.typtok.id }    
                                                    name=        { confPantalla.api.padre.controles.typtok.name }   
                                                    label=       { confPantalla.api.padre.controles.typtok.label }    
                                                    placeholder= { confPantalla.api.padre.controles.typtok.placeholder }
                                                    col=         { confPantalla.api.padre.controles.typtok.col } 
                                                    isClearable= { confPantalla.api.padre.controles.typtok.isclearable }
                                                    isSearchable={ confPantalla.api.padre.controles.typtok.issearchable }
                                                    dataType=    { confPantalla.api.padre.controles.typtok.datatype }
                                                    data=        { confPantalla.api.padre.controles.typtok.data }
                                                    required=    { confPantalla.api.padre.controles.typtok.required } 
                                                    disabled=  { confPantalla.api.padre.controles.typtok.disabled }
                                                    readOnly =   { confPantalla.api.padre.controles.typtok.readonly }
                                                    disablethisid={confPantalla.api.padre.controles.typtok.disablethisid}
                                                    formulario= { formularioPrin }   
                                                    change= { handleSelectFormPrin }
                                                />
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormPrin }
                                                    value=      { formularioPrin.nomgettok }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.api.padre.controles.nomgettok.id }   
                                                    type=       { confPantalla.api.padre.controles.nomgettok.type }  
                                                    name=       { confPantalla.api.padre.controles.nomgettok.name }   
                                                    label=      { confPantalla.api.padre.controles.nomgettok.label }                                                       
                                                    placeholder={ confPantalla.api.padre.controles.nomgettok.placeholder }    
                                                    col=        { confPantalla.api.padre.controles.nomgettok.col } 
                                                    required=   { confPantalla.api.padre.controles.nomgettok.required }
                                                    readonly=   { confPantalla.api.padre.controles.nomgettok.readonly }                                                
                                                    disabled=   { confPantalla.api.padre.controles.nomgettok.disabled }
                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                    onBlur=     {null} 
                                                />
                                                <TextArea                                                        
                                                    estadoForm= { estadoFormPrin }
                                                    value=      { formularioPrin.datatok }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.api.padre.controles.datatok.id }   
                                                    name=       { confPantalla.api.padre.controles.datatok.name }
                                                    rows=       { confPantalla.api.padre.controles.datatok.rows }      
                                                    label=      { confPantalla.api.padre.controles.datatok.label }                                                       
                                                    placeholder={ confPantalla.api.padre.controles.datatok.placeholder }    
                                                    col=        { confPantalla.api.padre.controles.datatok.col } 
                                                    required=   { confPantalla.api.padre.controles.datatok.required }
                                                    readonly=   { confPantalla.api.padre.controles.datatok.readonly }                                                
                                                    disabled=   { confPantalla.api.padre.controles.datatok.disabled }
                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                    onBlur=     {null} 
                                                />
                                                <Button 
                                                    clases={ "btn-block mb-2" }
                                                    color={ "success" }  
                                                    type="button" 
                                                    icono={ "fal fa-plus"}   
                                                    id=""     
                                                    texto={ confPantalla.api.padre.controles.btnadd.title }   
                                                    accion={ e => handleAddColumn(e, formularioPrin.datatok, "datatok") } 
                                                    disabled={ loadReg ? true : false }
                                                />
                                            </FormRow>
                                            {
                                                formularioPrin.datatok != "" && formularioPrin.datatok != undefined
                                                ?
                                                    JSON.parse(formularioPrin.datatok).map((variable, index, arr) => {                                                    
                                                    return(
                                                        <Fragment>
                                                            {/* <hr data-content={(index+1)} className="hr-text"></hr> */}
                                                            <FormRow key={index}>
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { variable.key }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { index }   
                                                                    type=       { "text" }  
                                                                    label=      { confPantalla.api.padre.controles.variables.token.titulocolumna.title }
                                                                    placeholder={ "" }    
                                                                    col=        { "5" } 
                                                                    required=   { false }
                                                                    readonly=   { false }                                                
                                                                    disabled=   { false }
                                                                    change=     { e => handleInputVar(e, index, "key", formularioPrin.datatok, "datatok") }
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { variable.value }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { index }   
                                                                    type=       { "text" }  
                                                                    label=      { confPantalla.api.padre.controles.variables.token.valorcolumna.title }
                                                                    placeholder={ "" }    
                                                                    col=        { "5" } 
                                                                    required=   { false }
                                                                    readonly=   { false }                                                
                                                                    disabled=   { false }
                                                                    change=     { e => handleInputVar(e, index, "value", formularioPrin.datatok, "datatok") }
                                                                    onBlur=     {null} 
                                                                />
                                                                <div className={"mt-4 col-md-2"}>
                                                                    <Button 
                                                                        clases={ "btn-block mt-2" }
                                                                        color={ "danger" }  
                                                                        type="button" 
                                                                        icono={ "fal fa-trash-alt" }   
                                                                        id={index}     
                                                                        texto={ "" }   
                                                                        accion={ e => handleDeleteVarTabla(index, formularioPrin.datatok, "datatok") } 
                                                                        disabled={ false }
                                                                    />                    
                                                                </div>
                                                            </FormRow>
                                                        </Fragment>
                                                    )
                                                    })
                                                :
                                                    null
                                            }
                                            <hr data-content={confPantalla.api.separator.datos.title} className="hr-titleinfwhite"></hr>
                                            <FormRow clases="" >                                              
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormPrin }
                                                    value=      { formularioPrin.urldat }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.api.padre.controles.urldat.id }   
                                                    type=       { confPantalla.api.padre.controles.urldat.type }  
                                                    name=       { confPantalla.api.padre.controles.urldat.name }   
                                                    label=      { confPantalla.api.padre.controles.urldat.label }                                                       
                                                    placeholder={ confPantalla.api.padre.controles.urldat.placeholder }    
                                                    col=        { confPantalla.api.padre.controles.urldat.col } 
                                                    required=   { confPantalla.api.padre.controles.urldat.required }
                                                    readonly=   { confPantalla.api.padre.controles.urldat.readonly }                                                
                                                    disabled=   { confPantalla.api.padre.controles.urldat.disabled }
                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                    onBlur=     {null} 
                                                />
                                                <MiSelect  
                                                    estadoForm=  { estadoFormPrin }
                                                    val=         { formularioPrin.typdat  }   
                                                    loading=     { loadReg ? true : false }
                                                    id=          { confPantalla.api.padre.controles.typdat.id }    
                                                    name=        { confPantalla.api.padre.controles.typdat.name }   
                                                    label=       { confPantalla.api.padre.controles.typdat.label }    
                                                    placeholder= { confPantalla.api.padre.controles.typdat.placeholder }
                                                    col=         { confPantalla.api.padre.controles.typdat.col } 
                                                    isClearable= { confPantalla.api.padre.controles.typdat.isclearable }
                                                    isSearchable={ confPantalla.api.padre.controles.typdat.issearchable }
                                                    dataType=    { confPantalla.api.padre.controles.typdat.datatype }
                                                    data=        { confPantalla.api.padre.controles.typdat.data }
                                                    required=    { confPantalla.api.padre.controles.typdat.required } 
                                                    disabled=  { confPantalla.api.padre.controles.typdat.disabled }
                                                    readOnly =   { confPantalla.api.padre.controles.typdat.readonly }
                                                    disablethisid={confPantalla.api.padre.controles.typdat.disablethisid}
                                                    formulario= { formularioPrin }   
                                                    change= { handleSelectFormPrin }
                                                />
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormPrin }
                                                    value=      { formularioPrin.nomposttok }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.api.padre.controles.nomposttok.id }   
                                                    type=       { confPantalla.api.padre.controles.nomposttok.type }  
                                                    name=       { confPantalla.api.padre.controles.nomposttok.name }   
                                                    label=      { confPantalla.api.padre.controles.nomposttok.label }                                                       
                                                    placeholder={ confPantalla.api.padre.controles.nomposttok.placeholder }    
                                                    col=        { confPantalla.api.padre.controles.nomposttok.col } 
                                                    required=   { confPantalla.api.padre.controles.nomposttok.required }
                                                    readonly=   { confPantalla.api.padre.controles.nomposttok.readonly }                                                
                                                    disabled=   { confPantalla.api.padre.controles.nomposttok.disabled }
                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                    onBlur=     {null} 
                                                />                                                
                                                <TextArea                                                        
                                                    estadoForm= { estadoFormPrin }
                                                    value=      { formularioPrin.datadat }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.api.padre.controles.datadat.id }   
                                                    name=       { confPantalla.api.padre.controles.datadat.name }
                                                    rows=       { confPantalla.api.padre.controles.datadat.rows }      
                                                    label=      { confPantalla.api.padre.controles.datadat.label }                                                       
                                                    placeholder={ confPantalla.api.padre.controles.datadat.placeholder }    
                                                    col=        { confPantalla.api.padre.controles.datadat.col } 
                                                    required=   { confPantalla.api.padre.controles.datadat.required }
                                                    readonly=   { confPantalla.api.padre.controles.datadat.readonly }                                                
                                                    disabled=   { confPantalla.api.padre.controles.datadat.disabled }
                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                    onBlur=     {null} 
                                                />
                                                <Button 
                                                    clases={ "btn-block mb-2" }
                                                    color={ "success" }  
                                                    type="button" 
                                                    icono={ "fal fa-plus"}   
                                                    id=""     
                                                    texto={ confPantalla.api.padre.controles.btnadd.title }   
                                                    accion={ e => handleAddColumn(e, formularioPrin.datadat, "datadat") } 
                                                    disabled={ loadReg ? true : false }
                                                />
                                            </FormRow>
                                            {
                                                formularioPrin.datadat != "" && formularioPrin.datadat != undefined
                                                ?
                                                    JSON.parse(formularioPrin.datadat).map((variable, index, arr) => {                                                    
                                                    return(
                                                        <Fragment>
                                                            {/* <hr data-content={(index+1)} className="hr-text"></hr> */}
                                                            <FormRow key={index}>
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { variable.key }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { index }   
                                                                    type=       { "text" }  
                                                                    label=      { confPantalla.api.padre.controles.variables.peticion.titulocolumna.title }
                                                                    placeholder={ "" }    
                                                                    col=        { "5" } 
                                                                    required=   { false }
                                                                    readonly=   { false }                                                
                                                                    disabled=   { false }
                                                                    change=     { e => handleInputVar(e, index, "key", formularioPrin.datadat, "datadat") }
                                                                    onBlur=     {null} 
                                                                />
                                                                    <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { variable.value }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { index }   
                                                                    type=       { "text" }  
                                                                    label=      { confPantalla.api.padre.controles.variables.peticion.valorcolumna.title }
                                                                    placeholder={ "" }    
                                                                    col=        { "5" } 
                                                                    required=   { false }
                                                                    readonly=   { false }                                                
                                                                    disabled=   { false }
                                                                    change=     { e => handleInputVar(e, index, "value", formularioPrin.datadat, "datadat") }
                                                                    onBlur=     {null} 
                                                                />
                                                                <div className={"mt-4 col-md-2"}>
                                                                    <Button 
                                                                        clases={ "btn-block mt-2" }
                                                                        color={ "danger" }  
                                                                        type="button" 
                                                                        icono={ "fal fa-trash-alt" }   
                                                                        id={index}     
                                                                        texto={ "" }   
                                                                        accion={ e => handleDeleteVarTabla(index, formularioPrin.datadat, "datadat") } 
                                                                        disabled={ false }
                                                                    />                    
                                                                </div>
                                                            </FormRow>
                                                        </Fragment>
                                                    )
                                                    })
                                                :
                                                    null
                                            }                                                                                   
                                            <hr data-content={confPantalla.api.separator.tabla.title} className="hr-titleinfwhite"></hr>
                                            <small id="emailHelp" className="form-text text-muted text-center">{confPantalla.api.separator.helptabla.title}</small>
                                            <FormRow clases="" >
                                                <TextArea                                                        
                                                    estadoForm= { estadoFormPrin }
                                                    value=      { formularioPrin.datatab }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.api.padre.controles.datatab.id }   
                                                    name=       { confPantalla.api.padre.controles.datatab.name }
                                                    rows=       { confPantalla.api.padre.controles.datatab.rows }      
                                                    label=      { confPantalla.api.padre.controles.datatab.label }                                                       
                                                    placeholder={ confPantalla.api.padre.controles.datatab.placeholder }    
                                                    col=        { confPantalla.api.padre.controles.datatab.col } 
                                                    required=   { confPantalla.api.padre.controles.datatab.required }
                                                    readonly=   { confPantalla.api.padre.controles.datatab.readonly }                                                
                                                    disabled=   { confPantalla.api.padre.controles.datatab.disabled }
                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                    onBlur=     {null} 
                                                />
                                                <Button 
                                                    clases={ "btn-block mb-2" }
                                                    color={ "success" }  
                                                    type="button" 
                                                    icono={ "fal fa-plus"}   
                                                    id=""     
                                                    texto={ confPantalla.api.padre.controles.btnadd.title }   
                                                    accion={ e => handleAddColumn(e, formularioPrin.datatab, "datatab") } 
                                                    disabled={ loadReg ? true : false }
                                                />
                                            </FormRow>
                                            {
                                                formularioPrin.datatab != "" && formularioPrin.datatab != undefined
                                                ?
                                                    JSON.parse(formularioPrin.datatab).map((variable, index, arr) => {                                                    
                                                    return(
                                                        <Fragment>
                                                            {/* <hr data-content={(index+1)} className="hr-text"></hr> */}
                                                            <FormRow key={index}>
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { variable.title }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { index }   
                                                                    type=       { "text" }  
                                                                    label=      { confPantalla.api.padre.controles.variables.tabla.titulocolumna.title }
                                                                    placeholder={ "" }    
                                                                    col=        { "5" } 
                                                                    required=   { false }
                                                                    readonly=   { false }                                                
                                                                    disabled=   { false }
                                                                    change=     { e => handleInputVar(e, index, "title", formularioPrin.datatab, "datatab") }
                                                                    onBlur=     {null} 
                                                                />
                                                                    <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { variable.data }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { index }   
                                                                    type=       { "text" }  
                                                                    label=      { confPantalla.api.padre.controles.variables.tabla.valorcolumna.title }
                                                                    placeholder={ "" }    
                                                                    col=        { "5" } 
                                                                    required=   { false }
                                                                    readonly=   { false }                                                
                                                                    disabled=   { false }
                                                                    change=     { e => handleInputVar(e, index, "data", formularioPrin.datatab, "datatab") }
                                                                    onBlur=     {null} 
                                                                />
                                                                <div className={"mt-4 col-md-2"}>
                                                                    <Button 
                                                                        clases={ "btn-block mt-2" }
                                                                        color={ "danger" }  
                                                                        type="button" 
                                                                        icono={ "fal fa-trash-alt" }   
                                                                        id={index}     
                                                                        texto={ "" }   
                                                                        accion={ e => handleDeleteVarTabla(index, formularioPrin.datatab, "datatab") } 
                                                                        disabled={ false }
                                                                    />                    
                                                                </div>
                                                            </FormRow>
                                                        </Fragment>
                                                    )
                                                    })
                                                :
                                                    null
                                            }
                                        </Formulario>
                                        <MiDataTable
                                            formulario={formularioPrin} 
                                            estadoForm= { estadoFormPrin }
                                            id={"api"}
                                            columnas={confPantalla.api.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.api.padre.datatable.buttons}
                                            ordering={confPantalla.api.padre.datatable.ordering}
                                            order={confPantalla.api.padre.datatable.order}
                                            searching={confPantalla.api.padre.datatable.searching}
                                            paging={confPantalla.api.padre.datatable.paging}
                                            select={confPantalla.api.padre.datatable.select}
                                            pagelength={confPantalla.api.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.api.padre.urls, confPantalla.api.hijo.urls, confPantalla.api.padreshijos, confPantalla.api.hijo.modelform, confPantalla.api.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.api.padre.urls, confPantalla.api.padre.modelform, confPantalla.api.padre.tabla, confPantalla.api.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.api.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />                                             
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Api