import React, { useEffect, useState, useContext, Fragment } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import MiSelect from '../../componentes/formulario/MiSelect';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Informes from '../../componentes/formulario/informes/Informes';

import Spinner from 'react-bootstrap/Spinner'

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getTitle } from '../../helpers/helperApp';

import QRCode from 'qrcode.react';

function Macros(props){
    
    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");
    const [ labelUrl, saveLabelUrl ] = useState("");
    const [ placeholderUrl, savePlaceHolderUrl ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, saveFormularioHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleDeleteItemPrin, handleSubmitPrin, resetFormPrin, handleSelectFormPrin } = useLogicForm();
    

    useEffect( () => {

        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
        .then( (respuesta) => {
            if( confPantalla === undefined ){
                return getConfApp();
            }else{
                document.title = getTitle(confPantalla.config.trad, confPantalla.macros.title);                
                if( respuesta == 1 ){
                    saveTieneNivel(true);
                    saveEstadoFormPrin(confPantalla.macros.padre.estadoform);
                    saveEstadoFormHijo(confPantalla.macros.hijo.estadoform);                            
                    // ***** Carga inicial de registros *****
                    if( registrosPrin ){
                        if( registrosPrin.length === 0 ){
                            saveLoadingTable(true);
                            regIniciales(saveRegistrosPrin, confPantalla.macros.padre.urls);
                            saveFormularioPrin(confPantalla.macros.padre.modelform);
                            saveFormularioHijo(confPantalla.macros.hijo.modelform);   
                        }
                    }  
                    // Seleccionar el id que nos llega como parámetro por la url
                    if( props.location.state ){
                        if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                            handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.macros.padre.urls, confPantalla.macros.hijo.urls, confPantalla.macros.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                        }                        
                    }                      
                }
                else if( respuesta == 0 ){
                    saveTieneNivel(false);
                }                
                saveLoadedConf(true);        
            }
        })
        .then( (respuesta) => {
            if( respuesta !== undefined  ){
                saveConfPantalla(respuesta.msg);
                saveConfApp(respuesta.msg);             
            }
        }) 
        
        if( Object.keys(formularioPrin).length > 0){
            if( formularioPrin.idtip != '' ){
                setLabelPlaceholderUrl(formularioPrin.idtip);
            }else{
                setLabelPlaceholderUrl(null);
            }
        }

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
            document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla, formularioPrin.id])    

    const downloadQR = () => {
        const canvas = document.getElementById("qr");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qr.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

    }

    const handleSelectTipo = (selectedItem, nameOfComponent) => {

        if(selectedItem){
            setLabelPlaceholderUrl(selectedItem.value);
        }else{
            setLabelPlaceholderUrl(null);
        }

        handleSelectFormPrin(selectedItem, nameOfComponent)
    }

    const setLabelPlaceholderUrl = (value) => {

        if( value ){
            switch(value){
                case "1" :
                        saveLabelUrl("Id del adjunto");
                        savePlaceHolderUrl("Si el valor empieza # estaremos indicando que busque por id de adjunto, de lo contrario buscará por su descripción"); 
                    break;
                case "2" :
                case "3" :
                case "4" :
                        saveLabelUrl("Id del informe");
                        savePlaceHolderUrl("Establecer aquí el id del informe"); 
                    break;
                default :
                        saveLabelUrl("");
                        savePlaceHolderUrl("");
                    break;
            }
        }else{
            saveLabelUrl("");
            savePlaceHolderUrl("");
        }

        return value;
    }
   
    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>     
            {
                confPantalla !== undefined
                ?
                    <Fragment>
                        <Menusup 
                            props = { props }
                        />
                        <Sidebar 
                            props = { props }
                        />
                    </Fragment>
                :
                null
            } 
            <div className="content-wrapper">
                <div className="content-header">
                </div>
                <section className="content">
                {
                        !tieneNivel 
                        ?
                            <PermissionRefused
                                traduction={confPantalla.config.trad.components.permissionrefused} 
                            />
                        :
                        <Fragment>
                            {
                                loadedConf === true && confPantalla !== undefined
                                ?
                                <Section titulo={ confPantalla.macros.section.titulo } icono={ confPantalla.macros.section.icono } col={ confPantalla.macros.section.col } wiki={ confPantalla.macros.section.wiki } >
                                    <Formulario 
                                        estadoForm= { estadoFormPrin }
                                        loading=    { loadReg ? true : false }
                                        btnNew={ confPantalla.macros.padre.btnform.new }
                                        btnSave={ confPantalla.macros.padre.btnform.save }
                                        btnSaveAndNew={ confPantalla.macros.padre.btnform.saveandnew }
                                        btnDelete={ confPantalla.macros.padre.btnform.delete }
                                        btnClone={ confPantalla.macros.padre.btnform.clone }
                                        handleSubmit = { e => handleSubmitPrin(e, confPantalla.macros.padre.urls, confPantalla.macros.padre.modelform, confPantalla.macros.padre.tabla, confPantalla.macros.hijo.modelform, confPantalla.macros.title) } 
                                        formulario = { formularioPrin } 
                                        resetForm = { e => resetFormPrin(confPantalla.macros.padre.modelform, true, confPantalla.macros.padre.tabla) } 
                                        handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.macros.padre.urls, confPantalla.macros.padre.modelform, confPantalla.macros.padre.tabla, confPantalla.macros.title) } 
                                    >
                                        <Informes
                                            select =     { confPantalla.macros.padre.controles.informes.select }
                                            formulario = { formularioPrin }
                                            loadReg =    { loadReg ? true : false }
                                            estadoForm=  { estadoFormPrin } 
                                            traduction=  { confPantalla.config.trad.components }
                                        />
                                        <hr className="separator" />
                                        {/* <Tabs defaultActiveKey="tab1" id="tab1"> */}
                                            {/* <Tab eventKey="tab1" title={ confPantalla.macros.tabs.tab1.titulo }>                                     */}
                                                    <FormRow clases="" >
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idtip }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.macros.padre.controles.tipo.id }    
                                                            name=        { confPantalla.macros.padre.controles.tipo.name }   
                                                            label=       { confPantalla.macros.padre.controles.tipo.label }    
                                                            placeholder= { confPantalla.macros.padre.controles.tipo.placeholder }
                                                            col=         { confPantalla.macros.padre.controles.tipo.col } 
                                                            isClearable= { confPantalla.macros.padre.controles.tipo.isclearable }
                                                            isSearchable={ confPantalla.macros.padre.controles.tipo.issearchable }
                                                            dataType=    { confPantalla.macros.padre.controles.tipo.datatype }
                                                            data=        { confPantalla.macros.padre.controles.tipo.data }
                                                            required=    { confPantalla.macros.padre.controles.tipo.required } 
                                                            disabled=  { confPantalla.macros.padre.controles.tipo.disabled }
                                                            readOnly =   { confPantalla.macros.padre.controles.tipo.readonly }
                                                            disablethisid={confPantalla.macros.padre.controles.tipo.disablethisid}
                                                            formulario = { formularioPrin }   
                                                            change={ handleSelectTipo }                                                    
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idusu  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.macros.padre.controles.idusu.id }    
                                                            name=        { confPantalla.macros.padre.controles.idusu.name }   
                                                            label=       { confPantalla.macros.padre.controles.idusu.label }    
                                                            placeholder= { confPantalla.macros.padre.controles.idusu.placeholder }
                                                            col=         { confPantalla.macros.padre.controles.idusu.col } 
                                                            isClearable= { confPantalla.macros.padre.controles.idusu.isclearable }
                                                            isSearchable={ confPantalla.macros.padre.controles.idusu.issearchable }
                                                            dataType=    { confPantalla.macros.padre.controles.idusu.datatype }
                                                            data=        { confPantalla.macros.padre.controles.idusu.data }
                                                            required=    { confPantalla.macros.padre.controles.idusu.required } 
                                                            disabled=  { confPantalla.macros.padre.controles.idusu.disabled }
                                                            readOnly =   { confPantalla.macros.padre.controles.idusu.readonly }
                                                            disablethisid={confPantalla.macros.padre.controles.idusu.disablethisid}
                                                            formulario = { formularioPrin }   
                                                            change={ handleSelectFormPrin }                                                    
                                                        />
                                                    </FormRow>
                                                    <FormRow clases="" >
                                                        <FormInput 
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.url }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.macros.padre.controles.url.id }   
                                                            type=       { confPantalla.macros.padre.controles.url.type }  
                                                            name=       { confPantalla.macros.padre.controles.url.name }   
                                                            label=      { labelUrl != "" ? labelUrl : confPantalla.macros.padre.controles.url.label}                                                       
                                                            placeholder={ placeholderUrl != "" ? placeholderUrl : confPantalla.macros.padre.controles.url.placeholder }    
                                                            col=        { confPantalla.macros.padre.controles.url.col } 
                                                            required=   { confPantalla.macros.padre.controles.url.required }
                                                            readonly=   { confPantalla.macros.padre.controles.url.readonly }                                                
                                                            disabled=   { confPantalla.macros.padre.controles.url.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                    <FormRow clases="" >
                                                        <FormInput                                    
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.des }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.macros.padre.controles.des.id }   
                                                            type=       { confPantalla.macros.padre.controles.des.type }  
                                                            name=       { confPantalla.macros.padre.controles.des.name }   
                                                            label=      { confPantalla.macros.padre.controles.des.label }                                                       
                                                            placeholder={ confPantalla.macros.padre.controles.des.placeholder }    
                                                            col=        { confPantalla.macros.padre.controles.des.col } 
                                                            required=   { confPantalla.macros.padre.controles.des.required }
                                                            readonly=   { confPantalla.macros.padre.controles.des.readonly }                                                
                                                            disabled=   { confPantalla.macros.padre.controles.des.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null}
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.frun }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.macros.padre.controles.frun.id }   
                                                            type=       { confPantalla.macros.padre.controles.frun.type }  
                                                            name=       { confPantalla.macros.padre.controles.frun.name }   
                                                            label=      { confPantalla.macros.padre.controles.frun.label }                                                       
                                                            placeholder={ confPantalla.macros.padre.controles.frun.placeholder }    
                                                            col=        { confPantalla.macros.padre.controles.frun.col } 
                                                            required=   { confPantalla.macros.padre.controles.frun.required }
                                                            readonly=   { confPantalla.macros.padre.controles.frun.readonly }                                                
                                                            disabled=   { confPantalla.macros.padre.controles.frun.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                    {
                                                        formularioPrin.uuid != null && formularioPrin.uuid != "" 
                                                            ?                 
                                                                // level = ('L' 'M' 'Q' 'H')
                                                                <div>
                                                                    <div className='flex-center'>
                                                                        <h5>{window.location.origin+"/runmac/"+formularioPrin.uuid} </h5>
                                                                    </div>
                                                                    <div className='flex-center'>
                                                                        <QRCode 
                                                                            id="qr"
                                                                            value={window.location.origin+"/runmac/"+formularioPrin.uuid} 
                                                                            renderAs="canvas" 
                                                                            level="M" 
                                                                            size={512}
                                                                        />
                                                                    </div>
                                                                    <div className='flex-center mb-3'>
                                                                        <button
                                                                            type="button" 
                                                                            className="btn btn-flat btn-outline-success mt-1"
                                                                            style={{width : "512px"}}
                                                                            onClick={ downloadQR }                                                                                                
                                                                        >
                                                                            <i className='fal fa-download'></i>
                                                                        </button>
                                                                    </div>
                                                                </div>                           
                                                            :
                                                                null
                                                    }
                                            {/* </Tab> */}
                                            {/* <Tab eventKey="tab2" title={ confPantalla.macros.tabs.tab2.titulo }>      
                                                <Informes
                                                    select =     { confPantalla.macros.padre.controles.informes.select }
                                                    formulario = { formularioPrin }
                                                    loadReg =    { loadReg ? true : false }
                                                    estadoForm=  { estadoFormPrin } 
                                                    traduction=  { confPantalla.config.trad.components }
                                                    />                               
                                            </Tab> */}
                                        {/* </Tabs> */}
                                    </Formulario>                                                      
                                <MiDataTable
                                    formulario={formularioPrin} 
                                    estadoForm= { estadoFormPrin }
                                    id={"macros"}
                                    columnas={confPantalla.macros.padre.datatable.columns}
                                    data={registrosPrin}
                                    buttons={confPantalla.macros.padre.datatable.buttons}
                                    ordering={confPantalla.macros.padre.datatable.ordering}
                                    order={confPantalla.macros.padre.datatable.order}
                                    searching={confPantalla.macros.padre.datatable.searching}
                                    paging={confPantalla.macros.padre.datatable.paging}
                                    select={confPantalla.macros.padre.datatable.select}
                                    pagelength={confPantalla.macros.padre.datatable.pagelength}
                                    handleEditReg= { e => handleEditRegPrin(e, confPantalla.macros.padre.urls, confPantalla.macros.hijo.urls, confPantalla.macros.padreshijos, confPantalla.macros.hijo.modelform, confPantalla.macros.padre.tabla) }
                                    handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.macros.padre.urls, confPantalla.macros.padre.modelform, confPantalla.macros.padre.tabla, confPantalla.macros.title) }
                                    saveLoadingTable={ saveLoadingTable }
                                    loadingTable={loadingTable}
                                    columnactions={confPantalla.macros.padre.datatable.columnactions.title}
                                    traduction={confPantalla.config.trad.components.datatable}
                                /> 
                                </Section>                                     
                                :
                                <LoadingPage />
                            }
                        </Fragment>
                }
                </section>
            </div>
            <Footer />        
        </div>
    );

}
export default Macros