import React, { useEffect, useState, Fragment } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import Spinner from 'react-bootstrap/Spinner';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';


import { SRLWrapper } from 'simple-react-lightbox';

import { getApiUrl, getPantDocFromNivel } from '../../helpers/helperApp';

require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-buttons');
require('datatables.net-bs4');

require('datatables.net/js/jquery.dataTables');
require('datatables.net-buttons/js/dataTables.buttons');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-select');
require('datatables.mark.js/build/templates/datatables.mark');
require('datatables.mark.js/dist/datatables.mark.es6');
require('datatables.net-rowreorder-bs4/js/rowReorder.bootstrap4');
require('datatables.net-rowreorder');

$.DataTable = DataTable;

let date = new Date();
let mesHoy = date.getMonth() + 1;
let h = date.getDate();
let hoy = (h < 10 ? h = '0'+h : h = h )+'/'+(mesHoy < 10 ? mesHoy = '0'+mesHoy : mesHoy = mesHoy )+'/'+date.getFullYear();


function MiDataTableRunciccfg(props){
    var botones = [
        {
            extend: 'pageLength',
            text: `<i style='font-size: 20px;' class='fal fa-list-ol'></i>`,
            className: 'btn btn-flat mr-1 btn-outline-secondary',
            // titleAttr: 'Ver número de registros'
        },
        // {
        //     extend: 'copy',
        //     text: `<i style='font-size: 20px;' class='fal fa-copy'></i>`,
        //     className: 'btn btn-flat mr-1 btn-outline-secondary',
        //     // titleAttr: 'Copiar al portapapeles',
        //     exportOptions: {
        //         columns: ':visible'
        //     }
        // }, 
        // {
        //     extend: 'csv',
        //     filename: hoy,
        //     text: `<i style='font-size: 20px;' class='fal fa-file-csv'></i>`,
        //     className: 'btn btn-flat mr-1 btn-outline-secondary',
        //     title: hoy,
        //     // titleAttr: 'Exportar a csv',
        //     exportOptions: {
        //         columns: ':visible'
        //     }
    
        // },
        {
            extend: 'excel',
            filename: hoy,
            text: `<i style='font-size: 20px;' class='fal fa-file-excel'></i>`,
            className: 'btn btn-flat mr-1 btn-outline-secondary',
            title: hoy,
            // titleAttr: 'Exportar a excel',
            exportOptions: {
                columns: ':visible'
            }
        }, 
        {
            extend: 'pdf',
            filename: hoy,
            text: `<i style='font-size: 20px;' class='fal fa-file-pdf'></i>`,
            className: 'btn btn-flat mr-1 btn-outline-secondary',
            orientation: 'landscape', 
            title: hoy,
            // titleAttr: 'Exportar a pdf',
            exportOptions: {
                columns: ':visible'
            }
        }, 
        // {
        //     extend: 'print',
        //     filename: hoy,
        //     text: `<i style='font-size: 20px;' class='fal fa-print'></i>`,
        //     className: 'btn btn-flat mr-1 btn-outline-secondary',
        //     title: hoy,
        //     // titleAttr: 'Imprimir',
        //     customize: function ( win ) {
        //         $(win.document.body)
        //             .css( 'font-size', '22pt' );
    
        //         $(win.document.body).find( 'table' )
        //             .addClass( 'compact' )
        //             .css( 'font-size', '14pt' );
        //     },
        //     exportOptions: {
        //         modifier: {
        //             selected: null
        //         }
        //     },
        //     exportOptions: {
        //         columns: ':visible'
        //     }
        // },
        {
            extend: 'colvis',
            text: `<i style='font-size: 20px;' class='fad fa-line-columns'></i>`,
            className: 'btn btn-flat mr-1 btn-outline-secondary',
        }
    ];


    const cookies = new Cookies();

    // ***** props *****
    const { id, data, columnas, formulario, selectId } = props;
    let { buttons, ordering, order, searching, paging, select, handleEditReg, handleDeleteItem, saveLoadingTable, loadingTable, estadoForm, columnactions, traduction, linkColumns, functionClickTd, pagelength, columnsFunctionClickTd, txtFilterTable, noDisplay, rowreorder, functionAfterReorder, functionAfterSelect, cantidad, saveCantidad } = props;

    const [noDisplayState, saveNoDisplayState] = useState(noDisplay);


    const existenBotones = (botones) => {    
        let data = false;
        botones.map(boton => {                    
            if(boton[0] != undefined){
                if(boton[0].className.includes('btnSeleccion')){
                    data = true;
                }
            }
        })
        return data;
    }

    // En las tablas que tengan activadas la selección le metemos las opciones de "Marcar todo", "Desmarcar Todo" e "Invertir selección"
    if( select !== undefined && select !== false ){
        if( select.style == "multi" ){
            if( !existenBotones(botones) ){
                const newBtns = [
                    {
                        extend: 'collection',
                        text: "<i style='font-size: 20px' class='fal fa-check'></i>",
                        className: 'btn btn-flat mr-1 btn-outline-secondary btnSeleccion',
                        buttons: [
                            {
                                // text: "<i style='font-size: 20px' class='fas fa-ballot-check'></i>",
                                text: "<i style='font-size: 20px' class='fal fa-check-square'></i>",
                                className: 'dt-button buttons-columnVisibility',
                                action: function ( e, dt, node, config ) {
                                    actionsBtnSelector( 0 );
                                }
                            },
                            {
                                // text: "<i style='font-size: 20px' class='fal fa-ballot-check'></i>",
                                text: "<i style='font-size: 20px' class='fal fa-square'></i>",
                                className: 'dt-button buttons-columnVisibility',
                                action: function ( e, dt, node, config ) {
                                    actionsBtnSelector( 1 );
                                }
                            },
                            {
                                // text: "<i style='font-size: 20px' class='fad fa-ballot-check'></i>",
                                text: "<i style='font-size: 20px' class='fas fa-check-square'></i>",
                                className: 'dt-button buttons-columnVisibility',
                                action: function ( e, dt, node, config ) {
                                    actionsBtnSelector( 2 );
                                }
                            }
                        ]
                    }
                ];
                botones.push(newBtns);
            }
        }
    }
    
    useEffect(()=>{
        
        const accionesMiDataTable = columnas.find(column => column["title"] === columnactions);

        // Si ya existe la columna de acciones la eliminamos ya que sino da error cuando seleccionas un reg, vas a otra pantalla, vuelves y seleccionas otro reg.        
        if( accionesMiDataTable !== undefined ){
            columnas.splice( (columnas.length-1), 1 );
        }

        // Añadimos los enlaces a los td
        if( linkColumns != undefined ){
            for( let i = 0; i < columnas.length; i++ ){
                columnas[i].createdCell = (td, cellData, rowData, row, col) => {

                    if( linkColumns[i] != undefined ){
                        // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                        if ('style' in rowData){
                            $(td).addClass(rowData["style"]);
                        }
                        //Creamos los enlaces
                        if( typeof cellData == "string" ){
                            if( cellData.toLowerCase() != "Media".toLowerCase()  && cellData != "Average".toLowerCase() && cellData.toLowerCase() != "Total".toLowerCase() ){
                                // Si el enlace es a la pantalla de documentos obtenemos la pantalla que le corresponde según su id
                                if( linkColumns[i].link == "/documentos" ){
                                    linkColumns[i].link = getPantDocFromNivel(cookies.get('nivel'));
                                }

                                if( linkColumns[i].data.includes("/") && linkColumns[i].link != "" ){
                                    const links = linkColumns[i].data.split("/");
                                    $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[links[0]]+"/"+rowData[links[1]]+"' >"+cellData+"</a>");
                                }else if( linkColumns[i].link != "" ){
                                    $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[linkColumns[i].data]+"' >"+cellData+"</a>");
                                }
                            }
                        }
                    } 
                    // Si la celda contiene la cadena '/assets/' y rowData hay una key foto asumimos que es una foto
                    if( 'foto' in rowData || 'img' in rowData ){
                        if( cellData ){
                            if( cellData.includes('/assets/')  ){
                                $(td).html("<img src='"+getApiUrl(window.location.origin)+cellData+"' style='width: 100px !important; cursor: pointer;' />");
                            }
                        }else{
                            $(td).html("<span></span>");
                        }
                    }                   
                    if( functionClickTd ){
                        if( columnsFunctionClickTd ){
                            if( columnsFunctionClickTd.includes(col) ){
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }
                        }else{
                            $(td).on('click', function (e) {
                                e.preventDefault();
                                functionClickTd(e, rowData);
                            });
                        }                        
                    }

                    // Modificaciones para la tabla de la pantalla kpis
                    if( id == "kpis" ){
                        if( typeof cellData == "string" && rowData.idcentro != -1 ){
                            if( cellData.toLowerCase().includes("Media".toLowerCase())  || cellData.toLowerCase().includes("Average".toLowerCase()) || cellData.toLowerCase().includes("Total".toLowerCase()) ){
                                $(td).addClass("tdAvg");
                                $(td).parent('tr').addClass("trAvg");
                            }
                        }
                        if( typeof cellData == "string" && rowData.idcentro == -1 ){
                            $(td).addClass("tdTotal");
                            $(td).parent('tr').addClass("trTotal");
                        }
                        if( col > 0 ){
                            $(td).addClass("text-right");
                        }
                        // % Correctivos
                        if( col == 4 ){
                            $(td)[0].innerText = $(td)[0].innerText+" %";

                            if( cellData < 5 ){
                                $(td).addClass("alert-success");
                            }
                            if( cellData >= 5 && cellData <= 10 ){
                                $(td).addClass("alert-warning");
                            }
                            if( cellData > 10 ){
                                $(td).addClass("alert-danger");
                            }
                        }
                        // Costes
                        if( col == 6 || col == 7 ){
                            if( cellData > 0 ){
                                $(td).addClass("alert-success");
                            }
                            if( cellData == 0 ){
                                $(td).addClass("alert-warning");
                            }
                            if( cellData < 0 ){
                                $(td).addClass("alert-danger");
                            }
                            $(td)[0].innerText = $(td)[0].innerText+" €";
                        }
                    }
                    // Modificaciones para la pantalla stockart
                    if( id == "stockart" ){
                        if( typeof cellData == "string" && rowData.id_ubicacion == -1 ){
                            $(td).addClass("tdTotal");
                            $(td).parent('tr').addClass("trTotal");
                        }
                        if( col > 1 ){
                            $(td).addClass("text-right");
                            
                            if( rowData.stock_min > 0 ){
                                if( parseInt(rowData.unidades) <= 0 ){
                                    $(td).addClass("alert-danger");
                                }
                                if( parseInt(rowData.unidades) > parseInt(rowData.stock_min) ){
                                    $(td).addClass("alert-success");
                                }
                                if( parseInt(rowData.unidades) <= parseInt(rowData.stock_min) ){
                                    $(td).addClass("alert-warning");
                                }
                            }else{
                                if( parseInt(rowData.unidades) <= 0 ){
                                    $(td).addClass("alert-danger");
                                }
                                if( parseInt(rowData.unidades) > 0 ){
                                    $(td).addClass("alert-success");
                                }
                            }
                        }
                    }
                }
            }
        }else{
            for( let i = 0; i < columnas.length; i++ ){
  
                columnas[i].createdCell = (td, cellData, rowData, row, col) => {

                        // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                        if ('style' in rowData){
                            $(td).addClass(rowData["style"]);
                        }
                        // Si la celda contiene la cadena '/assets/' y rowData hay una key foto asumimos que es una foto
                        if( 'foto' in rowData || 'img' in rowData ){
                            if( cellData ){
                                if( cellData.includes('/assets/')  ){

                                    let templateFoto = `
                                    <div>                                   
                                            <div class="containerImgTablePending">
                                                <img class="imgUplmTablePending" src=${getApiUrl(window.location.origin)+cellData} />
                                            </div>                                                                                                                                        
                                    </div>
                                    `;
                                    $(td).html(templateFoto);
                                    // $(td).html("<img src='"+getApiUrl(window.location.origin)+cellData+"' style='width: 100px !important; cursor: pointer' />");
                                }
                            }else{
                                $(td).html("<span></span>");
                            }
                        }
                        if( functionClickTd ){
                            if( columnsFunctionClickTd ){
                                if( columnsFunctionClickTd.includes(col) ){
                                    $(td).on('click', function (e) {
                                        e.preventDefault();
                                        functionClickTd(e, rowData);
                                    });
                                }
                            }else{
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }                        
                        }
                    // Modificaciones para la tabla de la pantalla kpis
                    if( id == "kpis" ){
                        if( typeof cellData == "string" && rowData.idcentro != -1 ){
                            if( cellData.toLowerCase().includes("Media".toLowerCase())  || cellData.toLowerCase().includes("Average".toLowerCase()) || cellData.toLowerCase().includes("Total".toLowerCase()) ){
                                $(td).addClass("tdAvg");
                                $(td).parent('tr').addClass("trAvg");
                            }
                        }
                        if( typeof cellData == "string" && rowData.idcentro == -1 ){
                            $(td).addClass("tdTotal");
                            $(td).parent('tr').addClass("trTotal");
                        }
                        if( col > 0 ){
                            $(td).addClass("text-right");
                        }
                        // % Correctivos
                        if( col == 4 ){
                            $(td)[0].innerText = $(td)[0].innerText+" %";

                            if( cellData < 5 ){
                                $(td).addClass("alert-success");
                            }
                            if( cellData >= 5 && cellData <= 10 ){
                                $(td).addClass("alert-warning");
                            }
                            if( cellData > 10 ){
                                $(td).addClass("alert-danger");
                            }
                        }
                        // Costes
                        if( col == 6 || col == 7 ){
                            if( cellData > 0 ){
                                $(td).addClass("alert-success");
                            }
                            if( cellData == 0 ){
                                $(td).addClass("alert-warning");
                            }
                            if( cellData < 0 ){
                                $(td).addClass("alert-danger");
                            }
                            $(td)[0].innerText = $(td)[0].innerText+" €";
                        }
                    }  
                    // Modificaciones para la pantalla stockart
                    if( id == "stockart" ){
                        if( typeof cellData == "string" && rowData.id_ubicacion == -1 ){
                            $(td).addClass("tdTotal");
                            $(td).parent('tr').addClass("trTotal");
                        }
                        if( col > 0 ){
                            $(td).addClass("text-right");
                        }
                    }
                }
            }
        }
    
        if( columnactions != null && columnactions != "null" && columnactions != "" ){
            columnas.push({      
                title: columnactions,
                data: null,
                className: 'text-center',
                sortable: false,
                createdCell : (td, cellData, rowData, row, col) => { 

                                let templateBtn =  ReactDOM.render(<div className="flex-center">
                                                            <span 
                                                                id={ rowData.id } 
                                                                className=" btn btn-flat btn-outline-primary mr-1"
                                                                style={{border: 0}} 
                                                                onClick={ e => {handleEditReg(e) }} >
                                                                    <i 
                                                                        className="fal fa-edit" 
                                                                        id={ rowData.id } 
                                                                        onClick={ e => { handleEditReg(e) }} 
                                                                    >                                                    
                                                                    </i>
                                                            </span>
                                                            {
                                                                estadoForm !== "disabled" && estadoForm !== "readonly"
                                                                ?
                                                                    <span 
                                                                        id={ rowData.id } 
                                                                        data-idpadre={ rowData.iddoc ? rowData.iddoc : null } 
                                                                        className="btn btn-flat btn-outline-danger deleteRow" 
                                                                        style={{border: 0}}
                                                                        onClick={ e => { handleDeleteItem(e) } }>
                                                                            <i  
                                                                                    id={ rowData.id }
                                                                                    data-idpadre={ rowData.iddoc ? rowData.iddoc : null } 
                                                                                    className="fal fa-trash-alt deleteRow"
                                                                                    onClick={ e => { handleDeleteItem(e) }}
                                                                                >
                                                                            </i>
                                                                    </span>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    ,td);
                                $(td).html(templateBtn)
                }
            });
        }

        buttons === undefined || buttons === true || buttons === "true" ? buttons = 'Bfrtip' : buttons = 'frtip';
        ordering === undefined ? ordering = true : ordering = ordering;
        order === undefined ? order = [[0,"asc"]] : order = order;
        searching === undefined ? searching = true : searching = searching;
        paging === undefined ? paging = true : paging = paging;
        pagelength === undefined ? pagelength = traduction.defaultpagelength : pagelength = pagelength;
        select === undefined ? select = false : select = select;

        if(cantidad && saveCantidad){

            columnas.map((columna, i) => {                              
                if( i == 2 ){
                    // Cantidad editable
                    columna.createdCell = (td, cellData, rowData, row, col) => {
                        let templateNecesario =  ReactDOM.render(
                            <div className="">                                                                      
                                <input  type={ "number" } 
                                        className={ "form-control"} 
                                        id={ rowData.id } 
                                        name={"cantidad-"+rowData.id} 
                                        placeholder={ "" }
                                        defaultValue={ rowData.num1 }                                         
                                        readOnly={ false } 
                                        onChange={ e => handleCantidad(e, rowData) }                                         
                                />                                                                                                                             
                            </div>
                        ,td);
                        $(td).addClass(rowData.style);
                        $(td).html(templateNecesario)                                         
                    }
                }
            })
        }

        if( data ){
            if( data.length > 0 ){
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear( );
                    for (let i = 0; i < data.length; i++){
                        oTable.row.add(data[i]);      
                    }
                    oTable.draw();               
                }else{
                    $("."+id).DataTable({
                        mark: true,
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: data,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        rowReorder: rowreorder ? true : false,
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }        
                        },
                        rowCallback : function (row, data, index){ 
                            // Si td campo incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                            for (let item of row.children) {
                                if( item.outerText.includes("#") ){
                                    item.bgColor = item.outerText;
                                    item.innerText = "";
                                }
                            }
                        }
                    })
                }  
                // saveLoadingTable(false);       
            }else{
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear();
                    oTable.draw();               
                }{               
                    $("."+id).DataTable({
                        mark: true,
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: null,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        rowReorder: rowreorder ? true : false,
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }         
                        },
                        rowCallback : function (row, data, index){
                            // Si td campo incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                            for (let item of row.children) {
                                if( item.outerText.includes("#") ){
                                    item.bgColor = item.outerText;
                                    item.innerText = "";
                                }
                            }


                        }
                    })
                }
                // saveLoadingTable(false);
            }
        }else{
            if ( $.fn.DataTable.isDataTable("."+id) ) {
                let oTable = $("."+id).DataTable();
                oTable.clear();
                oTable.draw();               
            }{               
                $("."+id).DataTable({
                    mark: true,
                    rowId: function(a) {
                        return '#row-' + a.id;},
                    dom: buttons,
                    data: null,
                    columns: columnas,
                    ordering: ordering,
                    order: order,
                    searching: searching,
                    paging: paging,
                    select: select,
                    buttons: [botones],
                    // fixedHeader: true,
                    // scrollX: true,
                    responsive: false, 
                    autoWidth: false,
                    retrieve: true,
                    pageLength: parseInt(pagelength),
                    lengthMenu: [
                        [ 10, 25, 50, 100 ],
                        [ '10', '25', '50', '100' ]
                    ],
                    rowReorder: rowreorder ? true : false,
                    deferRender: true,
                    language: {
                        "sProcessing":    traduction.sprocessing.title,
                        "sLengthMenu":    traduction.slengthmenu.title,
                        "sZeroRecords":   traduction.szerorecords.title,
                        "sEmptyTable":    traduction.semptytable.title,
                        "sInfo":          traduction.sinfo.title,
                        "sInfoEmpty":     traduction.sinfoempty.title,
                        "sInfoFiltered":  traduction.sinfofiltered.title,
                        "sInfoPostFix":   traduction.sinfopostfix.title,
                        "sSearch":        traduction.ssearch.title,
                        "sUrl":           traduction.surl.title,
                        "sInfoThousands":  traduction.sinfothousands.title,
                        "sLoadingRecords": traduction.sloadingrecords.title,
                        "oPaginate": {
                            "sFirst":    traduction.sfirst.title,
                            "sLast":    traduction.slast.title,
                            "sNext":    traduction.snext.title,
                            "sPrevious": traduction.sprevious.title
                        },
                        "oAria": {
                            "sSortAscending":  traduction.ssortascending.title,
                            "sSortDescending": traduction.ssortdescending.title
                        },
                        select: {
                            rows: traduction.rows.title
                        },
                        buttons: {
                            copyTitle: traduction.copytitle.title,
                            copyKeys: traduction.copykeys.title,
                            copySuccess: {
                                _: traduction.copysuccess.many.title,
                                1: traduction.copysuccess.one.title
                            },
                        }       
                    },
                    rowCallback : function (row, data, index){
                        // Si algún td incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                        for (let item of row.children) {
                            if( item.outerText.includes("#") ){
                                item.bgColor = item.outerText;
                                item.innerText = "";
                            }
                        }                        
                    }
                })
            }
            // saveLoadingTable(false);
            
        }
        
        if( saveLoadingTable ){
            saveLoadingTable(false); 
        }

        // Código para llamar a una función tras reordenar las filas
        if( functionAfterReorder != null  ){
            let tabla = $('#'+id).DataTable();
            tabla.on( 'row-reorder', function ( e, diff, edit ) {
                functionAfterReorder( e, diff, edit);         
            } );
        }

        // Código para llamar a una función tras seleccionar una fila
        if( functionAfterSelect != null  ){
            let tabla = $('#'+id).DataTable();
            tabla.on( 'click', 'tr', function ( e, dt, type, indexes ) {
                if(e.currentTarget.id != ""){
                    setTimeout(()=> {
                        let idRow = tabla.row( this ).id();         
                        const datos = $('#'+id).DataTable().rows().data();
                        const selectedRows = $('#'+id).DataTable().rows( { selected: true } ).data();
                        functionAfterSelect(idRow, id, datos, selectedRows);                                                
                    }, 500)
                }
            } );
            // tabla.on( 'select', function ( e, dt, type, indexes ) {
            //     const datos = $('#'+id).DataTable().rows( indexes ).data();
            //     functionAfterSelect(datos, id);         
            // } );
            // tabla.on( 'deselect', function ( e, dt, type, indexes ) {
            //     const datos = $('#'+id).DataTable().rows( indexes ).data();
            //     functionAfterSelect(datos, id, true);         
            // } );
        }

        
    }, [data, formulario.id]) 
    
    const handleCantidad = (e, rowData) => {
        let datosAux = cantidad;
        if(cantidad.length == 0){
            const primero = { "idLin" : rowData.id, "cant" : e.target.value };
            datosAux.push(primero)            
        }else{
            let editando = false;
            datosAux = cantidad;
            datosAux.map((dato, index) => {
                if(dato.idLin == rowData.id){
                    dato.cant = e.target.value;
                    editando = true;
                }
            })
            if(!editando){
                datosAux.push({ "idLin" : rowData.id, "cant" : e.target.value });
            }
        }
        saveCantidad(datosAux);            
    }

    // Funcion que realiza las acciones de los botones de Marcar todos, Desmarcar todos e Invertir Selección
    function actionsBtnSelector ( boton ) {
        let oTable = $("."+id).DataTable();

        switch(boton){
            case 0:
                // Marcar todos
                oTable.rows({ search: 'applied' }).select();
                break;
            case 1:
                // Desmarcar todos
                oTable.rows({ search: 'applied' }).deselect();
                break;
            case 2:
                // Invertir selección
                var rows = oTable.rows( { selected: true, search: 'applied' } )[0];  
                oTable.rows({ search: 'applied' }).select();
                oTable.rows(rows).deselect();
                break;
        }
    }
    

    // Código para evitar los acentos en las búsquedas
    function removeAccents ( data ) {
        if ( data.normalize ) {
            // Use I18n API if avaiable to split characters and accents, then remove
            // the accents wholesale. Note that we use the original data as well as
            // the new to allow for searching of either form.
            return data +' '+ data
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
     
        return data;
    }
    var searchType = jQuery.fn.DataTable.ext.type.search;
 
    searchType.string = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data ) :
                data;
    };
    
    searchType.html = function ( data ) {    
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data.replace( /<.*?>/g, '' ) ) :
                data;
    };
    // FIN: Código para evitar los acentos en las búsquedas 

    // PRUEBAS PARA QUE AL BUSCAR UNA PALABRA CON TILDE MUESTRE LOS RESULTADOS CON Y SIN TILDE. SI BUSCAS SIN TILDE, LO HACE BIEN, MUESTRA LOS RESULTADOS CON Y SIN TILDE.
    // jQuery.fn.DataTable.ext.search.push(
    //     function() {
    //         let inputSearch = document.querySelector("#"+id+"_filter > label > input");
    //         if(inputSearch){     
    //             console.log(inputSearch.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));                
    //             return inputSearch.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    //         }else{
    //             return inputSearch.value;
    //         }
    //     }
    // );
   
    // let oTable = $("."+id).DataTable();
    // oTable.on( 'search.dt', function () {                        
    //     let inputSearch = document.querySelector("#"+id+"_filter > label > input");
    //     if(inputSearch){     
    //         console.log(inputSearch.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    //         // oTable.search(inputSearch.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')).draw();
    //     }
    // } );

    
    // let inputSearch = document.querySelector("#"+id+"_filter > label > input");
    // if(inputSearch){     
    //     inputSearch.addEventListener('input', () => {
    //         let oTable = $("."+id).DataTable();
    //         console.log(inputSearch.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    //         console.log("*********");
    //         oTable.search(inputSearch.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')).draw();
    //         console.log(oTable.search());
    //     })
        
    // }

    
    // Resaltar el row que estamos editando en los formularios
    if( formulario ){

        if( formulario.id != "" ){        
            detectSelectedLine(true);
        }

        $("."+id).on( 'page.dt', function () {
            detectSelectedLine();
        } );
    
        $("."+id).on( 'length.dt', function () {
            detectSelectedLine(true);
        } );
    }

    // Añadir clase a la línea del registro seleccionado
    function detectSelectedLine(go=false){        
        setTimeout(()=> {
            let trs = document.querySelectorAll("table#"+id+" tr.selectedRow");
            for( let i = 0; i < trs.length; i++ ){
                trs[i].classList.remove("selectedRow");
            }

            const tr = document.querySelector("span[id='"+formulario.id+"']");        
            if( tr ){
                tr.parentElement.parentElement.parentElement.classList.add("selectedRow");
            }

            if( go ){
                goToPage();
            }
            
        }, 100)
    }

    // Salta a la página del registro seleccionado
    function goToPage(){
        let oTable = $("."+id).DataTable();
        let info = oTable.page.info();

        // Utilizamos los registros filtrados a través del buscador
        const filteredRegs = oTable.rows( {search:'applied'} ).data();
        // let rows = oTable.rows().data();

        // Buscar la página del registro y saltar a ella
        let pos = 0;
        for( let i = 0; i < filteredRegs.length; i++ ){
            if( filteredRegs[i].id == formulario.id ){
                pos = i;
                break;
            }
        }
        if( pos > 0 ){
            oTable.page( Math.floor( pos / info.length ) ).draw( false );
        }
    }
    // FIN: Añadir clase a la línea del registro seleccionado

    // Seleccionar un id de la tabla
    if( selectId ){
        if( selectId.id != "" ){        
            detectSelectedLine2(selectId,true);
        }

        $("."+id).on( 'page.dt', function () {
            detectSelectedLine2(selectId);
        } );
    
        $("."+id).on( 'length.dt', function () {
            detectSelectedLine2(selectId,true);
        } );
    }
    function detectSelectedLine2(selectId, go=false){        
        setTimeout(()=> {
            if(selectId != null  && selectId.id ){
                let trs = document.querySelectorAll("table#"+id+" tr.selectedRow");
                for( let i = 0; i < trs.length; i++ ){
                    trs[i].classList.remove("selectedRow");
                }
                

                let table = $("#"+id).DataTable();
                let rows = table.rows().data();
                for(let i=0; i < rows.length; i++){
                    if( rows[i].id == selectId.id ){
                        table.row(':eq('+i+')', { page: 'current' }).select();
                    }
                }
    
                if( go ){
                    goToPage2(selectId);
                }
            }
            
        }, 100)
    }
    function goToPage2(selectId){
        let oTable = $("."+id).DataTable();
        let info = oTable.page.info();

        // Utilizamos los registros filtrados a través del buscador
        const filteredRegs = oTable.rows( {search:'applied'} ).data();
        // let rows = oTable.rows().data();

        // Buscar la página del registro y saltar a ella
        let pos = 0;
        for( let i = 0; i < filteredRegs.length; i++ ){
            if( filteredRegs[i].id == selectId.id ){
                pos = i;
                break;
            }
        }
        if( pos > 0 ){
            oTable.page( Math.floor( pos / info.length ) ).draw( false );
        }
    }    
    // FIN: Seleccionar un id de la tabla




    // Filtramos en la tabla un texto recibido como parámetros
    if( txtFilterTable != null && txtFilterTable != "" ){
        let tabla = $('#'+id).DataTable();
        tabla.search(txtFilterTable).draw();
    }


    return(
        <Fragment>
            {   loadingTable ? 
                    <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                        <Spinner animation="border" />
                    </div>
                :
                null
            }
            <div className={noDisplayState ? "noDisplay" : ""} style={{overflowX: "auto"}}>
                <table id={id} className={id+" table table-sm table-bordered table-striped table-hover dataTable"} style={{width:"100%"}} ></table>
            </div>   
            
        </Fragment>
    )
}

export default MiDataTableRunciccfg;