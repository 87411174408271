import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ButtonOutline from '../../componentes/ui/ButtonOutline';
import MiSelect from '../../componentes/formulario/MiSelect';
import swal from 'sweetalert';

import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { MiReplaceAll, getApiUrl, stringToHtml, axiosPost, getTitle } from '../../helpers/helperApp';

import { Fragment } from 'react';
import FormRow from '../../componentes/formulario/FormRow';

function ImpStoArt(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ respuestaImpArt, saveRespuestaImpArt ] = useState("");
    const [ loading, saveLoading ] = useState(false);
    const [ idCentro, saveIdCentro ] = useState();
    const [ optionsCentro, saveOptionsCentro ] = useState([]);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confApp.impstoart.section.titulo);
                    if( cookies.get('nivel') == 1 || cookies.get('nivel') == 2 || cookies.get('nivel') == 3 ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);                                                                            
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    
                    initOptionsSelect();

                    saveLoadedConf(true);
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                let input = document.getElementById('inputStoArt');
                if( input ){
                    input.remove();
                }
                
                // Token cancelación axios al desmontar componentes
                source.cancel();
        } 

    },[confPantalla, formularioHijo.id, respuestaImpArt])


    const initOptionsSelect = async() => {
        
        const centro = confApp.impstoart.idcen;
        await loadOptionsCbo(centro.datatype, centro.data);
    }

    const loadOptionsCbo = async (fuente, opcion) =>{
        const data = new FormData();
        data.append("validacion", apiKey());
        if( opcion.includes("usu_actual") ){
            opcion = MiReplaceAll(opcion, "usu_actual", cookies.get('id'));
        }
        if( opcion.includes("nivel_actual") ){
            opcion = MiReplaceAll(opcion, "nivel_actual", cookies.get('nivel'));
        }
        data.append(fuente, opcion);                    

        if( optionsCentro.length == 0 ){
            let url = null;
            if( fuente == "filter" ){
                url = getApiUrl(window.location.origin)+'/filters/getFilter';
            }else{
                url = getApiUrl(window.location.origin)+'/app/getDataCbo';
            }
            const respuesta = await axios.post(url, data);
            saveIdCentro(respuesta.data[0].value);         
            saveOptionsCentro(respuesta.data)
        }

    }

    const importStoArt = () => {                

        let inputFile;
        if( !document.getElementById('inputStoArt') ){
            inputFile = document.createElement('input');
            inputFile.setAttribute('id','inputStoArt');
            inputFile.setAttribute('type','file');
            inputFile.style.display = 'none';
            // inputFile.setAttribute('accept','.csv, .xls, .xlsx ');
            inputFile.setAttribute('enctype','multipart/form-data');
            document.body.appendChild(inputFile);            
        }else{
            inputFile = document.getElementById('inputStoArt')
        }
        
        inputFile.addEventListener('change', handleFileImportStoArt, false);
        inputFile.click(); 
        
    }

    const handleFileImportStoArt = async () => {   
        let inputFile = document.getElementById('inputStoArt');          
        const file_data = inputFile.files[0];
        //Validamos la extensión
        if( !validateExtension(file_data, ['csv','xls','xlsx']) ){
            if( file_data ){
                swal("Error", `${file_data["name"]} tipo de archivo no admitido.`, "error");                   
            }
        }else{
            saveLoading(true); 
            
            saveRespuestaImpArt("");

            let form_data = new FormData();                  
            form_data.append('file', file_data);
            form_data.append('idCentro', idCentro);
            form_data.append("validacion", apiKey());  
    
            const url = `${getApiUrl(window.location.origin)}/app/FileToDB`;
            // const res = await axios.post(url, form_data); 
            const res = await axiosPost(url, form_data, source.token);   

            saveRespuestaImpArt(res.data);
        }
        // document.getElementById('inputStoArt').value = "";     
        saveLoading(false);                               
   
    }

    const validateExtension = (file, extensiones) => {
        if( file ){
            let fileExtension = file["name"].split(".")[file["name"].split(".").length-1];
            const valido = extensiones.find(ext => fileExtension.toLowerCase() == ext.toLowerCase());
            return valido;
        }
    }

    const handleSelectCentro = (selectedItem, nameOfComponent) => {
        saveIdCentro(selectedItem.value);
    }


    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                            <Section 
                                    titulo={confApp.impstoart.section.titulo} 
                                    icono={confApp.impstoart.section.icono} 
                                    col={confApp.impstoart.section.col} 
                                    wiki={confApp.impstoart.section.wiki}                                    
                            >
                            <div className="card">
                                <div className="card-header">
                                    <i className="fal fa-info-circle fa-2x" style={{color: "#212529"}}></i>
                                </div>
                                <div className="card-body">
                                    <blockquote className="blockquote mb-0" style={{borderLeft : "none"}}>
                                        <p>{confApp.impstoart.blockquote.titulo}</p>
                                        <footer className="blockquote-footer">
                                            <cite>
                                                <a className='ml-1' href="https://files.argos.aitodetec.com/doc/PLANTILLA_HOJA_STOCK_INICIAL.xlsx" >
                                                    {confApp.impstoart.blockquote.a.titulo}
                                                </a>
                                            </cite>
                                        </footer>
                                    </blockquote>
                                    <blockquote className="blockquote mb-0" style={{borderLeft : "none"}}>                                        
                                        <footer className="blockquote-footer">
                                            <cite className='mt-4'>                                                
                                                {confApp.impstoart.blockquote.help.titulo}                                                
                                            </cite>                                           
                                        </footer>
                                    </blockquote>
                                </div>
                            </div>
                            <FormRow>
                                <MiSelect  
                                    estadoForm=  { "" }
                                    val=         { idCentro }   
                                    loading=     { loadReg ? true : false }
                                    id=          { confApp.impstoart.idcen.id }    
                                    name=        { confApp.impstoart.idcen.name }   
                                    label=       { confApp.impstoart.idcen.label }    
                                    placeholder= { confApp.impstoart.idcen.placeholder }
                                    col=         { confApp.impstoart.idcen.col } 
                                    isClearable= { confApp.impstoart.idcen.isclearable }
                                    isSearchable={ confApp.impstoart.idcen.issearchable }
                                    dataType=    { confApp.impstoart.idcen.datatype }
                                    data=        { confApp.impstoart.idcen.data }
                                    required=    { confApp.impstoart.idcen.required } 
                                    disabled=  { confApp.impstoart.idcen.disabled }
                                    readOnly =   { confApp.impstoart.idcen.readonly }
                                    disablethisid={ confApp.impstoart.idcen.disablethisid}
                                    disableids={ confApp.impstoart.idcen.disableids}
                                    formulario= { {id:0} }   
                                    change= { handleSelectCentro }
                                />
                            </FormRow>
                            <ButtonOutline
                                clases={ "col-12 mt-2" }
                                color={ "success" } 
                                type="button" 
                                icono={ "mr-1 fal fa-file-upload" }   
                                id=""     
                                texto={confApp.impstoart.btnupload.titulo}   
                                accion={ importStoArt } 
                                disabled={ false }
                            />
                            {
                                loading ?
                                    <div className='mt-3 text-center'>
                                        <Spinner animation="border" />
                                    </div>                                 
                                :
                                    null
                            }
                            {
                                respuestaImpArt != "" ?
                                    <div className='mt-4'>
                                        <div className="alert alert-success" role="alert" 
                                             dangerouslySetInnerHTML={stringToHtml(respuestaImpArt)}
                                        >
                                        </div>
                                    </div>
                                :
                                    null
                            }
    
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default ImpStoArt