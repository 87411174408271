import React, { useEffect, useContext, useState } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net';
import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiDataTablestockReu from './MiDataTableStockReu';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Kpi from '../../componentes/ui/Kpi';
import Button from '../../componentes/ui/Button';
import { Spinner } from 'react-bootstrap';

import { alertService } from '../../services/alertservice';
import { Alert } from '../../componentes/ui/Alert';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, axiosPost, MiReplaceAll, getPantDocFromNivel, getTitle } from '../../helpers/helperApp';

import { Fragment } from 'react';

$.DataTable = DataTable;

function StockReu(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ documentosStock, saveDocumentosStock ] = useState([])
    const [ lineasStock, saveLineasStock ] = useState(null);
    const [ loadingCalculate, saveLoadingCalculate ] = useState(false);
    const [ options, saveOptions ] = useState(null);
    const [ idFiltro, saveIdFiltro ] = useState(0);
    const [ idParam, saveIdParam ] = useState(null);
    const [ infoDoc, saveInfoDoc ] = useState("");
    const [ idDocActual, saveIdDocActual ] = useState(null);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.stockreu.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        // Seleccionar los parámetros que nos llegan por la url
                        if( props.location.state ){
                            if( "iddoc" in props.location.state ){
                                // Nos llega el documento como parámetro
                                saveIdParam(props.location.state.iddoc);   
                                handleGetLineasStock(props.location.state.iddoc);             
                            }                        
                        }            
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
        } 

    },[confPantalla])



    const loadOptionsCbo = async (fuente, opcion) =>{
        const data = new FormData();
        data.append("validacion", apiKey());
        if( opcion.includes("usu_actual") ){
            opcion = MiReplaceAll(opcion, "usu_actual", cookies.get('id'));
        }
        if( opcion.includes("nivel_actual") ){
            opcion = MiReplaceAll(opcion, "nivel_actual", cookies.get('nivel'));
        }
        data.append(fuente, opcion);                    

        if( options == null ){
            let url = null;
            if( fuente == "filter" ){
                url = getApiUrl(window.location.origin)+'/filters/getFilter';
            }else{
                url = getApiUrl(window.location.origin)+'/app/getDataCbo';
            }
            const respuesta = await axios.post(url, data);
            saveOptions(respuesta.data)
        }
    }



    const handleGetLineasStock = async(param=null) => {
        
        
        // Obtenemos los option para los combos de la tabla con las líneas
        await loadOptionsCbo(confPantalla.stockreu.controles.idubi.datatype, confPantalla.stockreu.controles.idubi.data);
        
        
        let table, rows, idDoc;
        if( param ){
            saveLineasStock([])
            saveLoadingCalculate(true);            
            const data = new FormData();      
            data.append("validacion", apiKey());
            const ids = [];
            ids.push(param);
            data.append("ids", JSON.stringify(ids));            
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.stockreu.controles.datatableres.getdata, data, source.token);

            // Reseteamos la tabla de resultado
            saveLineasStock(null);
            if( typeof respuesta.data !== "string" ){
                saveLineasStock(respuesta.data);
                
                table = $("#stockreu").DataTable();
                rows = table.rows().data();
                table.row(':eq(0)', { page: 'current' }).select();
            }else if( typeof respuesta.data === "string" ){            
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
            } 
        }else{
            table = $("#stockreu").DataTable();
            rows = table.rows( { selected: true } ).data();
            if( rows.length > 0 ){
                saveLineasStock([])
                saveLoadingCalculate(true);
                
                const data = new FormData();      
                data.append("validacion", apiKey());
                const ids = [];
                rows.map( row => {                
                    ids.push(row.id);
                })                                
                data.append("ids", JSON.stringify(ids));                
                const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.stockreu.controles.datatableres.getdata, data, source.token);
                
                // Reseteamos la tabla de resultado
                saveLineasStock(null);
                if( typeof respuesta.data !== "string" ){
                    saveLineasStock(respuesta.data);
                }else if( typeof respuesta.data === "string" ){            
                    alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
                } 
    
            }else{
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.stockreu.controles.emptyclick.title}`,{autoClose: true});
            }  
        }

        
        saveLoadingCalculate(false);
    }

    
    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Alert fondo={true} />
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">                
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                            <Section titulo={ confPantalla.stockreu.section.titulo } icono={ confPantalla.stockreu.section.icono } col={ confPantalla.stockreu.section.col } >

                                <Kpi
                                    conf={ confPantalla.stockreu.controles.kpi }
                                    getDataTable1 = { confPantalla.stockreu.controles.datatable.getdata }
                                    saveDataTable1 = { saveDocumentosStock }                           
                                    saveLoadingTable = { saveLoadingTable }
                                    filtro={ confPantalla.stockreu.controles.kpi.tipdoc }
                                    idFiltro={ 0 }
                                    saveIdFiltro={ saveIdFiltro }
                                    idParam1={ idParam }
                                    saveIdParam1={ saveIdParam }
                                    display={ idParam != null ? true : false }
                                /> 
                                <MiDataTable 
                                    estadoForm= { "" }
                                    formulario={ {id:0} }
                                    id={"stockreu"}
                                    columnas={confPantalla.stockreu.controles.datatable.columns}
                                    data={documentosStock}
                                    buttons={confPantalla.stockreu.controles.datatable.buttons}
                                    ordering={confPantalla.stockreu.controles.datatable.ordering}
                                    order={confPantalla.stockreu.controles.datatable.order}
                                    searching={confPantalla.stockreu.controles.datatable.searching}
                                    paging={confPantalla.stockreu.controles.datatable.paging}
                                    select={confPantalla.stockreu.controles.datatable.select}
                                    pagelength={confPantalla.stockreu.controles.datatable.pagelength}
                                    // saveLoadingTable={ saveLoadingTable }
                                    loadingTable={loadingTable}
                                    columnactions={confPantalla.stockreu.controles.datatable.columnactions.title}
                                    linkColumns={confPantalla.stockreu.controles.datatable.linkColumns}
                                    traduction={confPantalla.config.trad.components.datatable}     
                                />
                                {
                                    documentosStock.length > 0
                                    ?
                                        <Button 
                                            clases={ "col-12 mt-2 mb-4" }
                                            color={ "success" }  
                                            type="button" 
                                            icono={ "" }   
                                            id="btngetart"     
                                            texto={ confPantalla.stockreu.controles.btncalcular.title }   
                                            accion={ handleGetLineasStock } 
                                            disabled={ false }
                                        />                             
                                    :
                                        null
                                }                    
                                
                                {
                                    lineasStock != null
                                    ?
                                    <div>                                        
                                        <MiDataTablestockReu 
                                            estadoForm= { "" }
                                            formulario={ {id:0} }
                                            id={"lineasstockreu"}
                                            columnas={confPantalla.stockreu.controles.datatableres.columns}
                                            data={lineasStock}
                                            saveData={saveLineasStock}
                                            buttons={confPantalla.stockreu.controles.datatableres.buttons}
                                            ordering={confPantalla.stockreu.controles.datatableres.ordering}
                                            order={confPantalla.stockreu.controles.datatableres.order}
                                            searching={confPantalla.stockreu.controles.datatableres.searching}
                                            paging={confPantalla.stockreu.controles.datatableres.paging}
                                            select={confPantalla.stockreu.controles.datatableres.select}
                                            pagelength={confPantalla.stockreu.controles.datatableres.pagelength}
                                            // saveLoadingTable={ saveLoadingCalculate }
                                            loadingTable={loadingCalculate}
                                            columnactions={confPantalla.stockreu.controles.datatableres.columnactions.title}
                                            linkColumns={confPantalla.stockreu.controles.datatableres.linkColumns}
                                            traduction={confPantalla.config.trad.components.datatable}
                                            selectubi={confPantalla.stockreu.controles.idubi}   
                                            optionselect={options} 
                                            idFiltro={idFiltro}                                    
                                        />        
                                    </div>
                                    :

                                        null

                                }
                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default StockReu