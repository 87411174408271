import React, { useEffect, useContext, useState, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { LoginContext } from '../../hooks/context/LoginContext';
import { alertService } from '../../services/alertservice';

import DataTableInformes from '../../componentes/formulario/informes/DataTableInformes';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Button from '../../componentes/ui/Button';
import Calendario from '../../componentes/ui/Calendario';
import CalendarioYear from '../../componentes/ui/CalendarioYear';
import MiModal from '../../componentes/ui/MiModal';
import { Alert } from '../../componentes/ui/Alert';
import Footer from '../../componentes/footer/Footer';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { MiReplaceAll, getApiUrl, axiosPost, ReplaceTextsReports } from '../../helpers/helperApp';

function VisorInformes(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Context *****
    const { apiKey } = useContext(LoginContext);

    const [ nombreInf, saveNombreInf ] = useState();
    const [ filtrossearch, saveFiltrosSearch] = useState(null);
    const [ tipoInf, saveTipoInf ] = useState(null);
    const [ sqlState, saveSqlState ] = useState(null);
    const [ sqlSecState, saveSqlSecState ] = useState(null);
    const [ sqlTerState, saveSqlTerState ] = useState(null);
    const [ sqlCuaState, saveSqlCuaState ] = useState(null);
    const [ logoState, saveLogoState ] = useState("");
    const [ textoAlerta, saveTextoAlerta ] = useState("");
    const [ colorAlerta, saveColorAlerta ] = useState("");

    // ***** State para la configuración de la pantalla *****
    const [ loadingInforme, saveLoadingInforme ] = useState(false);

    
    // ***** State para los datos del datatable*****
    const[ datosTable, saveDatosTable ] = useState(null);
    const[ columnasTable, saveColumnasTable ] = useState(null);
    let trad;
    
    // ***** State para las traducciones *****
    const[ traduction, saveTraduction ] = useState();
    
    // ***** State para los datos de los calendarios*****
    const[ datosCalendar, saveDatosCalendar ] = useState(null);
    const[ datosCalendarYear, saveDatosCalendarYear ] = useState(null);

    // ***** State para mostrar u ocultar el modal *****
    const [ showModal, saveShowModal ] = useState(false);

    // ***** Url archivos descargables *****
    const [ urlDownload, saveUrlDownload ] = useState("");
    const [ iconoOk, saveIconoOk ] = useState("");
    const [ txtOk, saveTxtOk ] = useState("");
    const [ showAlert, saveShowAlert ] = useState(false)


    useEffect( () => {   

        const sql = localStorage.sql;
        saveSqlState(localStorage.sql);
        const sqlSec = localStorage.sqlsec;
        saveSqlSecState(localStorage.sqlsec);
        const sqlTer = localStorage.sqlter;
        saveSqlTerState(localStorage.sqlter);
        const sqlCua = localStorage.sqlcua;
        saveSqlCuaState(localStorage.sqlcua);
        const idPlantilla = localStorage.idplantilla;
        const valSelect = localStorage.valselect;
        const formulario = JSON.parse(localStorage.formulario);
        const tipoInforme = localStorage.tipoinforme;
        saveTipoInf(localStorage.tipoinforme);
        saveNombreInf(localStorage.nombreinforme);
        saveTraduction(JSON.parse(localStorage.traduction));
        saveLogoState(localStorage.logo)
        const destinatarios = localStorage.destinatarios;
        const f = localStorage.filtrossearch;
        if( f != "" ){
            saveFiltrosSearch(JSON.parse(localStorage.filtrossearch));
        }else{
            saveFiltrosSearch(localStorage.filtrossearch);
        }
        trad = JSON.parse(localStorage.traduction);

        // Ponemos el nombre del informe en el title
        document.title =localStorage.nombreinforme;

        localStorage.removeItem("sql");
        localStorage.removeItem("sqlsec");
        localStorage.removeItem("sqlter");
        localStorage.removeItem("sqlcua");
        localStorage.removeItem("idplantilla");
        localStorage.removeItem("valselect");
        localStorage.removeItem("formulario");
        localStorage.removeItem("tipoinforme");
        localStorage.removeItem("nombreinforme");
        localStorage.removeItem("traduction");
        localStorage.removeItem("filtrossearch");
        localStorage.removeItem("logo");
        

        const callInforme = async (sql, plantilla, valSelect, formulario, tipoInforme, destinatarios, sqlSec, sqlTer, sqlCua) => {
            saveLoadingInforme(true); 

            sql = ReplaceTextsReports(sql, formulario);
            saveSqlState(sql);
            sqlSec = ReplaceTextsReports(sqlSec, formulario)
            saveSqlSecState(sqlSec);
            sqlTer = ReplaceTextsReports(sqlTer, formulario)
            saveSqlTerState(sqlTer);
            sqlCua = ReplaceTextsReports(sqlCua, formulario);
            saveSqlCuaState(sqlCua);


            const data = new FormData();
            const validacion = apiKey();
            data.append("idinforme", valSelect);
            data.append("validacion", validacion);
            data.append("sql", sql);
            data.append("sqlsec", sqlSec);
            data.append("sqlter", sqlTer);
            data.append("sqlcua", sqlCua);
            data.append("idregistro", formulario.id);
            if( plantilla ){
                data.append("idplantilla", plantilla);
            }
            data.append("destinatarios", MiReplaceAll(destinatarios, ",", ";"));
            // const respuestaInforme = await axios.post(getApiUrl(window.location.origin)+"/app/generateInforme",data);
            const respuestaInforme = await axiosPost(getApiUrl(window.location.origin)+"/app/generateInforme", data, source.token);
            
            if( tipoInforme == "1" ){
                // Tipo datatable
                prepareDataTable(respuestaInforme.data);
            }else if( tipoInforme == "2" ){
                // Tipo calendario
                prepareCalenario(respuestaInforme.data);
            }else if( tipoInforme == "0" ){
                // Documento descargable               
                if( typeof respuestaInforme.data == 'string' ){
                    if( respuestaInforme.data == "" ){
                        // alertService.error(`${trad.informes.errordocumento.title}`, {autoClose: false});
                        saveTextoAlerta(trad.informes.errordocumento.title);
                        saveColorAlerta("danger");
                        saveShowAlert(true);
                    }else{
                        saveUrlDownload(respuestaInforme.data);
                        var forceDown = document.createElement('a');
                        forceDown.setAttribute('href',respuestaInforme.data);
                        forceDown.setAttribute('download','');
                        document.body.appendChild(forceDown);
                        forceDown.click(); 
                        forceDown.remove();
                        // Configuración chrome descarga pdf: chrome://settings/content/pdfDocuments
                    }
                }else if( respuestaInforme.data.length == 0 ){
                    // Informe vacío
                    // alertService.warn(`${trad.informes.emptydocumento.title}`, {autoClose: false});
                    saveTextoAlerta(trad.informes.emptydocumento.title);
                    saveColorAlerta("warning");
                    saveShowAlert(true);
                }else if( respuestaInforme.data.error ){
                    // Error al generar el informe
                    // alertService.error(`${respuestaInforme.data.error}`, {autoClose: false});
                    saveTextoAlerta(respuestaInforme.data.error);
                    saveColorAlerta("danger");
                    saveShowAlert(true);
                }else if( respuestaInforme.data.errorSesion == true ){
                    // No pasó la validación en el api
                    props.history.push("/");                    
                }
            }else if( tipoInforme == "3" ){
                // Email PDF               
                if( typeof respuestaInforme.data == 'string' ){
                    // Enviado
                    // alertService.success(`<i class="fal fa-check-circle mr-1"></i>${respuestaInforme.data}`, {autoClose: false});
                    saveTextoAlerta(respuestaInforme.data);
                    saveColorAlerta("success");
                    saveShowAlert(true);
                }else if( respuestaInforme.data.length == 0 ){
                    // Informe vacío
                    // alertService.warn(`${trad.informes.emptydocumento.title}`, {autoClose: false});
                    saveTextoAlerta(trad.informes.emptydocumento.title);
                    saveColorAlerta("warning");
                    saveShowAlert(true);
                }else if( respuestaInforme.data.error ){
                    // Error al generar el informe o al enviar los correos
                    // alertService.error(`${respuestaInforme.data.error}`, {autoClose: false});
                    saveTextoAlerta(respuestaInforme.data.error);
                    saveColorAlerta("error");
                    saveShowAlert(true);
                }else if( respuestaInforme.data.errorSesion == true ){
                    // No pasó la validación en el api
                    props.history.push("/");                    
                }
            }



            saveLoadingInforme(false);             
        }

        callInforme(sql, idPlantilla, valSelect, formulario, tipoInforme, destinatarios, sqlSec, sqlTer, sqlCua);

        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        } 

    },[])


    const prepareDataTable = (data) => {
        const keys = data.keys();
        let cabeceras = [];
        let links = [];

        if( data.length > 0 ){
            for( let i = 0; i < data.length; i++ ){
                for (const [key, value] of Object.entries(data[i])) {
                    if( i == 0 ){
                        // Cabeceras
                        if( !key.includes("id_") && !key.includes("url_") && key != "id"){
                            let aux = { "title": key, "data": key };
                            cabeceras.push(aux);
                        }
                    }
                    // Enlaces si existe para esa columna
                    if( data[i]["url_"+key] ){  
                        data[i][key] = `<a href='${data[i]["url_"+key]}' target='_blank'>${data[i][key]}</a> `;
                    }
                }
            }
        }else{
            let aux = { "title": trad.datatable.semptytable.title, "data": "" };
            cabeceras.push(aux);

        }
        saveColumnasTable(cabeceras);
        saveDatosTable(data);
    }

    const prepareCalenario = (data) => {
        
        const currentYear = new Date().getFullYear();
        let dataCalendarioYear = [];
        let dataCalendario = [];
        data.map((ele) => {
            let fechaStart = ele.start.replace(" ", "T");           
            let fechaEnd = ele.end.replace(" ", "T");                  
            dataCalendarioYear.push(
                                        { 
                                            id: ele.id, 
                                            name: ele.title, 
                                            startDate: new Date(fechaStart), 
                                            endDate: new Date(fechaEnd), 
                                            color: ele.color == "" ? "#007bff" : ele.color , 
                                            url: ele.url_title != "" ? ele.url_title : ""
                                        }
                                    )
            dataCalendario.push(
                                    { 
                                        title:  ele.title, 
                                        start: ele.start, 
                                        end: ele.end, 
                                        backgroundColor: ele.color == "" ? "#007bff" : ele.color, 
                                        borderColor: ele.color == "" ? "#007bff" : ele.color, 
                                        url: ele.url_title != "" ? ele.url_title : ""
                                    }
                                )
        })        
        saveDatosCalendar(dataCalendario);
        saveDatosCalendarYear(dataCalendarioYear);
        
    }

    const handleShowModal = () => {
        saveShowModal(true);
    }

    const handleCloseModal = () => {
        saveShowModal(false);
    }

    
    return(
        <div className='App wrapper'>
            {
                loadingInforme == true 
                ?
                    <LoadingPage />
                :
                    
                    datosTable != null && columnasTable != null && tipoInf == "1"
                    ?
                    <div className='content-wrapper content-wrapper-Informes'>
                        <div className="container-fluid">
                            <div className='row'>
                                <div className='col-sm-12 col-md-2 text-center'>
                                    <img className="logoInformes hola" src={logoState} />                                            
                                </div>
                                {
                                    filtrossearch != ""
                                    ?   
                                    <Fragment>
                                        <div className='col-sm-12 col-md-9 pl-3 mt-2 ml-2' style={{borderLeft : "3px solid #343a40", color: "#343a40", borderRadius: "3px"}}>
                                            {
                                                cookies.get('nivel') == "1"
                                                ?
                                                    <h5 className='mt-3' onClick={handleShowModal}>{nombreInf}</h5>
                                                :
                                                    <h5 className='mt-3' >{nombreInf}</h5>
                                            }
                                            <div className="containerFiltros2 mt-3">
                                                {                                        
                                                    filtrossearch.map((filtro, index) => {
                                                        return <p key={index} className="cardfilter mr-2"><i className="fal fa-filter mr-2"></i>{filtro.cond}</p>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Fragment> 
                                    :                                    
                                        <div className='col-sm-12 col-md-9 pl-3 mt-2 ml-2' style={{borderLeft : "3px solid #343a40", color: "#343a40", borderRadius: "3px"}}>
                                            {
                                                cookies.get('nivel') == "1"
                                                ?
                                                    <h5 className='mt-3' onClick={handleShowModal}>{nombreInf}</h5>
                                                :
                                                    <h5 className='mt-3' >{nombreInf}</h5>
                                            }                                            
                                        </div>
                                }

                            </div>
                            <DataTableInformes 
                                id={"Informe"}
                                columnas={columnasTable}
                                data={datosTable}
                                buttons={true}
                                ordering={true}
                                order={[]}
                                searching={true}
                                paging={true}
                                select={true}               
                                traduction={traduction.datatable}
                                pagelength={traduction.datatable.pagelengthinformes}
                            />      
                        </div>
                    </div>
                    : 

                    tipoInf == "2"
                    ?
                        <div className="App wrapper">
                            <div className="container-fluid">
                            {
                            cookies.get('nivel') == "1"
                            ?
                                <hr data-content={nombreInf} className="hr-titleinf mb-2" onClick={handleShowModal}></hr> 
                            :
                                <hr data-content={nombreInf} className="hr-titleinf mb-2"></hr> 
                            }
                            {
                                filtrossearch != "" && filtrossearch != null
                                ?                                
                                    <div className="containerFiltros mt-5">
                                        <img className="logoInformes" src={logoState} />
                                        {
                                            filtrossearch.map((filtro, index) => {
                                                return <p key={index} className="cardfilter mr-2"><i className="fal fa-filter mr-2"></i>{filtro.cond}</p>
                                            })
                                        }
                                    </div>
                                :
                                    null
                            }                             
                                <Tabs defaultActiveKey="tab1" id="tab1">
                                    <Tab eventKey="tab1" tabClassName="tabInf" title={<i className="fal fa-calendar-day fa-2x" />}>
                                        <Calendario 
                                            data={ datosCalendar }
                                            locale = { cookies.get('idioma') }
                                        />
                                    </Tab>
                                    <Tab eventKey="tab2" tabClassName="tabInf" title={<i className="fal fa-calendar-alt fa-2x" />}>
                                        <CalendarioYear 
                                            data = {datosCalendarYear}
                                            onDayClick={true}
                                            locale = { cookies.get('idioma') }
                                        />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    :
                    tipoInf == "0" && urlDownload != ""
                    ?
                    <div className="App wrapper">
                        <div className="content-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "0", textAlign: "center" }}>
                            <p><i style={{ color: "#28a745"}} className={iconoOk != "" ? iconoOk+" fa-8x" : "fal fa-check fa-8x"}></i></p>
                            <h1>{txtOk}</h1>
                            <a href={urlDownload}>
                                <h5>{traduction.informes.downloadlink.title}</h5>
                                <p><i style={{ color: "#28a745"}} className='fal fa-download fa-6x'></i></p>
                            </a>
                        </div>
                    </div>
                    : 
                        null
                        
            }
            {
                showAlert
                ?
                    <div className="not-found">
                        {/* <Alert /> */}
                        <div className={`alert alert-${colorAlerta} alert-dismissible fade show`} role="alert">
                            {textoAlerta}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                :
                    null
            }
            {                
                showModal 
                ?
                    <MiModal
                        titulo={""}
                        actionhide={handleCloseModal}
                        centered={true}
                        btnclosetext={""}
                        iconoBtnDanger={"fal fa-times"}
                        footer={false}
                    >
                        <p>{sqlState}</p>
                        <hr className="separator" />
                        <p>{sqlSecState}</p>
                    </MiModal>
                :
                    null 
            }
            <Footer full={true} />            
        </div>

    );

}
export default VisorInformes