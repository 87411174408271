import React, { useState, useEffect, useContext } from 'react';

import UplmPlus2PendingList from './UplmPlus2PendingList';
import UplmPlus2List from './UplmPlus2List';

import { LoginContext } from '../../../hooks/context/LoginContext';

import imageCompression from "browser-image-compression";
import axios from 'axios';
import swal from 'sweetalert';
import Modal from 'react-bootstrap/Modal';
import FormInput from '../FormInput';


import Cookies from 'universal-cookie';

import { MiReplaceAll, getApiUrl, axiosPost } from '../../../helpers/helperApp';


function UplmPlus2(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();

    const { id, adjuntos, saveAdjuntos, adjuntosExistentes, saveAdjuntosExistentes, estadoForm, loading, col, readonly, traduction, datatable, select } = props;

    // ***** States *****
    const [validacionesUplm, saveValidacionesUplm] = useState([]);
    const [optionSelect, saveOptionsSelect] = useState(null);
    const [showModalUrl, saveShowModalUrl] = useState(false);
    const [url, saveUrl] = useState("");
    const [urlVacia, saveUrlVacia] = useState(false)

    // ***** Context *****
    const { apiKey } = useContext(LoginContext);

    useEffect( () => {

        // ***** Carga inicial de validaciones *****
        if( validacionesUplm.length === 0 ){
            const data = new FormData(); 
            const validacion = apiKey();
            data.append("validacion", validacion);
            const getValidacionesUplm = async () => {
                await axios.post(getApiUrl(window.location.origin)+"/adj/getValidaciones", data)
                // await axiosPost(getApiUrl(window.location.origin)+"/adj/getValidaciones", data, source.token)
                .then( response => {
                    if( typeof response.data !== "string" ){
                        saveValidacionesUplm(response.data);
                    }
                    // else{
                    //     swal("Error", `${traduction.uplm.loadvalidationserror.title}`, "error");
                    // }
                })
                .catch( error => {
                    swal("Error", `${traduction.uplm.loadvalidationserror.title}`, "error");
                })
            }
            
            getValidacionesUplm();

            getDataCbo(select.datatype, select.data)
        }

        return ( () => {
            saveValidacionesUplm([]);
            // Token cancelación axios al desmontar componentes
            source.cancel();            
            source2.cancel();            
        })

    },[])


    const getDataCbo = async(fuente, opcion) => {

        if( fuente === "codigo" ){
            if( typeof opcion === "string" ){
                if( opcion.includes("´") ){
                    const search = '´';
                    const replaceWith = '"';
                    opcion = opcion.split(search).join(replaceWith);
                }
                opcion = JSON.parse(opcion);
            }
            saveOptionsSelect(opcion); 
        }else{
            const data = new FormData();
            const validacion = apiKey();
            data.append("validacion", validacion);
            if( opcion.includes("usu_actual") ){
                opcion = MiReplaceAll(opcion, "usu_actual", cookies.get('id'));
            }
            if( opcion.includes("nivel_actual") ){
                opcion = MiReplaceAll(opcion, "nivel_actual", cookies.get('nivel'));
            }
            data.append(fuente, opcion);

            let url = null;
            if( fuente == "filter" ){
                url = getApiUrl(window.location.origin)+'/filters/getFilter';
            }else{
                url = getApiUrl(window.location.origin)+'/app/getDataCbo';
            }

            // const respuesta = await axios.post(url, data);
            const respuesta = await axiosPost(url, data, source2.token);
            saveOptionsSelect(respuesta.data);
        }
    }

    // ***** Leer los archivos del input *****
    const handleFiles = async files => {
        await actionsFiles(files);
    }
    const handleDragEnter = async e => {
        e.preventDefault();
        e.stopPropagation();
        await actionsFiles(e.dataTransfer.files);
    };
    const handleDragLeave = async e => {
        e.preventDefault();
        e.stopPropagation();
        await actionsFiles(e.dataTransfer.files);
    };
    const handleDragOver = async e => {
        e.preventDefault();
        e.stopPropagation();
        await actionsFiles(e.dataTransfer.files);
    };
    const handleDrop = async e => {
        e.preventDefault();
        e.stopPropagation();
        await actionsFiles(e.dataTransfer.files);
    };

    const actionsFiles = async files => {

        let extensiones = validacionesUplm[0]["val"].split("|");
        let maxWidth = parseInt(validacionesUplm[1]["val"].split("x")[0]);
        let maxSize = validacionesUplm[2]["val"];
        const optionsCompres = {          
            maxSizeMB: validacionesUplm[2]["val"],
            maxWidthOrHeight: maxWidth,
            useWebWorker: false
        }

        
        
        for(let i = 0; i < files.length; i++){
            
            const duplicado = adjuntos.filter( adj =>  adj.name === files[i].name );

            if(duplicado.length === 0){
                let valido = true;
                //Validamos la extensión
                if( !validateExtension(files[i], extensiones) ){
                    swal("Error", `${files[i]["name"]} ${traduction.uplm.errorextension.title}`, "error");
                    valido = false;
                }
    
                if( files[i]["type"].split("/")[0] === "image" ){
                    //Imágenes 
                        if( valido ){
                            //Hacemos el resize para las imágenes                    
                            let fileResize = await imageCompression(files[i], optionsCompres); 
                            let aux = await blobToFile(fileResize, files[i]);
                            //Actualizamos el state
                            await saveAdjuntos( adjuntos => [...adjuntos, aux] ) 
                        }               
                }else{
                    //Resto de arhivos
                    if( !validateSize(files[i]) ){                   
                        swal("Error", `${files[i]["name"]} ${traduction.uplm.errorsize.title} (${maxSize}Mb)`, "error");
                    }else{
                        //Actualizamos el state
                        if(valido){ 
                            await saveAdjuntos( adjuntos => [...adjuntos, files[i]] )  
                        } 
                    }
                }
            }else{
                swal("Error", `${duplicado[0]["name"]} ${traduction.uplm.duplicate.title}`, "error");
            }

        }
        //Borramos el value del input file multiple para manejarlos a nuestro antojo en la función que crea el FormData y envía el formulario
        document.querySelector(`#${id}`).value = "";
    }

    const validateExtension = (file, extensiones) => {
        let fileExtension = file["name"].split(".")[file["name"].split(".").length-1];
        const valido = extensiones.find(ext => fileExtension.toLowerCase() == ext.toLowerCase());
        return valido;
    }

    const validateSize = (file) => {
        let valido = true;
        let maxSize = validacionesUplm[2]["val"];

        if( (file["size"] / 1000) > (maxSize * 1000) ){
            valido = false;
        }

        return valido;
    }

    const blobToFile = (blob, file) => {
        blob.lastModifiedDate = file.lastModifiedDate;
        blob.name = file.name;
        return new File([blob], file.name,{type: "image/jpeg", lastModified: file.lastModifiedDate });     
    }

    // Funciones para los enlaces
    const addUrl = e => {
        saveUrl("");
        saveUrlVacia("");
        saveShowModalUrl(true)
    }

    const handleUrl = e => {
        const { name, value } = e.target;
        saveUrl(value)    
    }

    const addUrlAction = e => {
        if( url != "" ){
            // Comprobamos que esa url no esté pendiente de subir
            let existe = adjuntos.filter(adj => adj.name == url);
            // Comprobamos que esa url no esté entre las url existentes
            existe = adjuntosExistentes.filter(adj => adj.name == url);
            if( existe.length > 0 ){
                saveUrlVacia("existe");

            }else{
                saveUrlVacia(false);
                saveShowModalUrl(false);
                let aux = { name: url, urlAdd: url, idtip: "", fecha: "" }
                saveAdjuntos( adjuntos => [...adjuntos, aux] ) 
            }
        }else{
            saveUrlVacia(true);
        }
    }

    return(
            optionSelect != null
            ?
            <div className={"form-group col-md-"+col}>
                    <div className="">  
                        <UplmPlus2List 
                            id={id} 
                            adjuntosExistentes= { adjuntosExistentes } 
                            saveAdjuntosExistentes= { saveAdjuntosExistentes }
                            readonly={readonly}
                            estadoForm={estadoForm}
                            traduction={traduction}
                            datatable={ datatable }
                            optionSelect={ optionSelect }
                        />
                    </div>
                    {
                        readonly !== "true" && readonly !== "readonly" && !loading && estadoForm !== "readonly" && estadoForm !== "disabled" 
                        ?
                        <div>                        
                            <div className="row fileupload-buttonbar">
                                <div className="col-lg-12">                                                                        
                                    <div className='row'>
                                        <div className='col-6 col-xs-12'>
                                            <span className="btn btn-flat btn-success fileinput-button adj btn-block btn-lg " id="fileinput-buttonadj">
                                                <i className="far fa-paperclip mr-1" style={{transform : "rotate(-45deg)"}}></i>
                                                <span>{traduction.uplm.btnadd.title}</span>
                                                <input className="btn btn-success fileinput-button" id={id} type="file"  name={id+"[]"} multiple="multiple" onChange={ (e) => handleFiles(e.target.files) } />
                                            </span>
                                        </div>
                                        <div className='col-6 col-xs-12'>
                                            <button type="button" className="btn btn-flat btn-success btn-block btn-lg " onClick={ (e) => addUrl(e) }>
                                                <i className="fal fa-browser mr-1" onClick={ (e) => addUrl(e) }></i>
                                                {traduction.uplm.btnaddurl.title}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid" style={{marginTop:15}}>
                                <div className="containerCards">                                
                                        <UplmPlus2PendingList
                                            id={id} 
                                            adjuntos={ adjuntos }
                                            saveAdjuntos= { saveAdjuntos }
                                            readonly={readonly}
                                            estadoForm={estadoForm}
                                            traduction={traduction}
                                            optionSelect={ optionSelect }
                                            datatable={ datatable }
                                        />                                            
                                </div>
                            </div>
                        </div>
                        :
                        <div>                        
                            <div className="row fileupload-buttonbar" style={{display: "none"}}>
                                <div className="col-lg-12">
                                    <div className="well dropzone dropzone">
                                        <h3>{traduction.uplm.dropzone.title}</h3>
                                        <span><i className="fal fa-upload fa-2x"></i></span>
                                    </div>
                                    <span className="btn btn-flat btn-success fileinput-button adj btn-block btn-lg " id="fileinput-buttonadj">
                                        <i className="far fa-plus"></i>
                                        <span>{traduction.uplm.btnadd.title}</span>
                                        <input className="btn btn-success fileinput-button disable-click" id={id} type="file"  name={id+"[]"} multiple="multiple" />
                                    </span>
                                </div>
                            </div>
                            <div className="container-fluid" style={{marginTop:15}}>
                                <div className="containerCards">                                
                                        <UplmPlus2PendingList
                                            id={id} 
                                            adjuntos={ adjuntos }
                                            saveAdjuntos= { saveAdjuntos }
                                            readonly={readonly}
                                            estadoForm={estadoForm}
                                            traduction={traduction}
                                            optionSelect={ optionSelect }
                                            datatable={ datatable }
                                        />                                            
                                </div>
                            </div>
                        </div>
                    }

            {
                showModalUrl 
                ?
                    <Modal
                        size="lg"
                        show={true}
                        onHide={ () => saveShowModalUrl(false)}                            
                        aria-labelledby="example-custom-modal-styling-title"
                        centered={true}
                        contentClassName="likeSw"
                        backdropClassName="backLikeSw"                                              
                        >                          
                        <Modal.Body>
                            <div className='form-group'>
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { url }
                                    loading=    { false }
                                    id=         { "" }    
                                    type=       { "text" }  
                                    name=       { "" }   
                                    label=      { "" }                                                       
                                    placeholder={ "" }    
                                    col=        { "12" } 
                                    required=   { false }
                                    readonly=   { false }                                                
                                    disabled=   { false }
                                    change=     { handleUrl }
                                    onBlur=     {null}
                                    clasesInput={urlVacia || urlVacia == "existe" ? "is-invalid" : null} 
                                />
                                {
                                    urlVacia == "existe"
                                    ?
                                        <small id="emailHelp" class="form-text text-danger">{traduction.uplm.duplicateurl.title}</small>
                                    :
                                        null
                                }
                            </div>
                            <button type="button" className="btn btn-flat btn-success btn-block " onClick={ (e) => addUrlAction(e) }>
                                <i className="fal fa-plus mr-1" /*onClick={ (e) => addUrlAction(e) }*/></i>
                            </button>
                        </Modal.Body>                            
                    </Modal>    
                :
                    null 

            }           
            </div>
            :
                null
        
    )
}

export default UplmPlus2;