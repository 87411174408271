import { React, useEffect, useState } from 'react';

import axios from 'axios';

import { LoginProvider } from '../hooks/context/LoginContext';

import SimpleReactLightbox from 'simple-react-lightbox';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { getApiUrl } from '../helpers/helperApp';

import Login from '../pages/login/Login';
import Registro from '../pages/login/Registro';
import Inicio from '../pages/inicio/Inicio';
import Pruebas from '../pages/pruebas/Pruebas';
import LoginNvl from '../pages/loginNvl/LoginNvl';
import Loginusu from '../pages/loginUsu/LoginUsu';
import LoginUsuPr from '../pages/loginUsuPr/LoginUsuPr';
import AppCfg from '../pages/appCfg/AppCfg';
import ForgotPass from '../pages/login/ForgotPass';
import ChangePass from '../pages/login/ChangePass';
import Profile from '../pages/profile/Profile';
import NotFound from '../componentes/NotFound';
import Apartados from '../pages/apartados/Apartados';
import ClaObj from '../pages/claObj/ClaObj';
import ClaUni from '../pages/claUni/ClaUni';
import EstDoc from '../pages/estDoc/EstDoc';
import TipAge from '../pages/tipAge/TipAge';
import TipObj from '../pages/tipObj/TipObj';
import TipUni from '../pages/tipUni/TipUni';
import Ubicaciones from '../pages/ubicaciones/Ubicaciones';
import ClaAge from '../pages/claAge/ClaAge';
import ClaDoc from '../pages/claDoc/ClaDoc';
import TipPri from '../pages/tipPri/TipPri';
import TipCen from '../pages/tipCen/TipCen';
import TipGf from '../pages/tipGf/TipGf';
import Centros from '../pages/centros/Centros';
import CentrosPr from '../pages/centrospr/CentrosPr';
import Equipos from '../pages/equipos/Equipos';
import Articulos from '../pages/artículos/Artículos';
import Paises from '../pages/paises/Paises';
import Provincias from '../pages/provincias/Provincias';
import PermisosUsu from '../pages/permisosusu/PermisosUsu';
import Agentes from '../pages/agentes/Agentes';
import AgentesPr from '../pages/agentespr/AgentesPr';
import TipDoc from '../pages/tipdoc/TipDoc';
import Documentos from '../pages/documentos/Documentos';
import DocumentosPr from '../pages/documentospr/DocumentosPr';
import Documentosenc from '../pages/documentosenc/Documentosenc';
import Documentostrab from '../pages/Documentostrab/Documentostrab';
import appTrad from '../pages/appTrad/AppTrad';
import StockUbi from '../pages/stockubi/StockUbi';
import StockMov from '../pages/stockmov/StockMov';
import StockArt from '../pages/stockart/StockArt';
import StockReu from '../pages/stockreu/StockReu';
import PanInfCfg from '../pages/paninfcfg/PanInfCfg';
import VisorInformes from '../pages/visorinformes/VisorInformes';
import TipAdj from '../pages/tipAdj/TipAdj';
import Kpis from '../pages/kpis/Kpis';
import CheckList from '../pages/checklist/CheckList';
import CheckListMa from '../pages/checklistma/CheckListMa';
import CheckListTrab from '../pages/checklisttrab/CheckListTrab';
import Supply from '../pages/supply/Supply';
import Checklistwiz from '../pages/checklistwiz/CheckListWiz';
import CentrosAdj from '../pages/centrosadj/CentrosAdj';
import ImpStoArt from '../pages/impstoart/ImpStoArt';
import Macros from '../pages/macros/Macros';
import Runmac from '../pages/runmac/Runmac';
import EdiRap from '../pages/edirap/EdiRap';
import EdirapComp from '../pages/edirap/EdiRapComp';
import EdirapInv from '../pages/edirap/EdiRapInv';
import EdirapPoe from '../pages/edirap/EdiRapPoe';
import Planificador from '../pages/Planificador/Planificador';
import PlanificadorPr from '../pages/planificadorpr/PlanificadorPr';
import ExecPr from '../pages/execpr/ExecPr';
import Sfi from '../pages/sfi/Sfi';
import Historia from '../pages/historia/Historia';
import Connections from '../pages/Connections/Connections';
import Api from '../pages/api/Api';
import Ciccfg from '../pages/ciccfg/Ciccfg';
import RunCicCfg from '../pages/runciccfg/RunCicCfg';
import CrewList from '../pages/CrewList/CrewList';
import BrowserPr from '../pages/browserpr/BrowserPr';
import LogBook from '../pages/logbook/LogBook';

function App() {

      const [recoverPass, saveRecoverPass] = useState();
      const [register, saveRegister] = useState();
  
      useEffect( () => {

            // ***** Obtener las validaciones y configuraciones iniciales *****
            const getValidaciones = async () => {
                const url = `${getApiUrl(window.location.origin)}/login/getValidaciones`;
                const res = await axios.post(url);      
                res.data.map( validacion => {
                      if( validacion.cod === "login.recoverpass.allow" ){
                        saveRecoverPass(validacion.val);
                      }
                      if( validacion.cod === "login.register.allow" ){
                        saveRegister(validacion.val);
                      }
                } )
            }

            getValidaciones();
      },[])

      return (
        
        <SimpleReactLightbox>
          <LoginProvider>
                {/* <BrowserRouter forceRefresh={true}> */}
                <BrowserRouter>
                  <Switch>
                    {/*****  Login ******/}
                    <Route exact path="/" component={ Login } />
                    <Route exact path="/:paramId/:paramEmail/:paramNombre/:paramSesion/:paramNivel/:paramEstado/:paramIdioma/:paramImgUser/:paramIdAge/:paramUrl/:paramOrigenTabMA" component={ Login }/>

                    {
                      register === "1"
                      ?
                        <Route exact path="/registro" component={ Registro } />
                      :
                        <Route exact path="/registro" component={ Login } />

                    }
                    {
                      recoverPass === "1"
                      ?
                        <Route exact path="/forgotpass" component={ ForgotPass } />
                      :
                        <Route exact path="/forgotpass" component={ Login } />
                    }
                    {
                      recoverPass === "1"
                      ?
                        <Route exact path="/changepass/:info" component={ ChangePass } />
                      :
                        <Route exact path="/changepass/:info" component={ Login } />
                    }                 
                    
                    {/*****  Inicio ******/}
                    <Route exact path="/inicio" component={ Inicio } />
                    <Route exact path="/profile" component={ Profile } />
                    <Route exact path="/profile/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/profile', state: {...match.params}}} />;
                                      }} 
                          />   

                    {/*****  Páginas ******/}
                    <Route exact path="/pruebas" component={ Pruebas } />
                          {/*****  Página Pruebas  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/pruebas/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/pruebas', state: {...match.params}}} />;
                                      }} 
                          />   
                          <Route exact path="/pruebas/:idPrin/:idHijo" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/pruebas', state: {...match.params}}} />;
                                      }} 
                          />             
                    <Route exact path="/appcfg" component={ AppCfg } />
                          {/*****  Página app_cfg recibiendo opción de configuración para que solo muestre esos registros ******/} 
                          <Route exact path="/appcfg/:cfg" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/appcfg', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/loginnvl" component={ LoginNvl } />
                    <Route exact path="/permisosusu" component={ PermisosUsu } />
                    <Route exact path="/loginusu" component={ Loginusu } />
                          {/*****  Página Loginusu  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/loginusu/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/loginusu', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/loginusupr" component={ LoginUsuPr } />
                          {/*****  Página LoginusuPr  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/loginusupr/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/loginusupr', state: {...match.params}}} />;
                                      }} 
                          />   
                    <Route exact path="/apartados" component={ Apartados } />
                    {/* <Route exact path="/claobj" component={ ClaObj } /> */}
                    <Route exact path="/clauni" component={ ClaUni } />
                    <Route exact path="/estdoc" component={ EstDoc } />
                    <Route exact path="/tipage" component={ TipAge } />
                    <Route exact path="/tipobj" component={ TipObj } />
                    <Route exact path="/tipuni" component={ TipUni } />
                    <Route exact path="/tipdoc" component={ TipDoc } />
                    <Route exact path="/ubicaciones" component={ Ubicaciones } />
                    <Route exact path="/paises" component={ Paises } />
                    <Route exact path="/provincias" component={ Provincias } />
                    <Route exact path="/claage" component={ ClaAge } />
                    <Route exact path="/cladoc" component={ ClaDoc } />
                    <Route exact path="/tippri" component={ TipPri } />
                    <Route exact path="/apptrad" component={ appTrad } />
                    <Route exact path="/tipcen" component={ TipCen } />
                          {/*****  Página tipcen  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/tipcen/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/tipcen', state: {...match.params}}} />;
                                      }} 
                          />  
                    <Route exact path="/tipgf" component={ TipGf } />
                          {/*****  Página tipgf  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/tipgf/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/tipgf', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/agentes" component={ Agentes } />
                          {/*****  Página agentes  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/agentes/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/agentes', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/agentespr" component={ AgentesPr } />
                          {/*****  Página agentesPr  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/agentespr/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/agentespr', state: {...match.params}}} />;
                                      }} 
                          />  
                    <Route exact path="/centros" component={ Centros } />
                          {/*****  Página centros  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/centros/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/centros', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/centrospr" component={ CentrosPr } />
                          {/*****  Página centros  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/centrospr/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/centrospr', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/adjcentros" component={ CentrosAdj } />
                          {/*****  Página centrosadj recibiendo una cadena para filtrar en la tabla de documentos parámetros ******/} 
                          <Route exact path="/adjcentros/:filter" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/adjcentros', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/equipos" component={ Equipos } />
                          {/*****  Página equipos  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/equipos/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/equipos', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/articulos" component={ Articulos } />
                          {/*****  Página articulos  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/articulos/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/articulos', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/documentos" component={ Documentos } />
                          {/*****  Página Documentos recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/documentos/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/documentos', state: {...match.params}}} />;
                                      }} 
                          />   
                          <Route exact path="/documentos/:idPrin/:idHijo" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/documentos', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/documentospr" component={ DocumentosPr } />
                          {/*****  Página DocumentosPr recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/documentospr/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/documentospr', state: {...match.params}}} />;
                                      }} 
                          />   
                          <Route exact path="/documentospr/:idPrin/:idHijo" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/documentospr', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/documentosenc" component={ Documentosenc } />
                          {/*****  Página Documentos del encargado recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/documentosenc/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/documentosenc', state: {...match.params}}} />;
                                      }} 
                          />   
                          <Route exact path="/documentosenc/:idPrin/:idHijo" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/documentosenc', state: {...match.params}}} />;
                                      }} 
                          />
                          <Route exact path="/documentosenc/:idPrin/:idHijo/:estadoFormPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/documentosenc', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/documentostrab" component={ Documentostrab } />
                          {/*****  Página Documentos del encargado recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/documentostrab/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/documentostrab', state: {...match.params}}} />;
                                      }} 
                          />
                          <Route exact path="/documentostrab/:idPrin/:idHijo" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/documentostrab', state: {...match.params}}} />;
                                      }} 
                          />
                          <Route exact path="/documentostrab/:idPrin/:idHijo/:estadoFormPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/documentostrab', state: {...match.params}}} />;
                                      }} 
                          /> 
                    <Route exact path="/stockubi" component={ StockUbi } />
                    <Route exact path="/stockmov" component={ StockMov } />
                          <Route exact path="/stockmov/:idubi/:iduni" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/stockmov', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/stockart" component={ StockArt } />
                    <Route exact path="/stockart/:iduni" 
                                component={({ match }) => {
                                  return <Redirect to={{ pathname: '/stockart', state: {...match.params}}} />;
                                }} 
                    />
                    <Route exact path="/stockreu" component={ StockReu } />
                    <Route exact path="/stockreu/:iddoc" 
                                component={({ match }) => {
                                  return <Redirect to={{ pathname: '/stockreu', state: {...match.params}}} />;
                                }} 
                    />
                    <Route exact path="/edirap" component={ EdiRap } />
                    <Route exact path="/edirap/:iddoc" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/edirap', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/edirappoe" component={ EdirapPoe } />
                    <Route exact path="/edirappoe/:iddoc" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/edirappoe', state: {...match.params}}} />;
                                      }} 
                          />                    
                    <Route exact path="/edirapinv/14" component={ EdirapInv } />
                    <Route exact path="/edirapcomp/11" component={ EdirapComp } />
                    <Route exact path="/paninfcfg" component={ PanInfCfg } />
                          {/*****  Página paninfcfg recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/paninfcfg/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/paninfcfg', state: {...match.params}}} />;
                                      }} 
                          /> 
                    <Route exact path="/visorinformes" component={ VisorInformes } />      
                    <Route exact path="/tipadj" component={ TipAdj } /> 
                    <Route exact path="/kpis" component={ Kpis } />
                          {/*****  Página de kpis de centros recibiendo como parámetro el id del centro ******/} 
                          <Route exact path="/kpis/:idcen" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/kpis', state: {...match.params}}} />;
                                      }} 
                          />
                          {/*****  Página de kpis de centros recibiendo como parámetros el id del centro, la fecha inicio y fin para filtrar por fecha ******/} 
                          <Route exact path="/kpis/:idcen/:ini/:fin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/kpis', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/checklistma" component={ CheckListMa } />
                    {/*****  Página checklist recibiendo una cadena para filtrar en la tabla de plantillas ******/} 
                    <Route exact path="/checklistma/:filter" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/checklistma', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/checklist" component={ CheckList } />
                    {/*****  Página checklist recibiendo una cadena para filtrar en la tabla de plantillas ******/} 
                    <Route exact path="/checklist/:filter" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/checklist', state: {...match.params}}} />;
                                      }} 
                          /> 
                    <Route exact path="/checklisttrab" component={ CheckListTrab } />   
                    <Route exact path="/supply" component={ Supply } />
                    <Route exact path="/planificador" component={ Planificador } />
                    <Route exact path="/planificadorpr" component={ PlanificadorPr } />
                    <Route exact path="/execpr" component={ ExecPr } />
                          {/*****  Página Ejecutor del encargado recibiendo parámetros ******/} 
                          <Route exact path="/execpr/:planning" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/execpr', state: {...match.params}}} />;
                                      }} 
                          />
                          <Route exact path="/execpr/:planning/:poe" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/execpr', state: {...match.params}}} />;
                                      }} 
                          />
                          <Route exact path="/execpr/:planning/:poe/:agente" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/execpr', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/connections" component={ Connections } />
                    <Route exact path="/api" component={ Api } />
                          {/*****  Página api  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/api/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/api', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/checklistwiz" component={ Checklistwiz } />
                          {/*****  Página Documentos del encargado recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/checklistwiz/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/checklistwiz', state: {...match.params}}} />;
                                      }} 
                          />
                          <Route exact path="/checklistwiz/:idPrin/:idHijo" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/checklistwiz', state: {...match.params}}} />;
                                      }} 
                          />
                          <Route exact path="/checklistwiz/:idPrin/:idHijo/:estadoFormPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/checklistwiz', state: {...match.params}}} />;
                                      }} 
                          />            
                    <Route exact path="/impstoart" component={ ImpStoArt } />
                    <Route exact path="/macros" component={ Macros } />
                    <Route exact path="/runmac/:uuid" component={ Runmac } />
                    <Route exact path="/runmac/:uuid/:param1" component={ Runmac } />
                    <Route exact path="/runmac/:uuid/:param1/:param2" component={ Runmac } />
                    <Route exact path="/runmac/:uuid/:param1/:param2/:param3" component={ Runmac } />
                    <Route exact path="/runmac/:uuid/:param1/:param2/:param3" component={ Runmac } />
                    <Route exact path="/runmac/:uuid/:param1/:param2/:param3/:param4" component={ Runmac } />
                    <Route exact path="/runmac/:uuid/:param1/:param2/:param3/:param5" component={ Runmac } />
                    <Route exact path="/sfi" component={ Sfi } />
                          {/*****  Página equipos  recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/sfi/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/sfi', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/historia" component={ Historia } />
                          {/*****  Página Historia recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/historia/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/historia', state: {...match.params}}} />;
                                      }} 
                          />   
                          <Route exact path="/historia/:idPrin/:idHijo" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/historia', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/ciccfg" component={ Ciccfg } />
                          {/*****  Página Ciccfg recibiendo ids de registros como parámetros ******/} 
                          <Route exact path="/ciccfg/:idPrin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/ciccfg', state: {...match.params}}} />;
                                      }} 
                          />   
                          <Route exact path="/ciccfg/:idPrin/:idHijo" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/ciccfg', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/runciccfg" component={ RunCicCfg } />
                    <Route exact path="/crewlist" component={ CrewList } />
                    <Route exact path="/browserpr" component={ BrowserPr } />
                          <Route exact path="/browserpr/:id/:tipo" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/browserpr', state: {...match.params}}} />;
                                      }} 
                          />                          
                          <Route exact path="/browserpr/:id/:tipo/:fecini/:fecfin" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/browserpr', state: {...match.params}}} />;
                                      }} 
                          />
                    <Route exact path="/logbook" component={ LogBook } />                          
                          <Route exact path="/logbook/:idage/:idcen/:iduni" 
                                      component={({ match }) => {
                                        return <Redirect to={{ pathname: '/logbook', state: {...match.params}}} />;
                                      }} 
                          />
                    {/*****  Página no encontrada ******/}
                    <Route component={NotFound} />
                  </Switch>
                </BrowserRouter>
          </LoginProvider>
        </SimpleReactLightbox>

      );
}

export default App;
