import React, { useEffect, useContext, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';
import TextArea from '../../componentes/formulario/TextArea';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import UplmPlus from '../../componentes/formulario/UplmPlus/UplmPlus';
import UplmPlus2 from '../../componentes/formulario/UpmlPlus2/UplmPlus2';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Recurrencia from '../../componentes/formulario/forms/Recurrencia';
import Informes from '../../componentes/formulario/informes/Informes';
import MiDataTableAdjLin from '../../componentes/formulario/MiDataTableAdjLin/MiDataTableAdjLin';
import Button from '../../componentes/ui/Button';
import ButtonOutline from '../../componentes/ui/ButtonOutline';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

import { alertService } from '../../services/alertservice';

import { getApiUrl, axiosPost, MiReplaceAll, getTitle } from '../../helpers/helperApp';

let reordenandoLineas = false;

function Ciccfg(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo, loadHijos } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.ciccfg.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        saveEstadoFormPrin(confPantalla.ciccfg.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.ciccfg.hijo.estadoform);                            
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.ciccfg.padre.urls);
                                saveFormularioPrin(confPantalla.ciccfg.padre.modelform);
                                saveFormularioHijo(confPantalla.ciccfg.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.ciccfg.padre.urls, confPantalla.ciccfg.hijo.urls, confPantalla.ciccfg.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
            document.querySelector("body").classList.remove("sidebar-open");
            // Token cancelación axios al desmontar componentes
            source.cancel();
            source2.cancel();
            source3.cancel();
        } 

    },[confPantalla])

    /***** FUNCIONES CLONADO CAMPOS CABECERAS Y LINEAS *****/
    const handleSelectMultiple = (selectedItem, nameOfComponent, item) => {
        let optionsSeleccionadas = [];
        let nombre = nameOfComponent.name.split("[]")[0];

        for(let i = 0; i < selectedItem.length; i++){
            optionsSeleccionadas.push(selectedItem[i].value);
        }

        if( selectedItem.length > 0 ){
            saveFormularioHijo(
                {   ...formularioHijo, 
                    [item]:optionsSeleccionadas.join(",")
                }
            ) 
            
        }else{
            saveFormularioHijo(
                {   ...formularioHijo, 
                    [item]:""
                }
            ) 
        }
    }

    /***** FUNCIONES VALORES POR DEFECTO CABECERA Y LINEA *****/
    const handleAddColumn = (e, item) => {

        let variables;

        const newVariable = { "key" : "" , "value" : "" };

        if(formularioHijo[item] != "" ){
            item == "cab_valdef" ? variables = JSON.parse(formularioHijo.cab_valdef) : variables = JSON.parse(formularioHijo.lin_valdef);            
        }else{
            variables = [];
        }       
        variables.push(newVariable)
        saveFormularioHijo(
            {   ...formularioHijo, 
                [item]:JSON.stringify(variables)
            }
        ) 
    }

    const handleInputValue = (e, indice, item) => {

        let variables;
        const valor = e.target.value;

        item == "cab_valdef" ? variables = JSON.parse(formularioHijo.cab_valdef) : variables = JSON.parse(formularioHijo.lin_valdef);
        const editVar = variables.filter( (variable, index) =>  index == indice );
        editVar[0]["value"] = valor;

        saveFormularioHijo(
            {   ...formularioHijo, 
                [item]:JSON.stringify(variables)
            }
        )
    }

    const handleSelectKey = (selectedItem, nameOfComponent, indice, item) => {
        let variables;
        item == "cab_valdef" ? variables = JSON.parse(formularioHijo.cab_valdef) : variables = JSON.parse(formularioHijo.lin_valdef);
        const editVar = variables.filter( (variable, index) =>  index == indice );
        
        if( selectedItem ){
            editVar[0]["key"] = selectedItem.value;
        }else{            
            editVar[0]["key"] = "";
        }

        saveFormularioHijo(
            {   ...formularioHijo, 
                [item]:JSON.stringify(variables)
            }
        )    }

    const handleDeleteItem = (indice, item) => {
        let variables;
        item == "cab_valdef" ? variables = JSON.parse(formularioHijo.cab_valdef) : variables = JSON.parse(formularioHijo.lin_valdef);
        const newVariables = variables.filter( (variable, index) =>  index != indice );
        saveFormularioHijo(
            {   ...formularioHijo, 
                [item]:JSON.stringify(newVariables)
            }
        )
    }
    
    /***** FUNCIONES VARIAS *****/
    const reorderRows = async (e, diff, edit) => {        
        if( !reordenandoLineas ){
            saveLoadingTable(true)
            reordenandoLineas = true;
            let datos = [];

            diff.map(d=> {        
                let dataAux = { id: d.node.id.split("#row-")[1], old: d.oldPosition+1, new: d.newPosition+1};         
                datos.push(dataAux);
            })
            const data = new FormData();    
            data.append("validacion", apiKey());
            data.append("datos", JSON.stringify(datos));
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.ciccfg.hijo.datareorder, data, source3.token);
            if( typeof respuesta.data == "string"){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.ciccfg.hijo.reordererror.title}`, {autoClose: true}); 
            }else{                
                // await getDocPlanEnCurso(docPlanActivo); 
                // saveReloadKpi(reloadKpi => reloadKpi+1)           
                alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.ciccfg.hijo.reordersuccess.title}`, {autoClose: true});
                await loadHijos(formularioPrin.id, confPantalla.ciccfg.hijo.urls, null)
                await handleEditRegHijo(formularioHijo.id, confPantalla.ciccfg.hijo.urls, confPantalla.ciccfg.hijo.tabla)
            }
            reordenandoLineas = false;
            saveLoadingTable(false)
        }
    }


    return(
        <div className="App wrapper"> 
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section 
                                titulo={ confPantalla.ciccfg.section.titulo } 
                                icono={ confPantalla.ciccfg.section.icono } 
                                col={ confPantalla.ciccfg.section.col } 
                                idPrin={ formularioPrin.id }
                                idHijo={ formularioHijo.id }
                        >
                                <Tabs defaultActiveKey="tab1" id="tab1">
                                    <Tab eventKey="tab1" title={ confPantalla.ciccfg.tabs.tab1.titulo }>
                                        <Formulario 
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.ciccfg.padre.btnform.new }
                                                    btnSave={ confPantalla.ciccfg.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.ciccfg.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.ciccfg.padre.btnform.delete }
                                                    btnClone={ confPantalla.ciccfg.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.ciccfg.padre.urls, confPantalla.ciccfg.padre.modelform, confPantalla.ciccfg.padre.tabla, confPantalla.ciccfg.hijo.modelform, confPantalla.ciccfg.title) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.ciccfg.padre.modelform, true, confPantalla.ciccfg.padre.tabla) } 
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.ciccfg.padre.urls, confPantalla.ciccfg.padre.modelform, confPantalla.ciccfg.padre.tabla, confPantalla.ciccfg.title) } 
                                                    handleCloneItem = { e => handleClonePrin(formularioPrin.id, confPantalla.ciccfg.padre.urls, confPantalla.ciccfg.hijo.urls, confPantalla.ciccfg.padreshijos, confPantalla.ciccfg.hijo.modelform, confPantalla.ciccfg.padre.tabla, confPantalla.ciccfg.title) } 

                                        >
                                            <Tabs defaultActiveKey="tab1_1" id="tab1_1">
                                                <Tab eventKey="tab1_1" title={ confPantalla.ciccfg.tabs.tab1.subtabs.tab11.titulo }>
                                                    <FormRow clases="" >
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cod }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.ciccfg.padre.controles.cod.id }   
                                                            type=       { confPantalla.ciccfg.padre.controles.cod.type }  
                                                            name=       { confPantalla.ciccfg.padre.controles.cod.name }   
                                                            label=      { confPantalla.ciccfg.padre.controles.cod.label }                                                       
                                                            placeholder={ confPantalla.ciccfg.padre.controles.cod.placeholder }    
                                                            col=        { confPantalla.ciccfg.padre.controles.cod.col } 
                                                            required=   { confPantalla.ciccfg.padre.controles.cod.required }
                                                            readonly=   { confPantalla.ciccfg.padre.controles.cod.readonly }                                                
                                                            disabled=   { confPantalla.ciccfg.padre.controles.cod.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.ciccfg.padre.controles.nom.id }   
                                                            type=       { confPantalla.ciccfg.padre.controles.nom.type }  
                                                            name=       { confPantalla.ciccfg.padre.controles.nom.name }   
                                                            label=      { confPantalla.ciccfg.padre.controles.nom.label }                                                       
                                                            placeholder={ confPantalla.ciccfg.padre.controles.nom.placeholder }    
                                                            col=        { confPantalla.ciccfg.padre.controles.nom.col } 
                                                            required=   { confPantalla.ciccfg.padre.controles.nom.required }
                                                            readonly=   { confPantalla.ciccfg.padre.controles.nom.readonly }                                                
                                                            disabled=   { confPantalla.ciccfg.padre.controles.nom.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        /> 
                                                    </FormRow>
                                                    <FormRow clases="" >
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cod_en }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.ciccfg.padre.controles.cod_en.id }   
                                                            type=       { confPantalla.ciccfg.padre.controles.cod_en.type }  
                                                            name=       { confPantalla.ciccfg.padre.controles.cod_en.name }   
                                                            label=      { confPantalla.ciccfg.padre.controles.cod_en.label }                                                       
                                                            placeholder={ confPantalla.ciccfg.padre.controles.cod_en.placeholder }    
                                                            col=        { confPantalla.ciccfg.padre.controles.cod_en.col } 
                                                            required=   { confPantalla.ciccfg.padre.controles.cod_en.required }
                                                            readonly=   { confPantalla.ciccfg.padre.controles.cod_en.readonly }                                                
                                                            disabled=   { confPantalla.ciccfg.padre.controles.cod_en.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom_en }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.ciccfg.padre.controles.nom_en.id }   
                                                            type=       { confPantalla.ciccfg.padre.controles.nom_en.type }  
                                                            name=       { confPantalla.ciccfg.padre.controles.nom_en.name }   
                                                            label=      { confPantalla.ciccfg.padre.controles.nom_en.label }                                                       
                                                            placeholder={ confPantalla.ciccfg.padre.controles.nom_en.placeholder }    
                                                            col=        { confPantalla.ciccfg.padre.controles.nom_en.col } 
                                                            required=   { confPantalla.ciccfg.padre.controles.nom_en.required }
                                                            readonly=   { confPantalla.ciccfg.padre.controles.nom_en.readonly }                                                
                                                            disabled=   { confPantalla.ciccfg.padre.controles.nom_en.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        /> 
                                                    </FormRow>                                                                                       
                                                </Tab>                                                
                                            </Tabs>
                                        </Formulario>                                     
                                        <MiDataTable 
                                            formulario={formularioPrin}
                                            estadoForm= { estadoFormPrin }
                                            id={"ciccfg"}
                                            columnas={confPantalla.ciccfg.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.ciccfg.padre.datatable.buttons}
                                            ordering={confPantalla.ciccfg.padre.datatable.ordering}
                                            order={confPantalla.ciccfg.padre.datatable.order}
                                            searching={confPantalla.ciccfg.padre.datatable.searching}
                                            paging={confPantalla.ciccfg.padre.datatable.paging}
                                            select={confPantalla.ciccfg.padre.datatable.select}
                                            pagelength={confPantalla.ciccfg.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.ciccfg.padre.urls, confPantalla.ciccfg.hijo.urls, confPantalla.ciccfg.padreshijos, confPantalla.ciccfg.hijo.modelform, confPantalla.ciccfg.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.ciccfg.padre.urls, confPantalla.ciccfg.padre.modelform, confPantalla.ciccfg.padre.tabla, confPantalla.ciccfg.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.ciccfg.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}                                            
                                        />                                             
                                    </Tab>
                                    { formularioPrin.id != 0                         
                                        ? 
                                            <Tab eventKey="tab2" title={ confPantalla.ciccfg.tabs.tab2.titulo }>
                                            <Formulario 
                                                handleSubmit = { e => handleSubmitHijo(e, confPantalla.ciccfg.hijo.urls, confPantalla.ciccfg.hijo.modelform, confPantalla.ciccfg.hijo.tabla, confPantalla.ciccfg.padre.urls, confPantalla.ciccfg.padre.tabla, confPantalla.ciccfg.title2) } 
                                                formulario = { formularioHijo } 
                                                resetForm = { e => resetFormHijo(confPantalla.ciccfg.hijo.modelform) }
                                                btnClone={ confPantalla.ciccfg.hijo.btnform.clone } 
                                                handleDeleteItem = { e => handleDeleteItemHijo(undefined, confPantalla.ciccfg.hijo.urls, confPantalla.ciccfg.hijo.modelform, confPantalla.ciccfg.hijo.tabla, confPantalla.ciccfg.padre.urls, confPantalla.ciccfg.padre.tabla, confPantalla.ciccfg.title2) }
                                                handleCloneItem = { e => handleCloneHijo(formularioHijo.id, confPantalla.ciccfg.hijo.urls, confPantalla.ciccfg.hijo.tabla, confPantalla.ciccfg.padre.urls, confPantalla.ciccfg.padre.tabla, confPantalla.ciccfg.title2) }
                                                esHijo= { true }
                                                formularioPadre= { formularioPrin }  
                                            >
                                                <input type="hidden" name="idcic" id="ciccfglin.idcic" value={ formularioPrin.id } />
                                                    <Tabs defaultActiveKey="tab1_1" id="tab1_1">
                                                        <Tab eventKey="tab1_1" title={ confPantalla.ciccfg.tabs.tab2.subtabs.tab21.titulo }>
                                                            <FormRow>
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.step }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.ciccfg.hijo.controles.step.id }   
                                                                    type=       { confPantalla.ciccfg.hijo.controles.step.type }  
                                                                    name=       { confPantalla.ciccfg.hijo.controles.step.name }   
                                                                    label=      { confPantalla.ciccfg.hijo.controles.step.label }                                                       
                                                                    placeholder={ confPantalla.ciccfg.hijo.controles.step.placeholder }    
                                                                    col=        { confPantalla.ciccfg.hijo.controles.step.col } 
                                                                    required=   { confPantalla.ciccfg.hijo.controles.step.required }
                                                                    readonly=   { confPantalla.ciccfg.hijo.controles.step.readonly }                                                
                                                                    disabled=   { confPantalla.ciccfg.hijo.controles.step.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.idtip  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.ciccfg.hijo.controles.idtip.id }    
                                                                    name=        { confPantalla.ciccfg.hijo.controles.idtip.name }   
                                                                    label=       { confPantalla.ciccfg.hijo.controles.idtip.label }    
                                                                    placeholder= { confPantalla.ciccfg.hijo.controles.idtip.placeholder }
                                                                    col=         { confPantalla.ciccfg.hijo.controles.idtip.col } 
                                                                    isClearable= { confPantalla.ciccfg.hijo.controles.idtip.isclearable }
                                                                    isSearchable={ confPantalla.ciccfg.hijo.controles.idtip.issearchable }
                                                                    dataType=    { confPantalla.ciccfg.hijo.controles.idtip.datatype }
                                                                    data=        { confPantalla.ciccfg.hijo.controles.idtip.data }
                                                                    required=    { confPantalla.ciccfg.hijo.controles.idtip.required } 
                                                                    disabled=  { confPantalla.ciccfg.hijo.controles.idtip.disabled }
                                                                    readOnly =   { confPantalla.ciccfg.hijo.controles.idtip.readonly }
                                                                    disablethisid={confPantalla.ciccfg.hijo.controles.idtip.disablethisid}
                                                                    disableids={confPantalla.ciccfg.hijo.controles.idtip.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />                                                            
                                                            </FormRow>
                                                            <FormRow>
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.opt  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.ciccfg.hijo.controles.opt.id }    
                                                                    name=        { confPantalla.ciccfg.hijo.controles.opt.name }   
                                                                    label=       { confPantalla.ciccfg.hijo.controles.opt.label }    
                                                                    placeholder= { confPantalla.ciccfg.hijo.controles.opt.placeholder }
                                                                    col=         { confPantalla.ciccfg.hijo.controles.opt.col } 
                                                                    isClearable= { confPantalla.ciccfg.hijo.controles.opt.isclearable }
                                                                    isSearchable={ confPantalla.ciccfg.hijo.controles.opt.issearchable }
                                                                    dataType=    { confPantalla.ciccfg.hijo.controles.opt.datatype }
                                                                    data=        { confPantalla.ciccfg.hijo.controles.opt.data }
                                                                    required=    { confPantalla.ciccfg.hijo.controles.opt.required } 
                                                                    disabled=  { confPantalla.ciccfg.hijo.controles.opt.disabled }
                                                                    readOnly =   { confPantalla.ciccfg.hijo.controles.opt.readonly }
                                                                    disablethisid={confPantalla.ciccfg.hijo.controles.opt.disablethisid}
                                                                    disableids={confPantalla.ciccfg.hijo.controles.opt.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.divi  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.ciccfg.hijo.controles.divi.id }    
                                                                    name=        { confPantalla.ciccfg.hijo.controles.divi.name }   
                                                                    label=       { confPantalla.ciccfg.hijo.controles.divi.label }    
                                                                    placeholder= { confPantalla.ciccfg.hijo.controles.divi.placeholder }
                                                                    col=         { confPantalla.ciccfg.hijo.controles.divi.col } 
                                                                    isClearable= { confPantalla.ciccfg.hijo.controles.divi.isclearable }
                                                                    isSearchable={ confPantalla.ciccfg.hijo.controles.divi.issearchable }
                                                                    dataType=    { confPantalla.ciccfg.hijo.controles.divi.datatype }
                                                                    data=        { confPantalla.ciccfg.hijo.controles.divi.data }
                                                                    required=    { confPantalla.ciccfg.hijo.controles.divi.required } 
                                                                    disabled=  { confPantalla.ciccfg.hijo.controles.divi.disabled }
                                                                    readOnly =   { confPantalla.ciccfg.hijo.controles.divi.readonly }
                                                                    disablethisid={confPantalla.ciccfg.hijo.controles.divi.disablethisid}
                                                                    disableids={confPantalla.ciccfg.hijo.controles.divi.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.apl  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.ciccfg.hijo.controles.apl.id }    
                                                                    name=        { confPantalla.ciccfg.hijo.controles.apl.name }   
                                                                    label=       { confPantalla.ciccfg.hijo.controles.apl.label }    
                                                                    placeholder= { confPantalla.ciccfg.hijo.controles.apl.placeholder }
                                                                    col=         { confPantalla.ciccfg.hijo.controles.apl.col } 
                                                                    isClearable= { confPantalla.ciccfg.hijo.controles.apl.isclearable }
                                                                    isSearchable={ confPantalla.ciccfg.hijo.controles.apl.issearchable }
                                                                    dataType=    { confPantalla.ciccfg.hijo.controles.apl.datatype }
                                                                    data=        { confPantalla.ciccfg.hijo.controles.apl.data }
                                                                    required=    { confPantalla.ciccfg.hijo.controles.apl.required } 
                                                                    disabled=  { confPantalla.ciccfg.hijo.controles.apl.disabled }
                                                                    readOnly =   { confPantalla.ciccfg.hijo.controles.apl.readonly }
                                                                    disablethisid={confPantalla.ciccfg.hijo.controles.apl.disablethisid}
                                                                    disableids={confPantalla.ciccfg.hijo.controles.apl.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />
                                                            </FormRow>
                                                            <FormRow>
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.cab_est  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.ciccfg.hijo.controles.cab_est.id }    
                                                                    name=        { confPantalla.ciccfg.hijo.controles.cab_est.name }   
                                                                    label=       { confPantalla.ciccfg.hijo.controles.cab_est.label }    
                                                                    placeholder= { confPantalla.ciccfg.hijo.controles.cab_est.placeholder }
                                                                    col=         { confPantalla.ciccfg.hijo.controles.cab_est.col } 
                                                                    isClearable= { confPantalla.ciccfg.hijo.controles.cab_est.isclearable }
                                                                    isSearchable={ confPantalla.ciccfg.hijo.controles.cab_est.issearchable }
                                                                    dataType=    { confPantalla.ciccfg.hijo.controles.cab_est.datatype }
                                                                    data=        { confPantalla.ciccfg.hijo.controles.cab_est.data }
                                                                    required=    { confPantalla.ciccfg.hijo.controles.cab_est.required } 
                                                                    disabled=  { confPantalla.ciccfg.hijo.controles.cab_est.disabled }
                                                                    readOnly =   { confPantalla.ciccfg.hijo.controles.cab_est.readonly }
                                                                    disablethisid={confPantalla.ciccfg.hijo.controles.cab_est.disablethisid}
                                                                    disableids={confPantalla.ciccfg.hijo.controles.cab_est.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.lin_est  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.ciccfg.hijo.controles.lin_est.id }    
                                                                    name=        { confPantalla.ciccfg.hijo.controles.lin_est.name }   
                                                                    label=       { confPantalla.ciccfg.hijo.controles.lin_est.label }    
                                                                    placeholder= { confPantalla.ciccfg.hijo.controles.lin_est.placeholder }
                                                                    col=         { confPantalla.ciccfg.hijo.controles.lin_est.col } 
                                                                    isClearable= { confPantalla.ciccfg.hijo.controles.lin_est.isclearable }
                                                                    isSearchable={ confPantalla.ciccfg.hijo.controles.lin_est.issearchable }
                                                                    dataType=    { confPantalla.ciccfg.hijo.controles.lin_est.datatype }
                                                                    data=        { confPantalla.ciccfg.hijo.controles.lin_est.data }
                                                                    required=    { confPantalla.ciccfg.hijo.controles.lin_est.required } 
                                                                    disabled=  { confPantalla.ciccfg.hijo.controles.lin_est.disabled }
                                                                    readOnly =   { confPantalla.ciccfg.hijo.controles.lin_est.readonly }
                                                                    disablethisid={confPantalla.ciccfg.hijo.controles.lin_est.disablethisid}
                                                                    disableids={confPantalla.ciccfg.hijo.controles.lin_est.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />
                                                                <hr className="separator" />
                                                                <MiSelectMultiple  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo["cab_noclon"].split(',')  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.ciccfg.hijo.controles.cab_noclon_aux.id }    
                                                                    name=        { "" }   
                                                                    label=       { confPantalla.ciccfg.hijo.controles.cab_noclon_aux.label }    
                                                                    placeholder= { confPantalla.ciccfg.hijo.controles.cab_noclon_aux.placeholder }
                                                                    col=         { confPantalla.ciccfg.hijo.controles.cab_noclon_aux.col } 
                                                                    isClearable= { confPantalla.ciccfg.hijo.controles.cab_noclon_aux.isclearable }
                                                                    isSearchable={ confPantalla.ciccfg.hijo.controles.cab_noclon_aux.issearchable }
                                                                    dataType=    { confPantalla.ciccfg.hijo.controles.cab_noclon_aux.datatype }
                                                                    data=        { confPantalla.ciccfg.hijo.controles.cab_noclon_aux.data }
                                                                    required=    { confPantalla.ciccfg.hijo.controles.cab_noclon_aux.required } 
                                                                    disabled=    { confPantalla.ciccfg.hijo.controles.cab_noclon_aux.disabled }
                                                                    readOnly =   { confPantalla.ciccfg.hijo.controles.cab_noclon_aux.readonly }                                                                  
                                                                    formulario=  { {"id":0} }   
                                                                    change= { (selectedItem, nameOfComponent) => handleSelectMultiple(selectedItem, nameOfComponent, "cab_noclon") }
                                                                />
                                                                <TextArea                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.cab_noclon }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.ciccfg.hijo.controles.cab_noclon.id }   
                                                                    name=       { confPantalla.ciccfg.hijo.controles.cab_noclon.name }
                                                                    rows=       { confPantalla.ciccfg.hijo.controles.cab_noclon.rows }      
                                                                    label=      { confPantalla.ciccfg.hijo.controles.cab_noclon.label }
                                                                    placeholder={ confPantalla.ciccfg.hijo.controles.cab_noclon.placeholder }    
                                                                    col=        { confPantalla.ciccfg.hijo.controles.cab_noclon.col } 
                                                                    required=   { confPantalla.ciccfg.hijo.controles.cab_noclon.required }
                                                                    readonly=   { confPantalla.ciccfg.hijo.controles.cab_noclon.readonly }
                                                                    disabled=   { confPantalla.ciccfg.hijo.controles.cab_noclon.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <hr className="separator" />
                                                                <MiSelectMultiple  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo["lin_noclon"].split(',')   }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.ciccfg.hijo.controles.lin_noclon_aux.id }    
                                                                    name=        { "" }   
                                                                    label=       { confPantalla.ciccfg.hijo.controles.lin_noclon_aux.label }    
                                                                    placeholder= { confPantalla.ciccfg.hijo.controles.lin_noclon_aux.placeholder }
                                                                    col=         { confPantalla.ciccfg.hijo.controles.lin_noclon_aux.col } 
                                                                    isClearable= { confPantalla.ciccfg.hijo.controles.lin_noclon_aux.isclearable }
                                                                    isSearchable={ confPantalla.ciccfg.hijo.controles.lin_noclon_aux.issearchable }
                                                                    dataType=    { confPantalla.ciccfg.hijo.controles.lin_noclon_aux.datatype }
                                                                    data=        { confPantalla.ciccfg.hijo.controles.lin_noclon_aux.data }
                                                                    required=    { confPantalla.ciccfg.hijo.controles.lin_noclon_aux.required } 
                                                                    disabled=    { confPantalla.ciccfg.hijo.controles.lin_noclon_aux.disabled }
                                                                    readOnly =   { confPantalla.ciccfg.hijo.controles.lin_noclon_aux.readonly }                                                                  
                                                                    formulario=  { {"id":0} }   
                                                                    change= { (selectedItem, nameOfComponent) => handleSelectMultiple(selectedItem, nameOfComponent, "lin_noclon")}
                                                                />
                                                                <TextArea                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.lin_noclon }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.ciccfg.hijo.controles.lin_noclon.id }   
                                                                    name=       { confPantalla.ciccfg.hijo.controles.lin_noclon.name }
                                                                    rows=       { confPantalla.ciccfg.hijo.controles.lin_noclon.rows }      
                                                                    label=      { confPantalla.ciccfg.hijo.controles.lin_noclon.label }
                                                                    placeholder={ confPantalla.ciccfg.hijo.controles.lin_noclon.placeholder }    
                                                                    col=        { confPantalla.ciccfg.hijo.controles.lin_noclon.col } 
                                                                    required=   { confPantalla.ciccfg.hijo.controles.lin_noclon.required }
                                                                    readonly=   { confPantalla.ciccfg.hijo.controles.lin_noclon.readonly }
                                                                    disabled=   { confPantalla.ciccfg.hijo.controles.lin_noclon.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <hr className="separator" />
                                                                <Button 
                                                                    clases={ "btn-block mb-2" }
                                                                    color={ "success" }  
                                                                    type="button" 
                                                                    icono={ "fal fa-plus" }   
                                                                    id=""     
                                                                    texto={ confPantalla.ciccfg.hijo.controles.btnadd.title }   
                                                                    accion={ e => handleAddColumn(e, "cab_valdef") } 
                                                                    disabled={ loadReg ? true : false }
                                                                />
                                                                {
                                formularioHijo.cab_valdef != "" && formularioHijo.cab_valdef != undefined
                                ?
                                JSON.parse(formularioHijo.cab_valdef).map((field, index, arr) => {                                                    
                                    return(
                                        <Fragment key={index}>                                    
                                            <FormRow key={index} clases=" col-12 " >
                                                <MiSelect  
                                                    estadoForm=  { "" }
                                                    val=         { field.key  }   
                                                    loading=     { loadReg ? true : false }
                                                    id=          { "key-"+index }    
                                                    name=        { "" }   
                                                    label=       { confPantalla.ciccfg.hijo.controles.cab_valdefitem.key.title }    
                                                    placeholder= { "" }
                                                    col=         { "5" } 
                                                    isClearable= { false }
                                                    isSearchable={ true }
                                                    dataType=    { confPantalla.ciccfg.hijo.controles.cab_valdefitem.key.datatype }
                                                    data=        { confPantalla.ciccfg.hijo.controles.cab_valdefitem.key.data }
                                                    required=    { false } 
                                                    disabled=  { false }
                                                    readOnly =   { false }
                                                    disablethisid={false}
                                                    formulario= { {id:0} }   
                                                    change= { (selectedItem, nameOfComponent) => handleSelectKey(selectedItem, nameOfComponent, index, "cab_valdef") }
                                                />
                                                <FormInput                                                        
                                                    estadoForm= { "" }
                                                    value=      { field.value }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { "val-"+index }   
                                                    type=       { "text" }  
                                                    label=      { confPantalla.ciccfg.hijo.controles.cab_valdefitem.value.title }
                                                    placeholder={ "" }    
                                                    col=        { "5" } 
                                                    required=   { false }
                                                    readonly=   { false }                                                
                                                    disabled=   { false }
                                                    change=     { e => handleInputValue(e, index, "cab_valdef") }
                                                    onBlur=     {null} 
                                                />                                      
                                                <div className={"mt-4 col-md-2"}>
                                                    <Button 
                                                        clases={ "btn-block mt-2" }
                                                        color={ "danger" }  
                                                        type="button" 
                                                        icono={ "fal fa-trash-alt" }   
                                                        id={index}     
                                                        texto={ "" }   
                                                        accion={ e => handleDeleteItem(index, "cab_valdef") } 
                                                        disabled={ false }
                                                    />                    
                                                </div>
                                            </FormRow>
                                        </Fragment>
                                    )
                                    })
                                :
                                    null
                            } 
                                                                <TextArea                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { MiReplaceAll(formularioHijo.cab_valdef, "\\", "") }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.ciccfg.hijo.controles.cab_valdef.id }   
                                                                    name=       { confPantalla.ciccfg.hijo.controles.cab_valdef.name }
                                                                    rows=       { confPantalla.ciccfg.hijo.controles.cab_valdef.rows }      
                                                                    label=      { confPantalla.ciccfg.hijo.controles.cab_valdef.label }
                                                                    placeholder={ confPantalla.ciccfg.hijo.controles.cab_valdef.placeholder }    
                                                                    col=        { confPantalla.ciccfg.hijo.controles.cab_valdef.col } 
                                                                    required=   { confPantalla.ciccfg.hijo.controles.cab_valdef.required }
                                                                    readonly=   { confPantalla.ciccfg.hijo.controles.cab_valdef.readonly }
                                                                    disabled=   { confPantalla.ciccfg.hijo.controles.cab_valdef.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <hr className="separator" />
                                                                <Button 
                                                                    clases={ "btn-block mb-2" }
                                                                    color={ "success" }  
                                                                    type="button" 
                                                                    icono={ "fal fa-plus" }   
                                                                    id=""     
                                                                    texto={ confPantalla.ciccfg.hijo.controles.btnadd.title }   
                                                                    accion={ e => handleAddColumn(e, "lin_valdef") } 
                                                                    disabled={ loadReg ? true : false }
                                                                />
                                                                {
                                formularioHijo.lin_valdef != "" && formularioHijo.lin_valdef != undefined
                                ?
                                JSON.parse(formularioHijo.lin_valdef).map((field, index, arr) => {                                                    
                                    return(
                                        <Fragment key={index}>                                    
                                            <FormRow key={index} clases=" col-12 " >
                                                <MiSelect  
                                                    estadoForm=  { "" }
                                                    val=         { field.key  }   
                                                    loading=     { loadReg ? true : false }
                                                    id=          { "key-"+index }    
                                                    name=        { "" }   
                                                    label=       { confPantalla.ciccfg.hijo.controles.lin_valdefitem.key.title }    
                                                    placeholder= { "" }
                                                    col=         { "5" } 
                                                    isClearable= { false }
                                                    isSearchable={ true }
                                                    dataType=    { confPantalla.ciccfg.hijo.controles.lin_valdefitem.key.datatype }
                                                    data=        { confPantalla.ciccfg.hijo.controles.lin_valdefitem.key.data }
                                                    required=    { false } 
                                                    disabled=  { false }
                                                    readOnly =   { false }
                                                    disablethisid={false}
                                                    formulario= { {id:0} }   
                                                    change= { (selectedItem, nameOfComponent) => handleSelectKey(selectedItem, nameOfComponent, index, "lin_valdef") }
                                                />
                                                <FormInput                                                        
                                                    estadoForm= { "" }
                                                    value=      { field.value }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { "val-"+index }   
                                                    type=       { "text" }  
                                                    label=      { confPantalla.ciccfg.hijo.controles.cab_valdefitem.value.title }
                                                    placeholder={ "" }    
                                                    col=        { "5" } 
                                                    required=   { false }
                                                    readonly=   { false }                                                
                                                    disabled=   { false }
                                                    change=     { e => handleInputValue(e, index, "lin_valdef") }
                                                    onBlur=     {null} 
                                                />                                      
                                                <div className={"mt-4 col-md-2"}>
                                                    <Button 
                                                        clases={ "btn-block mt-2" }
                                                        color={ "danger" }  
                                                        type="button" 
                                                        icono={ "fal fa-trash-alt" }   
                                                        id={index}     
                                                        texto={ "" }   
                                                        accion={ e => handleDeleteItem(index, "lin_valdef") } 
                                                        disabled={ false }
                                                    />                    
                                                </div>
                                            </FormRow>
                                        </Fragment>
                                    )
                                    })
                                :
                                    null
                            }
                                                                <TextArea                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { MiReplaceAll(formularioHijo.lin_valdef, "\\", "") }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.ciccfg.hijo.controles.lin_valdef.id }   
                                                                    name=       { confPantalla.ciccfg.hijo.controles.lin_valdef.name }
                                                                    rows=       { confPantalla.ciccfg.hijo.controles.lin_valdef.rows }      
                                                                    label=      { confPantalla.ciccfg.hijo.controles.lin_valdef.label }
                                                                    placeholder={ confPantalla.ciccfg.hijo.controles.lin_valdef.placeholder }    
                                                                    col=        { confPantalla.ciccfg.hijo.controles.lin_valdef.col } 
                                                                    required=   { confPantalla.ciccfg.hijo.controles.lin_valdef.required }
                                                                    readonly=   { confPantalla.ciccfg.hijo.controles.lin_valdef.readonly }
                                                                    disabled=   { confPantalla.ciccfg.hijo.controles.lin_valdef.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />     
                                                            </FormRow>                                                            
                                                        </Tab> 
                                                    </Tabs>                                                    
                                                </Formulario>
                                                <MiDataTable 
                                                    formulario={formularioHijo} 
                                                    estadoForm= { estadoFormHijo }
                                                    id={"ciccfgHijo"}
                                                    columnas={confPantalla.ciccfg.hijo.datatable.columns}
                                                    data={registrosHijo}
                                                    buttons={confPantalla.ciccfg.hijo.datatable.buttons}
                                                    ordering={confPantalla.ciccfg.hijo.datatable.ordering}
                                                    order={confPantalla.ciccfg.hijo.datatable.order}
                                                    searching={confPantalla.ciccfg.hijo.datatable.searching}
                                                    paging={confPantalla.ciccfg.hijo.datatable.paging}
                                                    select={confPantalla.ciccfg.hijo.datatable.select}
                                                    pagelength={confPantalla.ciccfg.hijo.datatable.pagelength}
                                                    handleEditReg= { e => handleEditRegHijo(e, confPantalla.ciccfg.hijo.urls, confPantalla.ciccfg.hijo.tabla) }
                                                    handleDeleteItem={ e => handleDeleteItemHijo(e, confPantalla.ciccfg.hijo.urls, confPantalla.ciccfg.hijo.modelform, confPantalla.ciccfg.hijo.tabla, confPantalla.ciccfg.padre.urls, confPantalla.ciccfg.padre.tabla, confPantalla.ciccfg.title2) }
                                                    saveLoadingTable={ saveLoadingTable }
                                                    loadingTable={loadingTable}
                                                    columnactions={confPantalla.ciccfg.hijo.datatable.columnactions.title}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                    rowreorder={confPantalla.ciccfg.hijo.datatable.rowreorder}
                                                    functionAfterReorder={reorderRows}
                                                />                                                
                                            </Tab>
                                        :
                                        null
                                    }
                                    
                                </Tabs> 
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Ciccfg