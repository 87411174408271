import React, { useEffect, useContext, useState, Fragment } from 'react';
import axios from 'axios';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiSelect from '../../componentes/formulario/MiSelect';
import { Alert } from '../../componentes/ui/Alert';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';
import CheckIos from '../../componentes/ui/CheckIos';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import { getTitle } from '../../helpers/helperApp';

import Cookies from 'universal-cookie';

function PermisosUsu(props){

    const cookies = new Cookies();

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin } = useLogicForm();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{            
                    document.title = getTitle(confPantalla.config.trad, confPantalla.permisosusu.title);                            
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        saveEstadoFormPrin(confPantalla.permisosusu.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.permisosusu.hijo.estadoform);                             
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.permisosusu.padre.urls);
                                saveFormularioPrin(confPantalla.permisosusu.padre.modelform);
                                saveFormularioHijo(confPantalla.permisosusu.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.permisosusu.padre.urls, confPantalla.permisosusu.hijo.urls, confPantalla.permisosusu.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);    
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                   
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 
        
    },[confPantalla])
    
    

 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Fragment>
                                {
                                    formularioPrin.optimista == "1"
                                    ?
                                        <Section titulo={ confPantalla.permisosusu.sectionconf.titulo.title } icono={ confPantalla.permisosusu.sectionconf.titulo.icono } col={ confPantalla.permisosusu.sectionconf.col } >
                                            <p>{ confPantalla.permisosusu.sectionconf.parrafo.title }</p>
                                            <CheckIos
                                                val= {"1"}
                                                name={null}
                                                label={ confPantalla.permisosusu.sectionconf.permisivo.label.title }
                                                id={"permisivo"}
                                                col={"12"}
                                                disabled={ true } 
                                            /> 
                                            <CheckIos
                                                val= {"0"}
                                                name={null}
                                                label={ confPantalla.permisosusu.sectionconf.restrictivo.label.title }
                                                id={"restrictivo"}
                                                col={"12"}
                                                disabled={ true } 
                                            />
                                            <blockquote className="blockquote" style={{ borderLeft: ".5rem solid #6c757d"}}>
                                                <h4 className="mb-0 text-dark">{confPantalla.permisosusu.sectionconf.blockquote.title }</h4>
                                                <footer className="blockquote-footer">{ confPantalla.permisosusu.sectionconf.permisivo.msg.title }</footer>
                                            </blockquote>
                                        </Section>
                                    :
                                        formularioPrin.optimista == "0" 
                                        ?
                                        <Section titulo={ confPantalla.permisosusu.sectionconf.titulo.title } icono={ confPantalla.permisosusu.sectionconf.titulo.icono } col={ confPantalla.permisosusu.sectionconf.col } >                                            
                                            <p>{ confPantalla.permisosusu.sectionconf.parrafo.title }</p>
                                            <CheckIos
                                                val= {"0"}
                                                name={null}
                                                label={ confPantalla.permisosusu.sectionconf.permisivo.label.title }
                                                id={"permisivo"}
                                                col={"12"}
                                                disabled={ true } 
                                            /> 
                                            <CheckIos
                                                val= {"1"}
                                                name={null}
                                                label={ confPantalla.permisosusu.sectionconf.restrictivo.label.title }
                                                id={"restrictivo"}
                                                col={"12"}
                                                disabled={ true } 
                                            />
                                            <blockquote className="blockquote" style={{ borderLeft: ".5rem solid #6c757d"}}>
                                                <h4 className="mb-0 text-dark">{confPantalla.permisosusu.sectionconf.blockquote.title }</h4>
                                                <footer className="blockquote-footer">{ confPantalla.permisosusu.sectionconf.restrictivo.msg.title }</footer>
                                            </blockquote>
                                        </Section>
                                        :
                                        null
                                }
                           

                            
                            
                            <Section titulo={ confPantalla.permisosusu.section.titulo } icono={ confPantalla.permisosusu.section.icono } col={ confPantalla.permisosusu.section.col } wiki={ confPantalla.permisosusu.section.wiki } >
                                            <Formulario 
                                                        estadoForm= { estadoFormPrin }
                                                        loading=    { loadReg ? true : false }
                                                        btnNew={ confPantalla.permisosusu.padre.btnform.new }
                                                        btnSave={ formularioPrin.id != "" ? confPantalla.permisosusu.padre.btnform.save : "false" }
                                                        btnSaveAndNew={ confPantalla.permisosusu.padre.btnform.saveandnew }
                                                        btnDelete={ confPantalla.permisosusu.padre.btnform.delete }
                                                        btnClone={ confPantalla.permisosusu.padre.btnform.clone }
                                                        handleSubmit = { e => handleSubmitPrin(e, confPantalla.permisosusu.padre.urls, confPantalla.permisosusu.padre.modelform, confPantalla.permisosusu.padre.tabla, confPantalla.permisosusu.hijo.modelform, confPantalla.permisosusu.title) }
                                                        formulario = { formularioPrin } 
                                                        resetForm = { e => resetFormPrin(confPantalla.equipos.padre.modelform, true, confPantalla.equipos.padre.tabla) } 
                                                        handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.equipos.padre.urls, confPantalla.equipos.padre.modelform, confPantalla.equipos.padre.tabla, confPantalla.equipos.title) }
                                            >
                                                <FormRow>
                                                    {
                                                        formularioPrin.location != ""
                                                        ?
                                                            <Fragment>
                                                                
                                                                <blockquote className="blockquote" style={{ borderLeft: ".5rem solid #6c757d"}}>
                                                                    <h2 className="mb-4 text-dark">{confPantalla.permisosusu.section.blockquote.title} {formularioPrin.location}</h2>
                                                                    <footer className="blockquote-footer">{ confPantalla.permisosusu.section.blockquote.p1.title }</footer>
                                                                    <footer className="blockquote-footer">{ confPantalla.permisosusu.section.blockquote.p2.title }</footer>
                                                                    <footer className="blockquote-footer">{ confPantalla.permisosusu.section.blockquote.p3.title }</footer>
                                                                </blockquote>
                                                            </Fragment>
 
                                                        :
                                                            null
                                                    }
                                                </FormRow>
                                                {
                                                        formularioPrin.location != ""
                                                        ?
                                                            <Fragment>
                                                                <Tabs defaultActiveKey="tab1" id="tab1" className="tabsPermisos">
                                                                    <Tab eventKey="tab1" title={<span><i className="fal fa-user-slash" /> { confPantalla.permisosusu.tabs.tab1.title }</span>}>
                                                                        <FormRow clases="" >
                                                                            <ListaRel 
                                                                                estadoForm=  { estadoFormPrin }
                                                                                val=         { formularioPrin.rel_loc_usud  }   
                                                                                loading=     { loadReg ? true : false }
                                                                                id=          { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.id }    
                                                                                name=        { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.name }   
                                                                                label=       { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.label }    
                                                                                placeholder= { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.placeholder }
                                                                                col=         { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.col } 
                                                                                isClearable= { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.isclearable }
                                                                                isSearchable={ confPantalla.permisosusu.padre.controles.rel_loc_usud.select.issearchable }
                                                                                dataType=    { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.datatype }
                                                                                data=        { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.data }
                                                                                required=    { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.required } 
                                                                                disabled=  { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.disabled }
                                                                                readOnly =   { confPantalla.permisosusu.padre.controles.rel_loc_usud.select.readonly }
                                                                                disablethisid={ confPantalla.permisosusu.padre.controles.rel_loc_usud.select.disablethisid }
                                                                                formulario = { formularioPrin }
                                                                                saveFormulario = { saveFormularioPrin }
                                                                                table = { confPantalla.permisosusu.padre.controles.rel_loc_usud.table }
                                                                                traduction={confPantalla.config.trad.components.datatable}
                                                                            />
                                                                        </FormRow>                                                                    
                                                                    </Tab>
                                                                    <Tab eventKey="tab2" title={<span><i className="fal fa-user" /> { confPantalla.permisosusu.tabs.tab2.title }</span>}>
                                                                        <FormRow clases="" >
                                                                            <ListaRel
                                                                                estadoForm=  { estadoFormPrin }
                                                                                val=         { formularioPrin.rel_loc_usua  }   
                                                                                loading=     { loadReg ? true : false }
                                                                                id=          { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.id }    
                                                                                name=        { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.name }   
                                                                                label=       { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.label }    
                                                                                placeholder= { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.placeholder }
                                                                                col=         { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.col } 
                                                                                isClearable= { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.isclearable }
                                                                                isSearchable={ confPantalla.permisosusu.padre.controles.rel_loc_usua.select.issearchable }
                                                                                dataType=    { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.datatype }
                                                                                data=        { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.data }
                                                                                required=    { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.required } 
                                                                                disabled=  { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.disabled }
                                                                                readOnly =   { confPantalla.permisosusu.padre.controles.rel_loc_usua.select.readonly }
                                                                                disablethisid={ confPantalla.permisosusu.padre.controles.rel_loc_usua.select.disablethisid }
                                                                                formulario = { formularioPrin }
                                                                                saveFormulario = { saveFormularioPrin }
                                                                                table = { confPantalla.permisosusu.padre.controles.rel_loc_usua.table }
                                                                                traduction={confPantalla.config.trad.components.datatable}
                                                                            />
                                                                        </FormRow>
                                                                    </Tab>
                                                                    <Tab eventKey="tab3" title={<span><i className="fal fa-users" /> { confPantalla.permisosusu.tabs.tab3.title }</span>}>
                                                                        <FormRow clases="" >
                                                                            <ListaRel
                                                                                estadoForm=  { estadoFormPrin }
                                                                                val=         { formularioPrin.rel_loc_nvl  }   
                                                                                loading=     { loadReg ? true : false }
                                                                                id=          { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.id }    
                                                                                name=        { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.name }   
                                                                                label=       { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.label }    
                                                                                placeholder= { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.placeholder }
                                                                                col=         { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.col } 
                                                                                isClearable= { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.isclearable }
                                                                                isSearchable={ confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.issearchable }
                                                                                dataType=    { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.datatype }
                                                                                data=        { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.data }
                                                                                required=    { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.required } 
                                                                                disabled=  { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.disabled }
                                                                                readOnly =   { confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.readonly }
                                                                                disablethisid={ confPantalla.permisosusu.padre.controles.rel_loc_nvl.select.disablethisid }
                                                                                formulario = { formularioPrin }
                                                                                saveFormulario = { saveFormularioPrin }
                                                                                table = { confPantalla.permisosusu.padre.controles.rel_loc_nvl.table }
                                                                                traduction={confPantalla.config.trad.components.datatable}
                                                                            />
                                                                        </FormRow>                         
                                                                    </Tab>
                                                                </Tabs>
                                                            </Fragment> 
                                                        :
                                                            null
                                                }
                                        
                                                                                
                                            </Formulario>
                                            <MiDataTable
                                                formulario={formularioPrin} 
                                                estadoForm= { "readonly" }
                                                id={"permisosusu"}
                                                columnas={confPantalla.permisosusu.padre.datatable.columns}
                                                data={registrosPrin}
                                                buttons={confPantalla.permisosusu.padre.datatable.buttons}
                                                ordering={confPantalla.permisosusu.padre.datatable.ordering}
                                                order={confPantalla.permisosusu.padre.datatable.order}
                                                searching={confPantalla.permisosusu.padre.datatable.searching}
                                                paging={confPantalla.permisosusu.padre.datatable.paging}
                                                select={confPantalla.permisosusu.padre.datatable.select}
                                                pagelength={confPantalla.permisosusu.padre.datatable.pagelength}
                                                handleEditReg= { e => handleEditRegPrin(e, confPantalla.permisosusu.padre.urls, confPantalla.permisosusu.hijo.urls, confPantalla.permisosusu.padreshijos, confPantalla.permisosusu.hijo.modelform, confPantalla.permisosusu.padre.tabla) }
                                                handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.permisosusu.padre.urls, confPantalla.permisosusu.padre.modelform, confPantalla.permisosusu.padre.tabla, confPantalla.permisosusu.title) }
                                                saveLoadingTable={ saveLoadingTable }
                                                loadingTable={loadingTable}
                                                columnactions={confPantalla.permisosusu.padre.datatable.columnactions.title}
                                                traduction={confPantalla.config.trad.components.datatable}
                                            />                                                                                                                 
                                </Section>
                            </Fragment>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default PermisosUsu