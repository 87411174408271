import React, { useEffect, useContext, useState, Fragment } from 'react';
import axios from 'axios';
import $ from 'jquery';
import DataTable from 'datatables.net';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import LoadingPage from '../../componentes/ui/LoadingPage';
import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Kpi from '../../componentes/ui/Kpi';
import FormInput from '../../componentes/formulario/FormInput';
import Section from '../../componentes/ui/Section';
import { Alert } from '../../componentes/ui/Alert';
import { Accordion, Card } from "react-bootstrap";
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiDataTableCheckList from './MiDataTableCheckList';
import CalendarioYear from '../../componentes/ui/CalendarioYear';
import swal from 'sweetalert';
import { alertService } from '../../services/alertservice';
import Modal from 'react-bootstrap/Modal';
import MiSelect from '../../componentes/formulario/MiSelect';
import Button from '../../componentes/ui/Button';
import Informes from '../../componentes/formulario/informes/Informes';
import { Spinner } from 'react-bootstrap';

import { getAhorayyyymmdd, getTitle } from '../../helpers/helperApp';
import FormRow from '../../componentes/formulario/FormRow';

import { getApiUrl, getLinkReadOnly, axiosPost, getNumFromData } from '../../helpers/helperApp';

function CheckList(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();

    // ***** Context *****
    const { validateSesion, confApp, saveConfApp, getConfApp, apiKey, linkReadOnly, respSimp, centroActivo } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);

    const [ loading, saveLoading ] = useState(false);
    const [ loadingAgentes, saveLoadingAgentes ] = useState(false);
    const [ reloadKpi, saveReloadKpi ] = useState(0);
    
    const [ acordeonPrin, saveAcordeonPrin ] = useState(null);
    const [ datosTablePlanificados, saveDatosTablePlanificados ] = useState([]);
    const [ datosTableRealizados, saveDatosTableRealizados ] = useState([]);
    const [ datosTableLibreria, saveDatosTableLibreria ] = useState([]);
    const [ datosTableAgentesAcordeon, saveDatosTableAgentesAcordeon ] = useState();
    const [ datosTableAgentes, saveDatosTableAgentes ] = useState([]);
    const [ rowDataState, saveRowDataState ] = useState(null);
    const [ centroModal, saveCentroModal ] = useState("");

    const [ showModal, saveShowModal ] = useState(false);
    const [ showModalCentros, saveShowModalCentros ] = useState(false);


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.checklist.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);                                                                                                                           
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                                     
                    saveLoadedConf(true);  
                    if( cookies.get('defaultActiveKeyCheckList') ){
                        toogleAcordeon(cookies.get('defaultActiveKeyCheckList'), "acordeonPrin")
                    }      
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          
        
        return () =>{
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
                source2.cancel();
        } 

    },[confPantalla])

    const toogleAcordeon = (id, acordeon) => {
        switch(acordeon){        
            case "acordeonPrin":
                if( acordeonPrin == id ){
                    saveAcordeonPrin(null);
                    cookies.set('defaultActiveKeyCheckList', null, {path: '/'});
                }else{
                    saveAcordeonPrin(id);
                    cookies.set('defaultActiveKeyCheckList', id, {path: '/'});
                }                            
            break;           
        }
    }

    const handleSelectCentroModal = (selectedItem, nameOfComponent) => {
        if( selectedItem ){
            saveCentroModal(selectedItem.value)  
        }else{
            saveCentroModal("")
        }
    }


    // Ejecutar
    const ejecutar = (rowData, idTabla) => {
        if(idTabla == "tablaLibreria"){
            // saveCentroModal("");
            saveCentroModal(centroActivo);
            saveRowDataState(rowData);
            saveShowModalCentros(true);
        }else{
            swal({
                title: confPantalla.checklist.controles.swal.ejecutar.titulo.title,
                text: confPantalla.checklist.controles.swal.ejecutar.texto.title,
                icon: "warning",
                buttons: {
                    cancel : {text: confPantalla.checklist.controles.swal.ejecutar.cancelar.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                    confirm : {text: confPantalla.checklist.controles.swal.ejecutar.confirmar.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}                
                } 
            }).then(confirmacion => {
                if( confirmacion == true ){
                    ejecutarDoc(rowData);            
                }
            })
        }
    }

    const ejecutarDoc = async(rowData, fromModalCentros=false) => {
        saveLoading(true);       
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("id", rowData.id_poe);
        data.append("id_centro", fromModalCentros ? centroModal : rowData.id_centro);
        data.append("id_linea", rowData.id);
              
        const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.checklist.controles.planificados.createdocplantrapr, data);

        if(typeof respuesta.data == "string"){
            // Error
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
           // Lanzamos el wizardchecklist
           // propiedades.history.push("/checklistwiz/"+respuesta.data);
           window.open("/checklistwiz/"+respuesta.data);       
        }
        saveShowModalCentros(false);
        saveLoading(false);
    }

    // Ocultar
    const ocultar = async (rowData, ocultarParam) => {
        const aux = confPantalla.checklist.controles.swal;

        let titleSwal  = ocultarParam == true ? aux.ocultar.titulo.title    : aux.mostrar.titulo.title;
        let text       = ocultarParam == true ? aux.ocultar.texto.title     : aux.mostrar.texto.title;
        let btnconfirm = ocultarParam == true ? aux.ocultar.confirmar.title : aux.mostrar.confirmar.title;
        let btncancel  = ocultarParam == true ? aux.ocultar.cancelar.title  : aux.mostrar.cancelar.title;
        
        swal({
            title: titleSwal,
            text: text,
            icon: "warning",
            buttons: {
                cancel : {text: btncancel, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                confirm : {text: btnconfirm, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}                
            } 
        }).then(confirmacion => {
            if( confirmacion == true ){
                ocultarDoc(rowData, ocultarParam);
            }
        })
       
    }

    const ocultarDoc = async(rowData, ocultar) => {
        saveLoading(true);
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("ocultar", ocultar);
        data.append("id_linea", rowData.id);
              
        const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.checklist.controles.planificados.accionocultar, data);
        if(typeof respuesta.data == "string"){
            // Error
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
           // Reload kpi
           saveReloadKpi(reloadKpi => reloadKpi+1)
        }
        saveLoading(false);
    }

    // Agentes Modal
    const getAgentes = async (rowData) => {
        saveShowModal(true);
        saveLoadingAgentes(true);
        saveDatosTableAgentes([]);
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("id_linea", rowData.id);
        data.append("id_roe", rowData.id_roe);
              
        const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.checklist.controles.planificados.modal.getagentesmodal, data);
        if(typeof respuesta.data == "string"){
            // Error
            saveShowModal(false);
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
           saveDatosTableAgentes(respuesta.data);       
        }
    }

    return(
    <div className="App wrapper">
        <Alert fondo={true} />    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header"></div>
                <section className="content">
                {
                    loadedConf === true && confPantalla !== undefined
                    ?
                        <div>
                            <Kpi
                                conf={ confPantalla.checklist.controles.kpi }
                                getDataTable1 = { confPantalla.checklist.controles.planificados.getplanificados }
                                saveDataTable1 = { saveDatosTablePlanificados }
                                saveCalendar1 = { null }
                                getDataTable2 = { confPantalla.checklist.controles.libreria.getlibreria }
                                saveDataTable2 = { saveDatosTableLibreria }
                                saveCalendar2 = { null }
                                getDataTable3 = { confPantalla.checklist.controles.realizados.getrealizados }
                                saveDataTable3 = { saveDatosTableRealizados }
                                getDataTable4 = { confPantalla.checklist.controles.agentes.getagentes }
                                saveDataTable4 = { saveDatosTableAgentesAcordeon }
                                saveCalendar4 = { null }
                                saveLoadingTable = { saveLoading }
                                forceReload={ reloadKpi }
                            />                                        
                            <Accordion defaultActiveKey={ cookies.get('defaultActiveKeyCheckList') ? cookies.get('defaultActiveKeyCheckList') : ""  }  >
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0', "acordeonPrin")} className={ acordeonPrin == "0" ? "activeAcordeon" : "" }>
                                        <i className={ confPantalla.checklist.controles.planificados.section.icono } ></i> { confPantalla.checklist.controles.planificados.section.titulo }
                                        {
                                            acordeonPrin == "0"
                                            ?
                                                <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                            :
                                                <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                        }
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0" className={"slowAcordeon"} >
                                        <Card.Body>
                                            <MiDataTableCheckList
                                                estadoForm= { "" }
                                                formulario={ {id:0} }
                                                id={"tablaPlanificados"}
                                                columnas={confPantalla.checklist.controles.planificados.tabla.columns}
                                                data={datosTablePlanificados}
                                                linkColumns={confPantalla.checklist.controles.planificados.tabla.linkColumns}
                                                buttons={confPantalla.checklist.controles.planificados.tabla.buttons}                                                        
                                                ordering={confPantalla.checklist.controles.planificados.tabla.ordering}
                                                order={confPantalla.checklist.controles.planificados.tabla.order}
                                                searching={confPantalla.checklist.controles.planificados.tabla.searching}
                                                paging={confPantalla.checklist.controles.planificados.tabla.paging}
                                                select={confPantalla.checklist.controles.planificados.tabla.select}
                                                pagelength={confPantalla.checklist.controles.planificados.tabla.pagelength}                                                           
                                                columnactions={confPantalla.checklist.controles.planificados.tabla.columnactions.title}
                                                traduction={confPantalla.config.trad.components.datatable} 
                                                loadingTable={loading}
                                                saveLoading={saveLoading}
                                                confPantalla={confPantalla}
                                                propiedades={props}
                                                getAgentes={getAgentes}
                                                ejecutar={ejecutar}
                                                ocultar={ocultar}
                                            />                                                    
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => toogleAcordeon('1', "acordeonPrin")} className={ acordeonPrin == "1" ? "activeAcordeon" : "" }>
                                        <i className={ confPantalla.checklist.controles.realizados.section.icono } ></i> { confPantalla.checklist.controles.realizados.section.titulo }
                                        {
                                            acordeonPrin == "1"
                                            ?
                                                <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                            :
                                                <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                        }
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1" className={"slowAcordeon"} >
                                        <Card.Body>
                                            <MiDataTableCheckList
                                                estadoForm= { "" }
                                                formulario={ {id:0} }
                                                id={"tablaRealizados"}
                                                columnas={confPantalla.checklist.controles.realizados.tabla.columns}
                                                data={datosTableRealizados}
                                                linkColumns={confPantalla.checklist.controles.realizados.tabla.linkColumns}
                                                buttons={confPantalla.checklist.controles.realizados.tabla.buttons}                                                        
                                                ordering={confPantalla.checklist.controles.realizados.tabla.ordering}
                                                order={confPantalla.checklist.controles.realizados.tabla.order}
                                                searching={confPantalla.checklist.controles.realizados.tabla.searching}
                                                paging={confPantalla.checklist.controles.realizados.tabla.paging}
                                                select={confPantalla.checklist.controles.realizados.tabla.select}
                                                pagelength={confPantalla.checklist.controles.realizados.tabla.pagelength}                                                           
                                                columnactions={confPantalla.checklist.controles.realizados.tabla.columnactions.title}
                                                traduction={confPantalla.config.trad.components.datatable} 
                                                loadingTable={loading}
                                                saveLoading={saveLoading}
                                                confPantalla={confPantalla}
                                                propiedades={props}
                                                getAgentes={getAgentes}
                                                ejecutar={ejecutar}
                                                ocultar={ocultar}
                                            />                                                    
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="2" onClick={() => toogleAcordeon('2', "acordeonPrin")} className={ acordeonPrin == "2" ? "activeAcordeon" : "" }>
                                        <i className={ confPantalla.checklist.controles.libreria.section.icono } ></i> { confPantalla.checklist.controles.libreria.section.titulo }
                                        {
                                            acordeonPrin == "2"
                                            ?
                                                <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                            :
                                                <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                        }
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2" className={"slowAcordeon"} >
                                        <Card.Body>                                                    
                                            <MiDataTableCheckList
                                                estadoForm= { "" }
                                                formulario={ {id:0} }
                                                id={"tablaLibreria"}
                                                columnas={confPantalla.checklist.controles.libreria.tabla.columns}
                                                data={datosTableLibreria}
                                                linkColumns={confPantalla.checklist.controles.libreria.tabla.linkColumns}
                                                buttons={confPantalla.checklist.controles.libreria.tabla.buttons}
                                                ordering={confPantalla.checklist.controles.libreria.tabla.ordering}
                                                order={confPantalla.checklist.controles.libreria.tabla.order}
                                                searching={confPantalla.checklist.controles.libreria.tabla.searching}
                                                paging={confPantalla.checklist.controles.libreria.tabla.paging}
                                                select={confPantalla.checklist.controles.libreria.tabla.select}
                                                pagelength={confPantalla.checklist.controles.libreria.tabla.pagelength}                                                           
                                                columnactions={confPantalla.checklist.controles.libreria.tabla.columnactions.title}
                                                traduction={confPantalla.config.trad.components.datatable}
                                                adjuntosplegados={confPantalla.checklist.controles.libreria.tabla.adjuntosplegados } 
                                                loadingTable={loading}
                                                saveLoading={saveLoading}
                                                confPantalla={confPantalla}
                                                propiedades={props}
                                                getAgentes={getAgentes}
                                                ejecutar={ejecutar}
                                                ocultar={ocultar}
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="3" onClick={() => toogleAcordeon('3', "acordeonPrin")} className={ acordeonPrin == "3" ? "activeAcordeon" : "" }>
                                        <i className={ confPantalla.checklist.controles.agentes.section.icono } ></i> { confPantalla.checklist.controles.agentes.section.titulo }
                                        {
                                            acordeonPrin == "3"
                                            ?
                                                <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                            :
                                                <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                        }
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3" className={"slowAcordeon"} >
                                        <Card.Body>
                                            <MiDataTableCheckList
                                                estadoForm= { "" }
                                                formulario={ {id:0} }
                                                id={"tablaAgentes"}
                                                columnas={confPantalla.checklist.controles.agentes.tabla.columns}
                                                data={datosTableAgentesAcordeon}
                                                linkColumns={confPantalla.checklist.controles.agentes.tabla.linkColumns}
                                                buttons={confPantalla.checklist.controles.agentes.tabla.buttons}                                                        
                                                ordering={confPantalla.checklist.controles.agentes.tabla.ordering}
                                                order={confPantalla.checklist.controles.agentes.tabla.order}
                                                searching={confPantalla.checklist.controles.agentes.tabla.searching}
                                                paging={confPantalla.checklist.controles.agentes.tabla.paging}
                                                select={confPantalla.checklist.controles.agentes.tabla.select}
                                                pagelength={confPantalla.checklist.controles.agentes.tabla.pagelength}                                                           
                                                columnactions={confPantalla.checklist.controles.agentes.tabla.columnactions.title}
                                                traduction={confPantalla.config.trad.components.datatable} 
                                                loadingTable={loading}
                                                saveLoading={saveLoading}
                                                confPantalla={confPantalla}
                                                propiedades={props}                                                
                                            />                                                    
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>                       
                        </div>                             
                    :
                        <LoadingPage />
                }
                </section>
            {
            showModal
            ?
                <Modal
                    show={showModal}
                    onHide={ () => saveShowModal(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}                    
                    dialogClassName="modal-90w"                                             
                > 
                        <Modal.Header closeButton>
                            {
                                datosTableAgentes.length > 0
                                ?
                                    <h4>{confPantalla.checklist.controles.planificados.modal.header.title} {datosTableAgentes[0]["tot_fir"]}/{datosTableAgentes[0]["tot_pre"]}</h4>
                                :
                                    <h4>{confPantalla.checklist.controles.planificados.modal.header.title}</h4>

                            }
                        </Modal.Header>
                        <Modal.Body>
                            <MiDataTable 
                                estadoForm= { "" }
                                formulario={ {id:0} }
                                id={"tablaAgentesModal"}
                                columnas={confPantalla.checklist.controles.planificados.modal.tabla.columns}
                                data={datosTableAgentes}
                                linkColumns={confPantalla.checklist.controles.planificados.modal.tabla.linkColumns}
                                buttons={confPantalla.checklist.controles.planificados.modal.tabla.buttons}
                                ordering={confPantalla.checklist.controles.planificados.modal.tabla.ordering}
                                order={confPantalla.checklist.controles.planificados.modal.tabla.order}
                                searching={confPantalla.checklist.controles.planificados.modal.tabla.searching}
                                paging={confPantalla.checklist.controles.planificados.modal.tabla.paging}
                                select={confPantalla.checklist.controles.planificados.modal.tabla.select}
                                pagelength={confPantalla.checklist.controles.planificados.modal.tabla.pagelength}
                                saveLoadingTable={ saveLoadingAgentes }
                                loadingTable={loading}
                                columnactions={confPantalla.checklist.controles.planificados.modal.tabla.columnactions.title}
                                traduction={confPantalla.config.trad.components.datatable}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='text-right'>
                                <button 
                                        className="btn btn-danger mt-1"
                                        onClick={ () => saveShowModal(false) }                                        
                                >
                                    {confPantalla.checklist.controles.planificados.modal.btncerrar.title}
                                </button>
                            </div>
                        </Modal.Footer>
                </Modal>
            :
                null
            }
            {
            showModalCentros
            ?
                <Modal
                    show={showModalCentros}
                    onHide={ () => saveShowModalCentros(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}                    
                    dialogClassName="modal-70w"                                             
                > 
                        <Modal.Header closeButton>
                            <h4>{confPantalla.checklist.controles.libreria.modal.header.title}</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <MiSelect  
                                estadoForm=  { "" }
                                val=         { centroModal  }   
                                loading=     { false }
                                id=          { confPantalla.checklist.controles.libreria.modal.idcen.id }    
                                name=        { confPantalla.checklist.controles.libreria.modal.idcen.name }   
                                label=       { confPantalla.checklist.controles.libreria.modal.idcen.label }    
                                placeholder= { confPantalla.checklist.controles.libreria.modal.idcen.placeholder }
                                col=         { confPantalla.checklist.controles.libreria.modal.idcen.col } 
                                isClearable= { confPantalla.checklist.controles.libreria.modal.idcen.isclearable }
                                isSearchable={ confPantalla.checklist.controles.libreria.modal.idcen.issearchable }
                                dataType=    { confPantalla.checklist.controles.libreria.modal.idcen.datatype }
                                data=        { confPantalla.checklist.controles.libreria.modal.idcen.data }
                                required=    { confPantalla.checklist.controles.libreria.modal.idcen.required } 
                                disabled=  { confPantalla.checklist.controles.libreria.modal.idcen.disabled }
                                readOnly =   { confPantalla.checklist.controles.libreria.modal.idcen.readonly }
                                disablethisid={confPantalla.checklist.controles.libreria.modal.idcen.disablethisid}
                                disableids={confPantalla.checklist.controles.libreria.modal.idcen.disableids}
                                formulario= { {id:0} }   
                                change= { handleSelectCentroModal }
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='text-right'>
                                <Button 
                                    clases={ " rounded" }
                                    color={ "success" }  
                                    type="button" 
                                    icono={ "" }   
                                    id="btnaceptarmodal"     
                                    texto={ confPantalla.checklist.controles.libreria.modal.btnaceptar.title }   
                                    accion={ () => ejecutarDoc(rowDataState, true) } 
                                    disabled={ centroModal == "" ? true : false }
                                />                                
                            </div>
                            <div className='text-right'>
                                <button 
                                        className="btn btn-danger "
                                        onClick={ () => saveShowModalCentros(false) }                                        
                                >
                                    {confPantalla.checklist.controles.libreria.modal.btncerrar.title}
                                </button>
                            </div>
                        </Modal.Footer>
                </Modal>
            :
                null
            }              
        </div>
        <Footer />          
    </div>
    );
}

export default CheckList