import React, { useEffect, useContext, useState } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net';
import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
// import MiDataTableplanificador from './MiDataTableplanificador';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Kpi from '../../componentes/ui/Kpi';
import Button from '../../componentes/ui/Button';
import { Spinner } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import MiSelect from '../../componentes/formulario/MiSelect';
import FormInput from '../../componentes/formulario/FormInput';
import FormRow from '../../componentes/formulario/FormRow';
import MiDataTablePlanificador from './MiDataTablePlanificador';
import Gant from '../../componentes/ui/Gant';


import { alertService } from '../../services/alertservice';
import { Alert } from '../../componentes/ui/Alert';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, axiosPost, getPantDocFromNivel, getAhorayyyymmdd, MiReplaceAll, getTitle } from '../../helpers/helperApp';

import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';


$.DataTable = DataTable;

let reordenandoLineas = false;

function Planificador(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey, centroActivo } = useContext(LoginContext);   

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ tablePlan, saveTablePlan ] = useState([]);
    const [ docPlan, saveDocPlan ] = useState(null);
    const [ loadingDocPlan, saveLoadingDocPlan ] = useState(false);
    const [ showModal, saveShowModal ] = useState(false);
    const [ centro, saveCentro ] = useState(centroActivo);
    const [ agente, saveAgente ] = useState("");
    const [ fecIni, saveFecIni ] = useState(getAhorayyyymmdd());
    const [ fecPrev, saveFecPrev ] = useState(getAhorayyyymmdd());
    const [ descripcion, saveDescripcion ] = useState("");
    const [ enviandoDocumento, saveEnviandoDocumento ] = useState(false);
    const [ reloadKpi, saveReloadKpi ] = useState(0);
    const [ optionsUbi, saveOptionsUbi ] = useState([]);
    const [ optionsAge, saveOptionsAge ] = useState([]);
    const [ optionsEst, saveOptionsEst ] = useState([]);
    const [ selectDocData, saveSelectDocData ] = useState("");
    const [ docPlanActivo, saveDocPlanActivo ] = useState("");
    const [ docuVacio, saveDocuVacio ] = useState(false);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.planificador.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);                                                                                                        
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
                source2.cancel();
                source3.cancel();
        } 

    },[confPantalla, formularioHijo.id, centroActivo])



    const callBackKpis = async() => {

        // Combo ubicaciones para la tabla de edicion
        // const ubi = confPantalla.planificador.controles.datatableedi.controles.idubi;
        // await loadOptionsCbo(ubi.datatype,ubi.data, optionsUbi, saveOptionsUbi);

        // Combo agentes para la tabla de edicion
        const age = confPantalla.planificador.controles.datatableedi.controles.idage;
        await loadOptionsCbo(age.datatype,age.data, optionsAge, saveOptionsAge);

        // Combo estados para la tabla de edicion
        const est = confPantalla.planificador.controles.datatableedi.controles.idest;
        await loadOptionsCbo(est.datatype,est.data, optionsEst, saveOptionsEst);

        // Obtenemos la plantilla de planificación en curso
        // await getDocPlanEnCurso();

        // Cargamos los datos del select selector de plantillas de planificador
        await getDataSelect();
    }


    const loadOptionsCbo = async (fuente, opcion, options, saveOptions) =>{
        const data = new FormData();
        data.append("validacion", apiKey());
        if( opcion.includes("usu_actual") ){
            opcion = MiReplaceAll(opcion, "usu_actual", cookies.get('id'));
        }
        if( opcion.includes("nivel_actual") ){
            opcion = MiReplaceAll(opcion, "nivel_actual", cookies.get('nivel'));
        }
        data.append(fuente, opcion);                    

        if( options.length == 0 ){
            let url = null;
            if( fuente == "filter" ){
                url = getApiUrl(window.location.origin)+'/filters/getFilter';
            }else{
                url = getApiUrl(window.location.origin)+'/app/getDataCbo';
            }
            const respuesta = await axios.post(url, data);
            saveOptions(respuesta.data)
        }
    }



    const handleSelectDocActivo = async (selectedItem, nameOfComponent) => {
        saveDocPlan(null);
        if( selectedItem ){
            saveDocPlanActivo(selectedItem.value) 
            await getDocPlanEnCurso(selectedItem.value);
        }else{
            saveDocPlanActivo("");
            saveDocPlan(null);
        }
    }


    const getDataSelect = async() => {

        saveLoadingDocPlan(true);
        
        const data = new FormData();      
        data.append("validacion", apiKey());
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.planificador.controles.getdataselect, data, source.token);

        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
            saveSelectDocData(respuesta.data)
        }        

        saveLoadingDocPlan(false);

    }



    const getDocPlanEnCurso = async(idPlan) => {    

        saveLoadingDocPlan(true);
        
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("idplan", idPlan);
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+"/supply/getDocArprov",data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.planificador.controles.getdatadocplan, data, source.token);

        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else if( respuesta.data.length == 0 ){
            // No existe documento planificador en curso
            saveDocPlan(null);
            saveDocuVacio(true);
        }else if( respuesta.data.length ){
            // Existe documento planificador en curso
            saveDocPlan(respuesta.data);
            saveDocPlanActivo(idPlan)
            saveDocuVacio(false);                      
        }
        saveLoadingDocPlan(false);

    }

    const planificar = async() => {

        let table = $("#planificador").DataTable();
        const rows = table.rows( { selected: true } ).data();

        if(rows.length > 0){            
            // if( !docPlan ){ 
            if(docPlanActivo == "" || docPlanActivo == "undefined" || !docPlanActivo){          
                // No existe documento de planificación
                saveCentro(centroActivo == "0" ? "" : centroActivo)
                saveAgente("");
                saveFecPrev(getAhorayyyymmdd());
                saveFecIni(getAhorayyyymmdd());
                saveDescripcion("");
                saveShowModal(true)
            }else{
                // Si que existe el documento
                enviarDocumentos(false);                
            }
        }else if(rows.length == 0){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.planificador.controles.noselected.title}`, {autoClose: true}); 
        }
    }

    const enviarDocumentos = async(crear=true) =>{

        saveShowModal(false);
        saveEnviandoDocumento(true);

        let idsSeleccionados = [];

        // Guardamos los datos de las plantillas seleccionadas en la tabla
        let table = $("#planificador").DataTable();
        const rows = table.rows( { selected: true } ).data();
        rows.map( row => {                
            idsSeleccionados.push(row.id);
        })

        // Llamada
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("ids", JSON.stringify(idsSeleccionados));
        if(crear){
            // No existe el documento de planificación
            data.append("accion", "crear");
            data.append("centro", centro);
            data.append("agente", agente);
            data.append("fecIni", fecIni);
            data.append("fecPrev", fecPrev);
            data.append("descripcion", descripcion);
        }else{
            // Existe el documento de planificación
            data.append("accion", "modificar");
            // data.append("idPlanCurso", docPlan[0].id);
            data.append("idPlanCurso", docPlanActivo);
        }

        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.planificador.controles.actionsdoc, data, source2.token);
        
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
            await getDocPlanEnCurso(respuesta.data[0]); 
            saveReloadKpi(reloadKpi => reloadKpi+1)           
            alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.planificador.controles.success.title}`, {autoClose: true});
        }

        saveEnviandoDocumento(false);

    }


    const handleSelectAgente = (selectedItem, nameOfComponent) => {
        if( selectedItem ){
            saveAgente(selectedItem.value) 
        }else{
            saveAgente("")
        }
    }

    const handleChangeCentro = (selectedItem, nameOfComponent) => {
        if( selectedItem ){
            saveCentro(selectedItem.value) 
        }else{
            saveCentro("")
        }
    }

    const handleInput = (e, control) =>{
        switch(control){
            case 0:
                saveFecIni(e.target.value);
                break;
            case 1:
                saveFecPrev(e.target.value);
                break;
            case 2:
                saveDescripcion(e.target.value);
                break;            
        }
    }

    const reorderRowsPlan = async (e, diff, edit) => {        
        
        if( !reordenandoLineas ){
            reordenandoLineas = true;
            let datos = [];

            diff.map(d=> {        
                let dataAux = { id: d.node.id.split("#row-")[1], old: d.oldPosition+1, new: d.newPosition+1};         
                datos.push(dataAux);
            })
            const data = new FormData();    
            data.append("validacion", apiKey());
            data.append("datos", JSON.stringify(datos));
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.planificador.controles.datatableedi.datareorder, data, source3.token);
            if( typeof respuesta.data == "string"){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.planificador.controles.datatableedi.reordererror.title}`, {autoClose: true}); 
            }else{                
                await getDocPlanEnCurso(docPlanActivo); 
                // saveReloadKpi(reloadKpi => reloadKpi+1)           
                alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.planificador.controles.datatableedi.reordersuccess.title}`, {autoClose: true});
            }
            reordenandoLineas = false;
        }
    }

    const getDataSelectFromDocTab = (data) => {
        let newData = [];
        for(let i=0;i<data.length;i++){
            const aux = {
                value : data[i].value,
                label : data[i].label
            };
            newData.push(aux);
        }

        // Eliminamos los duplicados
        var hash = {};
        newData = newData.filter(function(current) {
            var exists = !hash[current.value];
            hash[current.value] = true;
            return exists;
        });

        return newData;
    }
    

    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Alert fondo={true} />
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">                
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                            <Section titulo={ confPantalla.planificador.section.titulo } icono={ confPantalla.planificador.section.icono } col={ confPantalla.planificador.section.col } >

                                <Kpi
                                    conf={ confPantalla.planificador.controles.kpi }
                                    getDataTable1 = { confPantalla.planificador.controles.datatable.getdata }
                                    saveDataTable1 = { saveTablePlan }                           
                                    saveLoadingTable = { saveLoadingTable }                                    
                                    callBackLoaded={ callBackKpis }
                                    initLoading={ null }
                                    forceReload={ reloadKpi }

                                />                            
                                <MiDataTable 
                                    estadoForm= { "" }
                                    formulario={ {id:0} }
                                    id={"planificador"}
                                    columnas={confPantalla.planificador.controles.datatable.columns}
                                    data={tablePlan}
                                    buttons={confPantalla.planificador.controles.datatable.buttons}
                                    ordering={confPantalla.planificador.controles.datatable.ordering}
                                    order={confPantalla.planificador.controles.datatable.order}
                                    searching={confPantalla.planificador.controles.datatable.searching}
                                    paging={confPantalla.planificador.controles.datatable.paging}
                                    select={confPantalla.planificador.controles.datatable.select}
                                    pagelength={confPantalla.planificador.controles.datatable.pagelength}
                                    // saveLoadingTable={ saveLoadingTable }
                                    loadingTable={loadingTable}
                                    columnactions={confPantalla.planificador.controles.datatable.columnactions.title}
                                    linkColumns={confPantalla.planificador.controles.datatable.linkColumns}
                                    traduction={confPantalla.config.trad.components.datatable}                                        
                                />
                                {
                                    tablePlan.length > 0
                                    ?
                                        <Fragment>
                                            <Button 
                                                clases={ "col-12 mt-2 mb-4" }
                                                color={ "success" }  
                                                type="button" 
                                                icono={ "" }   
                                                id="btncalcplan"     
                                                texto={ confPantalla.planificador.controles.btncalcular.title }   
                                                accion={ planificar } 
                                                disabled={ false }
                                            />                                                                        
                                        </Fragment>
                                    :
                                        null
                                }
                                
                                <div className="mt-3">                                                                                     
                                            <div className={docPlanActivo == "" ? 'container-fluid containerSelectCrear mb-2' : 'container-fluid containerSelectEditar mb-2' }>
                                                {
                                                    docPlanActivo == ""
                                                    ?
                                                        <h4 className='pl-3'>
                                                            {confPantalla.planificador.controles.modo.crear.title}
                                                        </h4>
                                                    :
                                                        <NavLink 
                                                            to={getPantDocFromNivel(cookies.get('nivel'))+"/"+docPlanActivo}
                                                            className={"linkColorWarning"}
                                                        >
                                                            <h4 className='ml-3 pl-3 pr-3'>
                                                                {confPantalla.planificador.controles.modo.editar.title}
                                                                <i className="ml-2 fal fa-link"></i>
                                                            </h4>
                                                        </NavLink>
                                                }
                                                <MiSelect  
                                                    estadoForm=  { "" }
                                                    val=         { docPlanActivo }   
                                                    loading=     { loadReg ? true : false }
                                                    id=          { "" }    
                                                    name=        { "" }   
                                                    label=       { "" }    
                                                    placeholder= { "" }
                                                    col=         { "12" } 
                                                    isClearable= { true }
                                                    isSearchable={ true }
                                                    dataType=    { "codigo" }
                                                    data=        { getDataSelectFromDocTab(selectDocData) }
                                                    required=    { false } 
                                                    disabled=  { false }
                                                    readOnly =   { false }
                                                    disablethisid={false}
                                                    disableids={false}
                                                    formulario= { {id:0} }   
                                                    change= { handleSelectDocActivo }
                                                    selectPlanificador={true}
                                                />
                                            </div>
                                        {   enviandoDocumento || loadingDocPlan ? 
                                                <div className='mt-4' style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                                    <Spinner animation="border" />
                                                </div>
                                            :
                                            null
                                        }
                                        {
                                            docPlanActivo == ""
                                            ?
                                                <div className='text-center mt-4 mb-4'>
                                                    {/* <i className="fal fa-empty-set fa-6x" style={{color: "#dc3545"}}></i> */}
                                                    <h4 style={{color: "#dc3545"}}>{confPantalla.planificador.controles.nodocument.title}</h4>                                                                
                                                </div>
                                            :
                                            docPlan == null && docuVacio
                                            ?
                                                <div className='text-center mt-4 mb-4'>
                                                    <i className="fal fa-empty-set fa-6x" style={{color: "#dc3545"}}></i>
                                                </div>
                                            :
                                            docPlan == null
                                            ?
                                                null
                                            :
                                                <Fragment>
                                                    {/* <NavLink to={getPantDocFromNivel(cookies.get('nivel'))+"/"+docPlan[0]["id"]}>
                                                        <hr data-content={docPlan[0]["descripcioncab"]+" - "+docPlan[0]["fecprevision"]+" - "+docPlan[0]["estado"]  }
                                                            className="hr-titleinfwhite">                                                                    
                                                        </hr>
                                                    </NavLink> */}
                                                    <Tabs defaultActiveKey="tab1" id="tab1">
                                                        <Tab eventKey="tab1" title={ confPantalla.planificador.tabs.tab1.titulo }>                                                            
                                                            <MiDataTable 
                                                                estadoForm= { "" }
                                                                formulario={ {id:0} }
                                                                id={"docPlan"}
                                                                columnas={confPantalla.planificador.controles.datatableplan.columns}
                                                                data={docPlan}
                                                                buttons={confPantalla.planificador.controles.datatableplan.buttons}
                                                                ordering={confPantalla.planificador.controles.datatableplan.ordering}
                                                                order={confPantalla.planificador.controles.datatableplan.order}
                                                                searching={confPantalla.planificador.controles.datatableplan.searching}
                                                                paging={confPantalla.planificador.controles.datatableplan.paging}
                                                                select={confPantalla.planificador.controles.datatableplan.select}
                                                                pagelength={confPantalla.planificador.controles.datatableplan.pagelength}
                                                                // saveLoadingTable={ saveLoadingCalculate }
                                                                // loadingTable={loadingCalculate}
                                                                columnactions={confPantalla.planificador.controles.datatableplan.columnactions.title}
                                                                linkColumns={confPantalla.planificador.controles.datatableplan.linkColumns}
                                                                traduction={confPantalla.config.trad.components.datatable}                                                               
                                                            />
                                                        </Tab>
                                                        <Tab eventKey="tab2" title={ confPantalla.planificador.tabs.tab2.titulo }>
                                                            <MiDataTablePlanificador 
                                                                estadoForm= { "" }
                                                                formulario={ {id:docPlanActivo} }
                                                                id={"tableedi"}
                                                                columnas={confPantalla.planificador.controles.datatableedi.columns}
                                                                data={docPlan}
                                                                saveData={saveDocPlan}
                                                                buttons={confPantalla.planificador.controles.datatableedi.buttons}
                                                                ordering={confPantalla.planificador.controles.datatableedi.ordering}
                                                                order={confPantalla.planificador.controles.datatableedi.order}
                                                                searching={confPantalla.planificador.controles.datatableedi.searching}
                                                                paging={confPantalla.planificador.controles.datatableedi.paging}
                                                                select={confPantalla.planificador.controles.datatableedi.select}
                                                                pagelength={confPantalla.planificador.controles.datatableedi.pagelength}                                                                
                                                                columnactions={confPantalla.planificador.controles.datatableedi.columnactions.title}
                                                                linkColumns={confPantalla.planificador.controles.datatableedi.linkColumns}
                                                                traduction={confPantalla.config.trad.components.datatable}
                                                                selectubi={confPantalla.planificador.controles.datatableedi.controles.idubi}   
                                                                selectage={confPantalla.planificador.controles.datatableedi.controles.idage}
                                                                selectest={confPantalla.planificador.controles.datatableedi.controles.idest}
                                                                fecini={confPantalla.planificador.controles.datatableedi.controles.fec1}   
                                                                fecprev={confPantalla.planificador.controles.datatableedi.controles.fec2}
                                                                optionselectubi={optionsUbi}
                                                                optionselectage={optionsAge}
                                                                optionselectest={optionsEst}
                                                                getDocPlanEnCurso={getDocPlanEnCurso}
                                                                rowreorder={confPantalla.planificador.controles.datatableedi.rowreorder}
                                                                functionAfterReorder={reorderRowsPlan}
                                                                confPantalla={confPantalla}
                                                                saveLoading={saveLoadingDocPlan}                                                                
                                                            />                                                            
                                                        </Tab>
                                                        <Tab eventKey="tab3" title={ confPantalla.planificador.tabs.tab3.titulo }>
                                                            {
                                                                docPlanActivo != ""
                                                                ?
                                                                <Gant 
                                                                    confPantalla={confPantalla}
                                                                    idPrin={docPlanActivo}
                                                                    idHijo={null}
                                                                    formulario={{id:docPlanActivo}} 
                                                                    insideSection={false}
                                                                />
                                                                :
                                                                    null
                                                            }
                                                        </Tab>
                                                    </Tabs>
                                                    
                                                </Fragment>
                                            }                                          
                                </div>
                                <Modal
                                    show={showModal}
                                    onHide={ () => saveShowModal(false)}                            
                                    aria-labelledby="example-custom-modal-styling-title"
                                    centered={true}                    
                                    dialogClassName="modal-90w"                                             
                                > 
                                    <Modal.Header closeButton>
                                        <h4>{confPantalla.planificador.controles.modal.header.title}</h4>
                                    </Modal.Header>                         
                                    <Modal.Body>
                                        <FormRow>
                                            <MiSelect  
                                                estadoForm=  { "" }
                                                val=         { centroActivo != "0" && centroActivo != "" ? centroActivo : centro }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.planificador.controles.modal.controles.idcen.id }    
                                                name=        { confPantalla.planificador.controles.modal.controles.idcen.name }   
                                                label=       { confPantalla.planificador.controles.modal.controles.idcen.label }    
                                                placeholder= { confPantalla.planificador.controles.modal.controles.idcen.placeholder }
                                                col=         { confPantalla.planificador.controles.modal.controles.idcen.col } 
                                                isClearable= { confPantalla.planificador.controles.modal.controles.idcen.isclearable }
                                                isSearchable={ confPantalla.planificador.controles.modal.controles.idcen.issearchable }
                                                dataType=    { confPantalla.planificador.controles.modal.controles.idcen.datatype }
                                                data=        { confPantalla.planificador.controles.modal.controles.idcen.data }
                                                required=    { confPantalla.planificador.controles.modal.controles.idcen.required } 
                                                disabled=    { confPantalla.planificador.controles.modal.controles.idcen.disabled }
                                                readOnly =   { centroActivo != "0" && centroActivo != ""  ? "readonly" : confPantalla.planificador.controles.modal.controles.idcen.readonly }
                                                disablethisid={false}
                                                formulario= { {id:0} }   
                                                change= { handleChangeCentro }
                                            />                                                
                                        {/* </FormRow>
                                        <FormRow> */}
                                            <MiSelect  
                                                estadoForm=  { "" }
                                                val=         { agente  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.planificador.controles.modal.controles.idage.id }    
                                                name=        { confPantalla.planificador.controles.modal.controles.idage.name }   
                                                label=       { confPantalla.planificador.controles.modal.controles.idage.label }    
                                                placeholder= { confPantalla.planificador.controles.modal.controles.idage.placeholder }
                                                col=         { confPantalla.planificador.controles.modal.controles.idage.col } 
                                                isClearable= { confPantalla.planificador.controles.modal.controles.idage.isclearable }
                                                isSearchable={ confPantalla.planificador.controles.modal.controles.idage.issearchable }
                                                dataType=    { confPantalla.planificador.controles.modal.controles.idage.datatype }
                                                data=        { confPantalla.planificador.controles.modal.controles.idage.data }
                                                required=    { confPantalla.planificador.controles.modal.controles.idage.required } 
                                                disabled=  { confPantalla.planificador.controles.modal.controles.idage.disabled }
                                                readOnly =   { confPantalla.planificador.controles.modal.controles.idage.readonly }
                                                disablethisid={confPantalla.planificador.controles.modal.controles.idage.disablethisid}
                                                disableids={confPantalla.planificador.controles.modal.controles.idage.disableids}
                                                formulario= { {id:0} }   
                                                change= { handleSelectAgente }
                                            />
                                            <FormInput                                                        
                                                estadoForm= { "" }
                                                value=      { fecIni }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.planificador.controles.modal.controles.fec1.id }   
                                                type=       { confPantalla.planificador.controles.modal.controles.fec1.type }  
                                                name=       { confPantalla.planificador.controles.modal.controles.fec1.name }   
                                                label=      { confPantalla.planificador.controles.modal.controles.fec1.label }                                                       
                                                placeholder={ confPantalla.planificador.controles.modal.controles.fec1.placeholder }    
                                                col=        { confPantalla.planificador.controles.modal.controles.fec1.col } 
                                                required=   { confPantalla.planificador.controles.modal.controles.fec1.required }
                                                readonly=   { confPantalla.planificador.controles.modal.controles.fec1.readonly }                                                
                                                disabled=   { confPantalla.planificador.controles.modal.controles.fec1.disabled }
                                                change=     { e => handleInput(e,0) }
                                                onBlur=     {null} 
                                            />
                                            <FormInput                                                        
                                                estadoForm= { "" }
                                                value=      { fecPrev }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.planificador.controles.modal.controles.fec2.id }   
                                                type=       { confPantalla.planificador.controles.modal.controles.fec2.type }  
                                                name=       { confPantalla.planificador.controles.modal.controles.fec2.name }   
                                                label=      { confPantalla.planificador.controles.modal.controles.fec2.label }                                                       
                                                placeholder={ confPantalla.planificador.controles.modal.controles.fec2.placeholder }    
                                                col=        { confPantalla.planificador.controles.modal.controles.fec2.col } 
                                                required=   { confPantalla.planificador.controles.modal.controles.fec2.required }
                                                readonly=   { confPantalla.planificador.controles.modal.controles.fec2.readonly }                                                
                                                disabled=   { confPantalla.planificador.controles.modal.controles.fec2.disabled }
                                                change=     { e => handleInput(e,1) }
                                                onBlur=     {null} 
                                            />
                                        </FormRow>
                                        <FormRow>
                                            <FormInput                                                        
                                                estadoForm= { "" }
                                                value=      { descripcion }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.planificador.controles.modal.controles.descripcion.id }   
                                                type=       { confPantalla.planificador.controles.modal.controles.descripcion.type }  
                                                name=       { confPantalla.planificador.controles.modal.controles.descripcion.name }   
                                                label=      { confPantalla.planificador.controles.modal.controles.descripcion.label }                                                       
                                                placeholder={ confPantalla.planificador.controles.modal.controles.descripcion.placeholder }    
                                                col=        { confPantalla.planificador.controles.modal.controles.descripcion.col } 
                                                required=   { confPantalla.planificador.controles.modal.controles.descripcion.required }
                                                readonly=   { confPantalla.planificador.controles.modal.controles.descripcion.readonly }                                                
                                                disabled=   { confPantalla.planificador.controles.modal.controles.descripcion.disabled }
                                                change=     { e => handleInput(e,2) }
                                                onBlur=     {null} 
                                            />
                                        </FormRow>                                        
                                        <Button 
                                            clases={ "col-12 mt-2" }
                                            color={ "success" }  
                                            type="button" 
                                            icono={ "" }   
                                            id="btncalcplan"     
                                            texto={ confPantalla.planificador.controles.btncrear.title }   
                                            accion={ enviarDocumentos } 
                                            disabled={ centro == "" || agente == "" || fecIni == "" || fecPrev == "" || descripcion == "" ? true :  false }
                                        />
                                        <p className="font-italic text-center">{ confPantalla.planificador.controles.modal.required.title }</p> 
                                  
                                    </Modal.Body>                            
                                </Modal>    
                            </Section>
                            
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>                                    
        </div>
        <Footer />        
        </div>

    );

}
export default Planificador