import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Informes from '../../componentes/formulario/informes/Informes';


import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getTitle } from '../../helpers/helperApp';

import { Fragment } from 'react';

function Ubicaciones(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, saveFormularioHijo , handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleDeleteItemPrin, handleSubmitPrin, resetFormPrin, handleSelectFormPrin } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{           
                    document.title = getTitle(confPantalla.config.trad, confPantalla.ubicaciones.title);                            
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        saveEstadoFormPrin(confPantalla.ubicaciones.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.ubicaciones.hijo.estadoform);                                
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.ubicaciones.padre.urls);
                                saveFormularioPrin(confPantalla.ubicaciones.padre.modelform);
                                saveFormularioHijo(confPantalla.ubicaciones.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.ubicaciones.padre.urls, confPantalla.ubicaciones.hijo.urls, confPantalla.ubicaciones.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);   
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])
 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.ubicaciones.section.titulo } icono={ confPantalla.ubicaciones.section.icono } col={ confPantalla.ubicaciones.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.ubicaciones.section.wiki } >
                            <Formulario 
                                        estadoForm= { estadoFormPrin }
                                        loading=    { loadReg ? true : false }
                                        btnNew={ confPantalla.ubicaciones.padre.btnform.new }
                                        btnSave={ confPantalla.ubicaciones.padre.btnform.save }
                                        btnSaveAndNew={ confPantalla.ubicaciones.padre.btnform.saveandnew }
                                        btnDelete={ confPantalla.ubicaciones.padre.btnform.delete }
                                        btnClone={ confPantalla.ubicaciones.padre.btnform.clone }
                                        handleSubmit = { e => handleSubmitPrin(e, confPantalla.ubicaciones.padre.urls, confPantalla.ubicaciones.padre.modelform, confPantalla.ubicaciones.padre.tabla, confPantalla.ubicaciones.hijo.modelform, confPantalla.ubicaciones.title) }
                                        formulario = { formularioPrin } 
                                        resetForm = { e => resetFormPrin(confPantalla.ubicaciones.padre.modelform, true, confPantalla.ubicaciones.padre.tabla) } 
                                        handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.ubicaciones.padre.urls, confPantalla.ubicaciones.padre.modelform, confPantalla.ubicaciones.padre.tabla, confPantalla.ubicaciones.title) } 
                            >         
                                <Informes
                                    select =     { confPantalla.ubicaciones.padre.controles.informes.select }
                                    formulario = { formularioPrin }
                                    loadReg =    { loadReg ? true : false }
                                    estadoForm=  { estadoFormPrin } 
                                    traduction=  { confPantalla.config.trad.components }
                                />   
                                <hr className="separator" />
                                <h4>{ formularioPrin.ruta }</h4>
                                {/* <Tabs defaultActiveKey="tab1" id="tab1"> */}
                                    {/* <Tab eventKey="tab1" title={ confPantalla.ubicaciones.tabs.tab1.title }> */}
                                        <FormRow clases="" >
                                            <MiSelect  
                                                estadoForm=  { estadoFormPrin }
                                                val=         { formularioPrin.pad  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.ubicaciones.padre.controles.pad.id }    
                                                name=        { confPantalla.ubicaciones.padre.controles.pad.name }   
                                                label=       { confPantalla.ubicaciones.padre.controles.pad.label }    
                                                placeholder= { confPantalla.ubicaciones.padre.controles.pad.placeholder }
                                                col=         { confPantalla.ubicaciones.padre.controles.pad.col } 
                                                isClearable= { confPantalla.ubicaciones.padre.controles.pad.isclearable }
                                                isSearchable={ confPantalla.ubicaciones.padre.controles.pad.issearchable }
                                                dataType=    { confPantalla.ubicaciones.padre.controles.pad.datatype }
                                                data=        { confPantalla.ubicaciones.padre.controles.pad.data }
                                                required=    { confPantalla.ubicaciones.padre.controles.pad.required } 
                                                disabled=    { confPantalla.ubicaciones.padre.controles.pad.disabled }
                                                readOnly =   { confPantalla.ubicaciones.padre.controles.pad.readonly }
                                                disablethisid={confPantalla.ubicaciones.padre.controles.pad.disablethisid}
                                                formulario = { formularioPrin } 
                                                change=      { handleSelectFormPrin }                                                    
                                            />                                    
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin.nom }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.ubicaciones.padre.controles.nom.id }   
                                                type=       { confPantalla.ubicaciones.padre.controles.nom.type }  
                                                name=       { confPantalla.ubicaciones.padre.controles.nom.name }   
                                                label=      { confPantalla.ubicaciones.padre.controles.nom.label }                                                       
                                                placeholder={ confPantalla.ubicaciones.padre.controles.nom.placeholder }    
                                                col=        { confPantalla.ubicaciones.padre.controles.nom.col } 
                                                required=   { confPantalla.ubicaciones.padre.controles.nom.required }
                                                readonly=   { confPantalla.ubicaciones.padre.controles.nom.readonly }                                                
                                                disabled=   { confPantalla.ubicaciones.padre.controles.nom.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin.nom_en }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.ubicaciones.padre.controles.nom_en.id }   
                                                type=       { confPantalla.ubicaciones.padre.controles.nom_en.type }  
                                                name=       { confPantalla.ubicaciones.padre.controles.nom_en.name }   
                                                label=      { confPantalla.ubicaciones.padre.controles.nom_en.label }                                                       
                                                placeholder={ confPantalla.ubicaciones.padre.controles.nom_en.placeholder }    
                                                col=        { confPantalla.ubicaciones.padre.controles.nom_en.col } 
                                                required=   { confPantalla.ubicaciones.padre.controles.nom_en.required }
                                                readonly=   { confPantalla.ubicaciones.padre.controles.nom_en.readonly }                                                
                                                disabled=   { confPantalla.ubicaciones.padre.controles.nom_en.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                            <MiSelect  
                                                estadoForm=  { estadoFormPrin }
                                                val=         { formularioPrin.idcen  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.ubicaciones.padre.controles.idcen.id }    
                                                name=        { confPantalla.ubicaciones.padre.controles.idcen.name }   
                                                label=       { confPantalla.ubicaciones.padre.controles.idcen.label }    
                                                placeholder= { confPantalla.ubicaciones.padre.controles.idcen.placeholder }
                                                col=         { confPantalla.ubicaciones.padre.controles.idcen.col } 
                                                isClearable= { confPantalla.ubicaciones.padre.controles.idcen.isclearable }
                                                isSearchable={ confPantalla.ubicaciones.padre.controles.idcen.issearchable }
                                                dataType=    { confPantalla.ubicaciones.padre.controles.idcen.datatype }
                                                data=        { confPantalla.ubicaciones.padre.controles.idcen.data }
                                                required=    { confPantalla.ubicaciones.padre.controles.idcen.required } 
                                                disabled=  { confPantalla.ubicaciones.padre.controles.idcen.disabled }
                                                readOnly =   { confPantalla.ubicaciones.padre.controles.idcen.readonly }
                                                disablethisid={confPantalla.ubicaciones.padre.controles.idcen.disablethisid}
                                                disableids={confPantalla.ubicaciones.padre.controles.idcen.disableids}
                                                formulario= { formularioPrin }   
                                                change= { handleSelectFormPrin }
                                            /> 
                                        </FormRow>       
                                    {/* </Tab> */}
                                    {/* <Tab eventKey="tab2" title={ confPantalla.ubicaciones.tabs.tab2.title }>
                                        <Informes
                                            select =     { confPantalla.ubicaciones.padre.controles.informes.select }
                                            formulario = { formularioPrin }
                                            loadReg =    { loadReg ? true : false }
                                            estadoForm=  { estadoFormPrin } 
                                            traduction=  { confPantalla.config.trad.components }
                                        />
                                    </Tab> */}
                                {/* </Tabs> */}
                                                     
                            </Formulario>
                            <MiDataTable 
                                formulario={formularioPrin}
                                estadoForm= { estadoFormPrin }
                                id={"ubicaciones"}
                                columnas={confPantalla.ubicaciones.padre.datatable.columns}
                                data={registrosPrin}
                                buttons={confPantalla.ubicaciones.padre.datatable.buttons}
                                ordering={confPantalla.ubicaciones.padre.datatable.ordering}
                                order={confPantalla.ubicaciones.padre.datatable.order}
                                searching={confPantalla.ubicaciones.padre.datatable.searching}
                                paging={confPantalla.ubicaciones.padre.datatable.paging}
                                select={confPantalla.ubicaciones.padre.datatable.select}
                                pagelength={confPantalla.ubicaciones.padre.datatable.pagelength}
                                handleEditReg= { e => handleEditRegPrin(e, confPantalla.ubicaciones.padre.urls, confPantalla.ubicaciones.hijo.urls, confPantalla.ubicaciones.padreshijos, confPantalla.ubicaciones.hijo.modelform, confPantalla.ubicaciones.padre.tabla) }
                                handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.ubicaciones.padre.urls, confPantalla.ubicaciones.padre.modelform, confPantalla.ubicaciones.padre.tabla, confPantalla.ubicaciones.title) }
                                saveLoadingTable={ saveLoadingTable }
                                loadingTable={loadingTable}
                                columnactions={confPantalla.ubicaciones.padre.datatable.columnactions.title}
                                traduction={confPantalla.config.trad.components.datatable}
                            />                                                                                                             
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Ubicaciones