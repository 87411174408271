import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiSelect from '../../componentes/formulario/MiSelect';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';
import { Alert } from '../../componentes/ui/Alert';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getTitle } from '../../helpers/helperApp';

import { Fragment } from 'react';

function TipDoc(props){

    const cookies = new Cookies();

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectMultipleFormPrin } = useLogicForm();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{     
                    document.title = getTitle(confPantalla.config.trad, confPantalla.tipdoc.title);                                   
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        saveEstadoFormPrin(confPantalla.tipdoc.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.tipdoc.hijo.estadoform);                              
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.tipdoc.padre.urls);
                                saveFormularioPrin(confPantalla.tipdoc.padre.modelform);
                                saveFormularioHijo(confPantalla.tipdoc.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.tipdoc.padre.urls, confPantalla.tipdoc.hijo.urls, confPantalla.tipdoc.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);   
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 
        
    },[confPantalla])
    


 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.tipdoc.section.titulo } icono={ confPantalla.tipdoc.section.icono } col={ confPantalla.tipdoc.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.tipdoc.section.wiki } >
                                        <Formulario 
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.tipdoc.padre.btnform.new }
                                                    btnSave={ confPantalla.tipdoc.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.tipdoc.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.tipdoc.padre.btnform.delete }
                                                    btnClone={ confPantalla.tipdoc.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.tipdoc.padre.urls, confPantalla.tipdoc.padre.modelform, confPantalla.tipdoc.padre.tabla, confPantalla.tipdoc.hijo.modelform, confPantalla.tipdoc.title) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.tipdoc.padre.modelform, true, confPantalla.tipdoc.padre.tabla) }  
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.tipdoc.padre.urls, confPantalla.tipdoc.padre.modelform, confPantalla.tipdoc.padre.tabla, confPantalla.tipdoc.title) } 
                                        >                                        
                                            <Tabs defaultActiveKey="tab1" id="tab1">
                                                <Tab eventKey="tab1" title={ confPantalla.tipdoc.tabs.tab1.title }>
                                                    {/* <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.pad  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.tipdoc.padre.controles.pad.id }    
                                                            name=        { confPantalla.tipdoc.padre.controles.pad.name }   
                                                            label=       { confPantalla.tipdoc.padre.controles.pad.label }    
                                                            placeholder= { confPantalla.tipdoc.padre.controles.pad.placeholder }
                                                            col=         { confPantalla.tipdoc.padre.controles.pad.col } 
                                                            isClearable= { confPantalla.tipdoc.padre.controles.pad.isclearable }
                                                            isSearchable={ confPantalla.tipdoc.padre.controles.pad.issearchable }
                                                            dataType=    { confPantalla.tipdoc.padre.controles.pad.datatype }
                                                            data=        { confPantalla.tipdoc.padre.controles.pad.data }
                                                            required=    { confPantalla.tipdoc.padre.controles.pad.required } 
                                                            disabled=    { confPantalla.tipdoc.padre.controles.pad.disabled }
                                                            readOnly =   { confPantalla.tipdoc.padre.controles.pad.readonly }
                                                            disablethisid={confPantalla.tipdoc.padre.controles.pad.disablethisid}
                                                            formulario = { formularioPrin } 
                                                            change=      { handleSelectFormPrin }                                                    
                                                        /> 
                                                    </FormRow> */}
                                                    <FormRow clases="" >
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cod }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.tipdoc.padre.controles.cod.id }   
                                                            type=       { confPantalla.tipdoc.padre.controles.cod.type }  
                                                            name=       { confPantalla.tipdoc.padre.controles.cod.name }   
                                                            label=      { confPantalla.tipdoc.padre.controles.cod.label }                                                       
                                                            placeholder={ confPantalla.tipdoc.padre.controles.cod.placeholder }    
                                                            col=        { confPantalla.tipdoc.padre.controles.cod.col } 
                                                            required=   { confPantalla.tipdoc.padre.controles.cod.required }
                                                            readonly=   { confPantalla.tipdoc.padre.controles.cod.readonly }                                                
                                                            disabled=   { confPantalla.tipdoc.padre.controles.cod.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cod_en }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.tipdoc.padre.controles.cod_en.id }   
                                                            type=       { confPantalla.tipdoc.padre.controles.cod_en.type }  
                                                            name=       { confPantalla.tipdoc.padre.controles.cod_en.name }   
                                                            label=      { confPantalla.tipdoc.padre.controles.cod_en.label }                                                       
                                                            placeholder={ confPantalla.tipdoc.padre.controles.cod_en.placeholder }    
                                                            col=        { confPantalla.tipdoc.padre.controles.cod_en.col } 
                                                            required=   { confPantalla.tipdoc.padre.controles.cod_en.required }
                                                            readonly=   { confPantalla.tipdoc.padre.controles.cod_en.readonly }                                                
                                                            disabled=   { confPantalla.tipdoc.padre.controles.cod_en.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.tipdoc.padre.controles.nom.id }   
                                                            type=       { confPantalla.tipdoc.padre.controles.nom.type }  
                                                            name=       { confPantalla.tipdoc.padre.controles.nom.name }   
                                                            label=      { confPantalla.tipdoc.padre.controles.nom.label }                                                       
                                                            placeholder={ confPantalla.tipdoc.padre.controles.nom.placeholder }    
                                                            col=        { confPantalla.tipdoc.padre.controles.nom.col } 
                                                            required=   { confPantalla.tipdoc.padre.controles.nom.required }
                                                            readonly=   { confPantalla.tipdoc.padre.controles.nom.readonly }                                                
                                                            disabled=   { confPantalla.tipdoc.padre.controles.nom.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom_en }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.tipdoc.padre.controles.nom_en.id }   
                                                            type=       { confPantalla.tipdoc.padre.controles.nom_en.type }  
                                                            name=       { confPantalla.tipdoc.padre.controles.nom_en.name }   
                                                            label=      { confPantalla.tipdoc.padre.controles.nom_en.label }                                                       
                                                            placeholder={ confPantalla.tipdoc.padre.controles.nom_en.placeholder }    
                                                            col=        { confPantalla.tipdoc.padre.controles.nom_en.col } 
                                                            required=   { confPantalla.tipdoc.padre.controles.nom_en.required }
                                                            readonly=   { confPantalla.tipdoc.padre.controles.nom_en.readonly }                                                
                                                            disabled=   { confPantalla.tipdoc.padre.controles.nom_en.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />  
                                                    </FormRow>
                                                    <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { confPantalla.centros.padre.estadoform }
                                                            val=         { formularioPrin.sto  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.tipdoc.padre.controles.sto.id }    
                                                            name=        { confPantalla.tipdoc.padre.controles.sto.name }   
                                                            label=       { confPantalla.tipdoc.padre.controles.sto.label }    
                                                            placeholder= { confPantalla.tipdoc.padre.controles.sto.placeholder }
                                                            col=         { confPantalla.tipdoc.padre.controles.sto.col } 
                                                            isClearable= { confPantalla.tipdoc.padre.controles.sto.isclearable }
                                                            isSearchable={ confPantalla.tipdoc.padre.controles.sto.issearchable }
                                                            dataType=    { confPantalla.tipdoc.padre.controles.sto.datatype }
                                                            data=        { confPantalla.tipdoc.padre.controles.sto.data }
                                                            required=    { confPantalla.tipdoc.padre.controles.sto.required } 
                                                            disabled=  { confPantalla.tipdoc.padre.controles.sto.disabled }
                                                            readOnly =   { confPantalla.tipdoc.padre.controles.sto.readonly }
                                                            disablethisid={confPantalla.tipdoc.padre.controles.sto.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />
                                                         <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cpk }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.tipdoc.padre.controles.cpk.id }   
                                                            type=       { confPantalla.tipdoc.padre.controles.cpk.type }  
                                                            name=       { confPantalla.tipdoc.padre.controles.cpk.name }   
                                                            label=      { confPantalla.tipdoc.padre.controles.cpk.label }                                                       
                                                            placeholder={ confPantalla.tipdoc.padre.controles.cpk.placeholder }    
                                                            col=        { confPantalla.tipdoc.padre.controles.cpk.col } 
                                                            required=   { confPantalla.tipdoc.padre.controles.cpk.required }
                                                            readonly=   { confPantalla.tipdoc.padre.controles.cpk.readonly }                                                
                                                            disabled=   { confPantalla.tipdoc.padre.controles.cpk.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />  
                                                    </FormRow>                                                    
                                                </Tab>
                                                <Tab eventKey="tab2" title={ confPantalla.tipdoc.tabs.tab2.title }>
                                                    <TextArea                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.notas }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.tipdoc.padre.controles.notas.id }   
                                                        name=       { confPantalla.tipdoc.padre.controles.notas.name }
                                                        rows=       { confPantalla.tipdoc.padre.controles.notas.rows }      
                                                        label=      { confPantalla.tipdoc.padre.controles.notas.label }                                                       
                                                        placeholder={ confPantalla.tipdoc.padre.controles.notas.placeholder }    
                                                        col=        { confPantalla.tipdoc.padre.controles.notas.col } 
                                                        required=   { confPantalla.tipdoc.padre.controles.notas.required }
                                                        readonly=   { confPantalla.tipdoc.padre.controles.notas.readonly }                                                
                                                        disabled=   { confPantalla.tipdoc.padre.controles.notas.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                    <TextArea                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.notas_en }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.tipdoc.padre.controles.notas_en.id }   
                                                        name=       { confPantalla.tipdoc.padre.controles.notas_en.name }
                                                        rows=       { confPantalla.tipdoc.padre.controles.notas_en.rows }      
                                                        label=      { confPantalla.tipdoc.padre.controles.notas_en.label }                                                       
                                                        placeholder={ confPantalla.tipdoc.padre.controles.notas_en.placeholder }    
                                                        col=        { confPantalla.tipdoc.padre.controles.notas_en.col } 
                                                        required=   { confPantalla.tipdoc.padre.controles.notas_en.required }
                                                        readonly=   { confPantalla.tipdoc.padre.controles.notas_en.readonly }                                                
                                                        disabled=   { confPantalla.tipdoc.padre.controles.notas_en.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                </Tab>
                                                <Tab eventKey="tab3" title={ confPantalla.tipdoc.tabs.tab3.title }>
                                                    
                                                </Tab>                                                                                                                                                        
                                            </Tabs>

                                        </Formulario>
                                        <MiDataTable 
                                            formulario={formularioPrin}
                                            estadoForm= { estadoFormPrin }
                                            id={"tipdoc"}
                                            columnas={confPantalla.tipdoc.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.tipdoc.padre.datatable.buttons}
                                            ordering={confPantalla.tipdoc.padre.datatable.ordering}
                                            order={confPantalla.tipdoc.padre.datatable.order}
                                            searching={confPantalla.tipdoc.padre.datatable.searching}
                                            paging={confPantalla.tipdoc.padre.datatable.paging}
                                            select={confPantalla.tipdoc.padre.datatable.select}
                                            pagelength={confPantalla.tipdoc.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.tipdoc.padre.urls, confPantalla.tipdoc.hijo.urls, confPantalla.tipdoc.padreshijos, confPantalla.tipdoc.hijo.modelform, confPantalla.tipdoc.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.tipdoc.padre.urls, confPantalla.tipdoc.padre.modelform, confPantalla.tipdoc.padre.tabla, confPantalla.tipdoc.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.tipdoc.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />                                             
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default TipDoc