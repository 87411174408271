import React, { useEffect, useState, useContext, Fragment } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import Spinner from 'react-bootstrap/Spinner';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import BaseSelect from "react-select";


import { LoginContext } from '../../../hooks/context/LoginContext';

import { SRLWrapper } from 'simple-react-lightbox';

import { getApiUrl, getPantDocFromNivel, setStockOptionsSelectUbi } from '../../../helpers/helperApp';

require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-buttons');
require('datatables.net-bs4');

require('datatables.net/js/jquery.dataTables');
require('datatables.net-buttons/js/dataTables.buttons');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-select');
require('datatables.mark.js/build/templates/datatables.mark');
require('datatables.mark.js/dist/datatables.mark.es6');
require('datatables.net-rowreorder-bs4/js/rowReorder.bootstrap4');
require('datatables.net-rowreorder');

$.DataTable = DataTable;

let date = new Date();
let mesHoy = date.getMonth() + 1;
let h = date.getDate();
let hoy = (h < 10 ? h = '0'+h : h = h )+'/'+(mesHoy < 10 ? mesHoy = '0'+mesHoy : mesHoy = mesHoy )+'/'+date.getFullYear();

var botones = [
    // {
    //     extend: 'pageLength',
    //     text: `<i style='font-size: 20px;' class='fal fa-list-ol'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Ver número de registros'
    // },
    // {
    //     extend: 'copy',
    //     text: `<i style='font-size: 20px;' class='fal fa-copy'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Copiar al portapapeles',
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // }, 
    // {
    //     extend: 'csv',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-file-csv'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Exportar a csv',
    //     exportOptions: {
    //         columns: ':visible'
    //     }

    // },
    // {
    //     extend: 'excel',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-file-excel'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Exportar a excel',
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // }, 
    // {
    //     extend: 'pdf',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-file-pdf'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     orientation: 'landscape', 
    //     title: hoy,
    //     // titleAttr: 'Exportar a pdf',
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // }, 
    // {
    //     extend: 'print',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-print'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Imprimir',
    //     customize: function ( win ) {
    //         $(win.document.body)
    //             .css( 'font-size', '22pt' );

    //         $(win.document.body).find( 'table' )
    //             .addClass( 'compact' )
    //             .css( 'font-size', '14pt' );
    //     },
    //     exportOptions: {
    //         modifier: {
    //             selected: null
    //         }
    //     },
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // },
    // {
    //     extend: 'colvis',
    //     text: `<i style='font-size: 20px;' class='fad fa-line-columns'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    // }
];

function MiDataTableCanPos(props){


    const cookies = new Cookies();

    const { confApp } = useContext(LoginContext);

    // ***** props *****
    const { id, datos, columnas, formulario, selectId, inputCantidad, inputListaRef, selectubi, optionselectubi, crearYDividir, getStockOptionsSelectUbi } = props;
    let { buttons, ordering, order, searching, paging, select, saveLoadingTable, loadingTable, estadoForm, columnactions, traduction, linkColumns, functionClickTd, pagelength, columnsFunctionClickTd, txtFilterTable, noDisplay, rowreorder, functionAfterReorder } = props;
   
    const [noDisplayState, saveNoDisplayState] = useState(noDisplay);
    const [data, saveData] = useState(datos);
    
    useEffect(()=>{
       
        const accionesMiDataTable = columnas.find(column => column["title"] === columnactions);

        // Si ya existe la columna de acciones la eliminamos ya que sino da error cuando seleccionas un reg, vas a otra pantalla, vuelves y seleccionas otro reg.        
        if( accionesMiDataTable !== undefined ){
            columnas.splice( (columnas.length-1), 1 );
        }

        // Añadimos los enlaces a los td
        if( linkColumns != undefined ){
            for( let i = 0; i < columnas.length; i++ ){
                columnas[i].createdCell = (td, cellData, rowData, row, col) => {

                    if( linkColumns[i] != undefined ){
                        // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                        if ('styleCanPosWizard' in rowData){
                            $(td).addClass(rowData["styleCanPosWizard"]);
                        }
                        //Creamos los enlaces
                        if( typeof cellData == "string" ){
                            if( cellData.toLowerCase() != "Media".toLowerCase()  && cellData != "Average".toLowerCase() && cellData.toLowerCase() != "Total".toLowerCase() ){
                                // Si el enlace es a la pantalla de documentos obtenemos la pantalla que le corresponde según su id
                                if( linkColumns[i].link == "/documentos" ){
                                    linkColumns[i].link = getPantDocFromNivel(cookies.get('nivel'));
                                }

                                if( linkColumns[i].data.includes("/") && linkColumns[i].link != "" ){
                                    const links = linkColumns[i].data.split("/");
                                    $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[links[0]]+"/"+rowData[links[1]]+"' >"+cellData+"</a>");
                                }else if( linkColumns[i].link != "" ){
                                    $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[linkColumns[i].data]+"' >"+cellData+"</a>");
                                }
                            }
                        }
                    } 
                    // Si la celda contiene la cadena '/assets/' y rowData hay una key foto asumimos que es una foto
                    if( 'foto' in rowData || 'img' in rowData ){
                        if( cellData ){
                            if( cellData.includes('/assets/')  ){
                                $(td).html("<img src='"+getApiUrl(window.location.origin)+cellData+"' style='width: 100px !important; cursor: pointer;' />");
                            }
                        }else{
                            $(td).html("<span></span>");
                        }
                    }                   
                    if( functionClickTd ){
                        if( columnsFunctionClickTd ){
                            if( columnsFunctionClickTd.includes(col) ){
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }
                        }else{
                            $(td).on('click', function (e) {
                                e.preventDefault();
                                functionClickTd(e, rowData);
                            });
                        }                        
                    }                    
                }
            }
        }else{
            for( let i = 0; i < columnas.length; i++ ){
  
                columnas[i].createdCell = (td, cellData, rowData, row, col) => {

                        // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                        if ('styleCanPosWizard' in rowData){
                            $(td).addClass(rowData["styleCanPosWizard"]);
                        }
                        // Si la celda contiene la cadena '/assets/' y rowData hay una key foto asumimos que es una foto
                        if( 'foto' in rowData || 'img' in rowData ){
                            if( cellData ){
                                if( cellData.includes('/assets/')  ){

                                    let templateFoto = `
                                    <div>                                   
                                            <div class="containerImgTablePending">
                                                <img class="imgUplmTablePending" src=${getApiUrl(window.location.origin)+cellData} />
                                            </div>                                                                                                                                        
                                    </div>
                                    `;
                                    $(td).html(templateFoto);
                                    // $(td).html("<img src='"+getApiUrl(window.location.origin)+cellData+"' style='width: 100px !important; cursor: pointer' />");
                                }
                            }else{
                                $(td).html("<span></span>");
                            }
                        }
                        if( functionClickTd ){
                            if( columnsFunctionClickTd ){
                                if( columnsFunctionClickTd.includes(col) ){
                                    $(td).on('click', function (e) {
                                        e.preventDefault();
                                        functionClickTd(e, rowData);
                                    });
                                }
                            }else{
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }                        
                        }                    
                }
            }
        }

        columnas.map((columna, i) => {
            if( i == 2 ){
                // Cantidad
                columna.createdCell = (td, cellData, rowData, row, col) => {                                          
                    let templateNecesario =  ReactDOM.render( 
                        <div className="row">
                            <div className="col-10" >                           
                                <input  
                                    type={ inputCantidad.type } 
                                    className={ "form-control text-right input-cant"} 
                                    id={ rowData.id } 
                                    name={ rowData.id } 
                                    placeholder={ inputCantidad.placeholder }
                                    // value={ rowData.cantidad }
                                    defaultValue={ rowData.num1 }  
                                    onChange={ e => handleChangeCantidad(e, rowData) } 
                                    // onBlur={ e => handleChangeFec2(e, rowData, "fec2") } 
                                    required={ rowData.respuesta.data[0].val.includes("R") ? true : false } 
                                    readOnly={ estadoForm === "readonly" || rowData.respuesta.data[0].val.includes("SL") ? true : false }                                                                         
                                    disabled={ false } 
                                />  
                            </div>                            
                        </div>                              
                    ,td);
                    if( rowData.num1 <= 0 ){
                        $(td).addClass("alert-danger");
                    }else{
                        $(td).addClass("alert-success");
                    }
                    $(td).html(templateNecesario)
                } 
            }  
            if( i == 3 ){
                // Lista referencia
                columna.createdCell = (td, cellData, rowData, row, col) => {                                          
                    let templateNecesario =  ReactDOM.render( 
                        <div className="row">
                            <div className="col-10" >                           
                                <input  
                                    type={ inputListaRef.type } 
                                    className={ "form-control input-listaref"} 
                                    id={ rowData.id } 
                                    name={ rowData.id } 
                                    // placeholder={ inputListaRef.placeholder }
                                    placeholder={ "" }
                                    defaultValue={ rowData.txt2 }  
                                    onChange={ e => handleChangeListaRef(e, rowData) } 
                                    required={ rowData.respuesta.data[0].val.includes("R") ? true : false } 
                                    readOnly={ estadoForm === "readonly" || rowData.respuesta.data[0].val.includes("SL") ? true : false }                                                                         
                                    disabled={ false } 
                                />  
                            </div>                            
                        </div>                              
                    ,td);
                    if( rowData.num1 <= 0 ){
                        $(td).addClass("alert-danger");
                    }else{
                        $(td).addClass("alert-success");
                    }                   
                    $(td).html(templateNecesario)
                } 
            }
        })
            

        buttons === undefined || buttons === true || buttons === "true" ? buttons = 'Bfrtip' : buttons = 'frtip';
        ordering === undefined ? ordering = true : ordering = ordering;
        order === undefined ? order = [[0,"asc"]] : order = order;
        searching === undefined ? searching = true : searching = searching;
        paging === undefined ? paging = true : paging = paging;
        pagelength === undefined ? pagelength = traduction.defaultpagelength : pagelength = pagelength;
        select === undefined ? select = false : select = select;

        if( data ){
            if( data.length > 0 ){
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear( );
                    for (let i = 0; i < data.length; i++){
                        oTable.row.add(data[i]);      
                    }
                    oTable.draw();               
                }else{
                    $("."+id).DataTable({
                        mark: true,
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: data,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        rowReorder: rowreorder ? true : false,
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }        
                        },
                        rowCallback : function (row, data, index){ 
                            // Si td campo incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                            for (let item of row.children) {
                                if( item.outerText.includes("#") ){
                                    item.bgColor = item.outerText;
                                    item.innerText = "";
                                }
                            }
                        }
                    })
                }  
                // saveLoadingTable(false);       
            }else{
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear();
                    oTable.draw();               
                }{               
                    $("."+id).DataTable({
                        mark: true,
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: null,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        rowReorder: rowreorder ? true : false,
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }         
                        },
                        rowCallback : function (row, data, index){
                            // Si td campo incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                            for (let item of row.children) {
                                if( item.outerText.includes("#") ){
                                    item.bgColor = item.outerText;
                                    item.innerText = "";
                                }
                            }


                        }
                    })
                }
                // saveLoadingTable(false);
            }
        }else{
            if ( $.fn.DataTable.isDataTable("."+id) ) {
                let oTable = $("."+id).DataTable();
                oTable.clear();
                oTable.draw();               
            }{               
                $("."+id).DataTable({
                    mark: true,
                    rowId: function(a) {
                        return '#row-' + a.id;},
                    dom: buttons,
                    data: null,
                    columns: columnas,
                    ordering: ordering,
                    order: order,
                    searching: searching,
                    paging: paging,
                    select: select,
                    buttons: [botones],
                    // fixedHeader: true,
                    // scrollX: true,
                    responsive: false, 
                    autoWidth: false,
                    retrieve: true,
                    pageLength: parseInt(pagelength),
                    lengthMenu: [
                        [ 10, 25, 50, 100 ],
                        [ '10', '25', '50', '100' ]
                    ],
                    rowReorder: rowreorder ? true : false,
                    deferRender: true,
                    language: {
                        "sProcessing":    traduction.sprocessing.title,
                        "sLengthMenu":    traduction.slengthmenu.title,
                        "sZeroRecords":   traduction.szerorecords.title,
                        "sEmptyTable":    traduction.semptytable.title,
                        "sInfo":          traduction.sinfo.title,
                        "sInfoEmpty":     traduction.sinfoempty.title,
                        "sInfoFiltered":  traduction.sinfofiltered.title,
                        "sInfoPostFix":   traduction.sinfopostfix.title,
                        "sSearch":        traduction.ssearch.title,
                        "sUrl":           traduction.surl.title,
                        "sInfoThousands":  traduction.sinfothousands.title,
                        "sLoadingRecords": traduction.sloadingrecords.title,
                        "oPaginate": {
                            "sFirst":    traduction.sfirst.title,
                            "sLast":    traduction.slast.title,
                            "sNext":    traduction.snext.title,
                            "sPrevious": traduction.sprevious.title
                        },
                        "oAria": {
                            "sSortAscending":  traduction.ssortascending.title,
                            "sSortDescending": traduction.ssortdescending.title
                        },
                        select: {
                            rows: traduction.rows.title
                        },
                        buttons: {
                            copyTitle: traduction.copytitle.title,
                            copyKeys: traduction.copykeys.title,
                            copySuccess: {
                                _: traduction.copysuccess.many.title,
                                1: traduction.copysuccess.one.title
                            },
                        }       
                    },
                    rowCallback : function (row, data, index){
                        // Si algún td incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                        for (let item of row.children) {
                            if( item.outerText.includes("#") ){
                                item.bgColor = item.outerText;
                                item.innerText = "";
                            }
                        }                        
                    }
                })
            }
            // saveLoadingTable(false);
            
        }
        
        if( saveLoadingTable ){
            saveLoadingTable(false); 
        }

        // Código para llamar a una función tras reordenar las filas
        if( functionAfterReorder != null  ){
            let tabla = $('#'+id).DataTable();
            tabla.on( 'row-reorder', function ( e, diff, edit ) {
                functionAfterReorder( e, diff, edit);         
            } );
        }
        
    }, [data, formulario.id])


    const handleChangeCantidad = (e, rowData) => {          
        data.map(d => {
            if( rowData.id == d.id ){
                d.num1 = e.target.value;
            }
        })

        const row = document.getElementById(`#row-${rowData.id}`).children;
        for(let i = 0; i <  row.length; i++){
            if(e.target.value <= 0){
                row[i].classList.remove('alert-success');
                row[i].classList.add('alert-danger');
            }else{
                row[i].classList.add('alert-success');
                row[i].classList.remove('alert-danger');
            }
        }        
    }

    const handleChangeListaRef = (e, rowData) => {
        data.map(d => {
            if( rowData.id == d.id ){
                    d.num1 = e.target.value
            }
        })
    }
    

    // Código para evitar los acentos en las búsquedas
    function removeAccents ( data ) {
        if ( data.normalize ) {
            // Use I18n API if avaiable to split characters and accents, then remove
            // the accents wholesale. Note that we use the original data as well as
            // the new to allow for searching of either form.
            return data +' '+ data
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
     
        return data;
    }
    var searchType = jQuery.fn.DataTable.ext.type.search;
 
    searchType.string = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data ) :
                data;
    };
    
    searchType.html = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data.replace( /<.*?>/g, '' ) ) :
                data;
    };
    // FIN: Código para evitar los acentos en las búsquedas 
    
    // Resaltar el row que estamos editando en los formularios
    if( formulario ){

        if( formulario.id != "" ){        
            detectSelectedLine(true);
        }

        $("."+id).on( 'page.dt', function () {
            detectSelectedLine();
        } );
    
        $("."+id).on( 'length.dt', function () {
            detectSelectedLine(true);
        } );
    }

    // Añadir clase a la línea del registro seleccionado
    function detectSelectedLine(go=false){        
        setTimeout(()=> {
            let trs = document.querySelectorAll("table#"+id+" tr.selectedRow");
            for( let i = 0; i < trs.length; i++ ){
                trs[i].classList.remove("selectedRow");
            }

            const tr = document.querySelector("span[id='"+formulario.id+"']");        
            if( tr ){
                tr.parentElement.parentElement.parentElement.classList.add("selectedRow");
            }

            if( go ){
                goToPage();
            }
            
        }, 100)
    }

    // Salta a la página del registro seleccionado
    function goToPage(){
        let oTable = $("."+id).DataTable();
        let info = oTable.page.info();

        // Utilizamos los registros filtrados a través del buscador
        const filteredRegs = oTable.rows( {search:'applied'} ).data();
        // let rows = oTable.rows().data();

        // Buscar la página del registro y saltar a ella
        let pos = 0;
        for( let i = 0; i < filteredRegs.length; i++ ){
            if( filteredRegs[i].id == formulario.id ){
                pos = i;
                break;
            }
        }
        if( pos > 0 ){
            oTable.page( Math.floor( pos / info.length ) ).draw( false );
        }
    }
    // FIN: Añadir clase a la línea del registro seleccionado

    // Seleccionar un id de la tabla
    if( selectId ){
        if( selectId.id != "" ){        
            detectSelectedLine2(selectId,true);
        }

        $("."+id).on( 'page.dt', function () {
            detectSelectedLine2(selectId);
        } );
    
        $("."+id).on( 'length.dt', function () {
            detectSelectedLine2(selectId,true);
        } );
    }
    function detectSelectedLine2(selectId, go=false){        
        setTimeout(()=> {
            let trs = document.querySelectorAll("table#"+id+" tr.selectedRow");
            for( let i = 0; i < trs.length; i++ ){
                trs[i].classList.remove("selectedRow");
            }

            let table = $("#"+id).DataTable();
            let rows = table.rows().data();
            for(let i=0; i < rows.length; i++){
                if( rows[i].id == selectId.id ){
                    table.row(':eq('+i+')', { page: 'current' }).select();
                }
            }

            if( go ){
                goToPage2(selectId);
            }
            
        }, 100)
    }
    function goToPage2(selectId){
        let oTable = $("."+id).DataTable();
        let info = oTable.page.info();

        // Utilizamos los registros filtrados a través del buscador
        const filteredRegs = oTable.rows( {search:'applied'} ).data();
        // let rows = oTable.rows().data();

        // Buscar la página del registro y saltar a ella
        let pos = 0;
        for( let i = 0; i < filteredRegs.length; i++ ){
            if( filteredRegs[i].id == selectId.id ){
                pos = i;
                break;
            }
        }
        if( pos > 0 ){
            oTable.page( Math.floor( pos / info.length ) ).draw( false );
        }
    }    
    // FIN: Seleccionar un id de la tabla




    // Filtramos en la tabla un texto recibido como parámetros
    if( txtFilterTable != null && txtFilterTable != "" ){
        let tabla = $('#'+id).DataTable();
        tabla.search(txtFilterTable).draw();
    }


    return(
        <Fragment>
            {   loadingTable ? 
                    <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                        <Spinner animation="border" />
                    </div>
                :
                null
            }
            <div className={noDisplayState ? "noDisplay" : ""} >
                <table id={id} className={id+" table table-sm table-bordered table-striped table-hover dataTable"} style={{width:"100%"}} ></table>
            </div>   
            
        </Fragment>
    )
}

export default MiDataTableCanPos;