import React, { useState, useEffect, useContext, Fragment } from 'react';
import Cookies from 'universal-cookie';
import { Link, NavLink } from 'react-router-dom';
import { LoginContext } from '../../../hooks/context/LoginContext';
import { loadTree } from '../../../helpers/MenuTreeHelper';
import { getIndexUrl } from '../../../helpers/helperApp';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Collapse, Button, ButtonGroup } from 'react-bootstrap';

import MenuSuperAdmin from './MenuSuperAdmin';
import MenuAdmin from './MenuAdmin';
import MenuEnc from './MenuEnc';
import MenuTrab from './MenuTrab';
import MenuEncCheckList from './checklist/MenuEncCheckList';
import MenuTrabCheckList from './checklist/MenuTrabCheckList';
import MenuCustom from './MenuCustom';

import { getApiUrl, getTitle } from '../../../helpers/helperApp';



function Sidebar(props){
 
    const { logo, sidebarLogo, activeMenuColor, sidebarColor, confApp, menuMode, customMenu } = useContext(LoginContext); 

    const [usuario, saveUsuario] = useState("");
    const [miNivel, saveMiNivel] = useState();
    const [miId, saveMiId] = useState();
    const [imgUser, saveImgUser] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
      
        const cookies = new Cookies();

        loadTree();
        
        // Obtenemos el nombre de usuario de las cookies
        if( cookies.get('nombre') ){
          saveUsuario(cookies.get('nombre'));
        }
        // Obtenemos el nivel de usuario para filtrar el menú en función de su nivel
        if( cookies.get('nivel') ){
          saveMiNivel(cookies.get('nivel'));
        }
        // Obtenemos el id de usuario
        if( cookies.get('id') ){
          saveMiId(cookies.get('id'));
        }
        // Obtenemos la imagen de perfil del usuario
        if( cookies.get('imguser') != "null" ){
          saveImgUser(cookies.get('imguser'));
        }

        
      },[])

      const toggleMenu = () => {
        setMenuOpen(!menuOpen);
      };

      let menu = null;
      switch(menuMode) {
        case 'checklist':
                  if( customMenu == 1 ){
                    menu = <MenuCustom 
                            confApp = { confApp }
                            miNivel = { miNivel }
                            activeMenuColor = { activeMenuColor }
                            propiedades={ props }
                          />
                  }else{
                    if( miNivel == 1 ){
                      // SuperAdministradores
                      menu = <MenuSuperAdmin 
                              confApp = { confApp }
                              miNivel = { miNivel }
                              activeMenuColor = { activeMenuColor }
                            />
                    }
                    if( miNivel == 2 ){
                      // Administradores
                      menu = <MenuAdmin 
                              confApp = { confApp }
                              miNivel = { miNivel }
                              activeMenuColor = { activeMenuColor }
                            />
                    }
                    if( miNivel == 3 ){
                      // Encargados
                      menu = <MenuEncCheckList 
                              confApp = { confApp }
                              miNivel = { miNivel }
                              activeMenuColor = { activeMenuColor }
                            />
                    }
                    if( miNivel == 4 ){
                      // Trabajadores
                      menu = <MenuTrabCheckList 
                            confApp = { confApp }
                            miNivel = { miNivel }
                            activeMenuColor = { activeMenuColor }
                          />
                    }                    
                  }                  
          break;        
        default:
                  if( customMenu == 1 ){
                    menu = <MenuCustom 
                            confApp = { confApp }
                            miNivel = { miNivel }
                            activeMenuColor = { activeMenuColor }
                            propiedades={ props }
                          />
                  }else{
                    if( miNivel == 1 ){
                      // SuperAdministradores
                      menu = <MenuSuperAdmin 
                              confApp = { confApp }
                              miNivel = { miNivel }
                              activeMenuColor = { activeMenuColor }
                            />
                    }
                    if( miNivel == 2 ){
                      // Administradores
                      menu = <MenuAdmin 
                              confApp = { confApp }
                              miNivel = { miNivel }
                              activeMenuColor = { activeMenuColor }
                            />
                    }
                    if( miNivel == 3 ){
                      // Encargados
                      menu = <MenuEnc 
                              confApp = { confApp }
                              miNivel = { miNivel }
                              activeMenuColor = { activeMenuColor }
                            />
                    }
                    if( miNivel == 4 ){
                      // Trabajadores
                      menu = <MenuTrab 
                            confApp = { confApp }
                            miNivel = { miNivel }
                            activeMenuColor = { activeMenuColor }
                          />
                    }
                  }
      }
     
      
    return(
        <Fragment>
        {
          confApp != undefined
          ?
          <Fragment>
            <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ backgroundColor: sidebarColor }}>
              <Link to={getIndexUrl(menuMode)} className="brand-link" style={{minHeight: "57px"}}>
                <img src={sidebarLogo} alt="" className="brand-image img-circle elevation-3" style={{opacity: .8, backgroundColor: "white"}} />
                {/* <span className="brand-text font-weight-light">{confApp.config.trad.maintenance.sidebar.title}</span> */}
                <span className="brand-text font-weight-light">{getTitle(confApp.config.trad, "")}</span>
                
              </Link>
          
              <div className="sidebar sidebar os-host os-theme-light os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition os-host-scrollbar-vertical-hidden">

              <div className="os-resize-observer-host observed"><div className="os-resize-observer" style={{left: "0px", right: "auto"}}></div></div>
                <div className="os-size-auto-observer observed" style={{height: "calc(100% + 1px)", float: "left"}}><div className="os-resize-observer"></div></div>
                <div className="os-content-glue" style={{margin: "0px -8px", width: "249px", height: "879px"}}></div>
                <div className="os-padding">
                  <div className="os-viewport os-viewport-native-scrollbars-invisible" style={{overflowY: "scroll"}} >
                    <div className="os-content" style={{padding: "0px 8px", height: "100%", width: "100%"}}>

                    

                                      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                                          <div className="image">
                                            {
                                                imgUser != null && imgUser.includes('/')
                                              ?
                                                <img src={getApiUrl(window.location.origin)+imgUser} className="img-circle elevation-2" alt="User Image" />
                                              :
                                                <img src={getApiUrl(window.location.origin)+"/assets/web/avatar.png"} className="img-circle elevation-2" alt="User Image" />
                                            }
                                          </div>
                                        <div className="info">
                                          <Link to={"/profile/"+miId} className="d-block">
                                            {confApp.profile.sidebarmenu.titulo} {usuario}
                                            <i className={confApp.profile.sidebarmenu.icono} style={{marginLeft:5}} />
                                          </Link>
                                        </div>
                                      </div>
                                      {menu}
                    </div>
                  </div>  
                </div>                                              
              </div>
            </aside>
            {/* <div className={`fixed-action-button ${menuOpen ? 'open' : ''}`}>
              <button className="btn btn-primary" onClick={toggleMenu}>
                <i className="fas fa-bars"></i>
              </button>
              <div className="menu-options">
                <button className="btn btn-primary" style={{width: "150px", borderRadius: "10px", borderColor: "#005cbf"}}>Opción 1</button>
                <button className="btn btn-primary" style={{width: "150px", borderRadius: "10px", borderColor: "#005cbf"}}>Opción 2</button>
                <button className="btn btn-primary" style={{width: "150px", borderRadius: "10px", borderColor: "#005cbf"}}>Opción 3</button>
              </div>
            </div> */}
          </Fragment>
          :
          null
        }
        </Fragment>
       

    )
}

export default Sidebar;