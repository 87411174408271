import React, { useEffect, useState, Fragment, useContext } from 'react';
import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';
import { alertService } from '../../services/alertservice';
import MiSelect from '../formulario/MiSelect';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import Spinner from 'react-bootstrap/Spinner';
import Cookies from 'universal-cookie';
import Section from '../../componentes/ui/Section';
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";

import { getApiUrl, getPantDocFromNivel, axiosPost, dateFormatByLocale } from '../../helpers/helperApp';

function Gant(props){

    const cookies = new Cookies();

    const {confPantalla, idPrin, idHijo, formulario, insideSection} = props;

    const { apiKey } = useContext(LoginContext);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const [tasks, saveTasks] = useState(null);
    const [loading, saveLoading] = useState();
    const [franjaTemporal, saveFranjaTemporal] = useState("Year");
    const [docActual, saveDocActual] = useState();
    
    useEffect(()=>{

        if( idPrin != "" && idPrin != undefined && idPrin != null ){
            loadData();
        }

        return () =>{                           
            source.cancel();
        } 
   
    }, [idPrin])


    const loadData = async() => {

        saveLoading(true);

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", idPrin );
        data.append("idHijo", idHijo );                       
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.historia.padre.controles.gant.getdata, data, source.token);

        if( typeof respuesta.data == "string" ){
            // Error api
            saveLoading(false)
            alertService.error(`${respuesta.data}`, {autoClose: false});
        }else{
            // Preparación de datos
            await prepareData(respuesta.data);
            // Guardamos los datos del documento que estamos consultando
            saveDocActual(respuesta.data.filter(d => d.id == idPrin)[0]);            
            saveLoading(false);
        }

    }

    const prepareData = (data) => {

        const typeTask = confPantalla.historia.padre.controles.gant.type ? confPantalla.historia.padre.controles.gant.type : "task";
        
        const newData = data.map((d, index) =>{
            let auxStart = d.start.split(' ');
            let auxEnd = d.end.split(' ');
            const progressColor = typeTask == "project" ? "#dc3545" : d.color;            
            let aux = {
                start: new Date(auxStart[0].split('-')[0], parseInt(auxStart[0].split('-')[1]-1), auxStart[0].split('-')[2], auxStart[1].split(':')[0], auxStart[1].split(':')[1], auxStart[1].split(':')[2]),
                end: new Date(auxEnd[0].split('-')[0], parseInt(auxEnd[0].split('-')[1]-1), auxEnd[0].split('-')[2], auxEnd[1].split(':')[0], auxEnd[1].split(':')[1], auxEnd[1].split(':')[2]),
                name: d.name,
                id: d.id,
                type: typeTask, // Opciones task(barra normal), milestone(rombo), project(barra con grafico de progreso)
                progress: d.progress,
                isDisabled: false,
                styles: { progressColor: progressColor, progressSelectedColor: progressColor },
                dependencies: d.dependencies
            }

            return aux
        })

        saveTasks(newData);        
    }

    const CustomTaskListHeader = () => {
        const columns = confPantalla.historia.padre.controles.gant.tableheader.map((header, index) => {
            if(confPantalla.historia.padre.controles.gant.tableheader.length == index+1){
                return ( 
                    <Fragment key={index}>
                        <div className="_WuQ0f pl-2 text-center" style={{minWidth: "400px"}}>{header.col.title}</div>                       
                    </Fragment>                
                );
            }else{
                return ( 
                    <Fragment key={index}>
                        <div className="_WuQ0f pl-2 text-center" style={{minWidth: "400px"}}>{header.col.title}</div>
                        <div className="_2eZzQ" style={{height: "25px", marginTop: "10px"}}></div>                        
                    </Fragment>                
                );
            }
        })
        return  <div className="_3_ygE" 
                     style={{
                             fontFamily: "Arial, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue",  
                             fontSize: "14px", 
                             borderRight: "#e6e4e4 1px solid",
                             width: "100%", 
                             color: "#555"
                }}>
                    <div className="_1nBOt" style={{height: "48px"}}>
                        {columns}
                    </div>
                </div>;
        
    };    

    const CustomTaskListTable = () => {            
        return (
                tasks.map((t, index) => {

                    let list;
                    let url = confPantalla.historia.padre.controles.gant.urlclicktask;
                    if(url == "/documentos" || url == "documentos"){
                        if( cookies.get('nivel') <= 2 ){
                            url = "/documentos";
                        }else{               
                            url = "/documentosenc";
                        }
                    }

                    if(confPantalla.historia.padre.controles.gant.tablelist){

                        const columns = confPantalla.historia.padre.controles.gant.tablelist.map((d, index) => {
                            // Si algún campo es una fecha la formateamos correctamente acorde con su idioma
                            let dato = t[d.data];                            
                            if(typeof t[d.data] == "object"){
                                dato = dateFormatByLocale(dato, cookies.get('idioma'))
                            }
                            if(d.data == "name"){
                                return( <div className="_3lLk3" key={index} title={dato} style={{minWidth: "155px", maxWidth: "155px", paddingLeft: "15px"}}>
                                            <a href={url+"/"+t.id}>{dato}</a>
                                        </div>); 
                            }else{
                                return(<div className="_3lLk3" key={index} title={dato} style={{minWidth: "155px", maxWidth: "155px", paddingLeft: "15px"}}>{dato}</div>); 
                            }
                        })

                        list =  <div className="_2B2zv" key={index}>
                                    <div className="_3ZbQT" 
                                         style={{
                                            fontFamily: "Arial, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue", 
                                            fontSize: "14px",
                                            width: "100%",
                                            color: "#555"
                                         }}
                                    >
                                        <div className="_34SS0" style={{height: "50px"}}>                                            
                                            {columns}
                                        </div>                                        
                                    </div>
                                </div>;    
                    }else{
                        list =  <div className="_2B2zv" key={index}>
                                    <div className="_3ZbQT" 
                                         style={{
                                            fontFamily: "Arial, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue", 
                                            fontSize: "14px",
                                            width: "100%",
                                            color: "#555"
                                         }}
                                    >
                                        <div className="_34SS0" style={{height: "50px"}}>                                            
                                            <div className="_3lLk3" title={t.name} style={{minWidth: "155px", maxWidth: "155px", paddingLeft: "15px"}}>
                                                <a href={url+"/"+t.id}>{t.name}</a>
                                            </div>
                                            <div className="_3lLk3" style={{minWidth: "155px", maxWidth: "155px", paddingLeft: "15px"}}>
                                                <a href={url+"/"+t.id}>{dateFormatByLocale(t.start, cookies.get('idioma'))}</a>
                                            </div>
                                            <div className="_3lLk3" style={{minWidth: "155px", maxWidth: "155px", paddingLeft: "15px"}}>
                                            <a href={url+"/"+t.id}>{dateFormatByLocale(t.end, cookies.get('idioma'))}</a>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>;                   
                    }

                    return(
                        list
                    )
                })
 
          );        
    };

    const CustomToolTip = (e) => {
        // const actualTask = tasks.filter(t=>t.id == e.task.id);
        // return  (
        //             <div style={{backgroundColor: "#fff", padding: "20px 15px", border: "1px solid", borderRadius: "5px", borderColor: "#6c757d !impotant"}}>
        //                 <h6>{actualTask[0].name}</h6>
        //             </div>
        //         )

        return null;
    }

    const handleChangeFranTemporal = (selectedItem, nameOfComponent) => {
        saveFranjaTemporal(selectedItem.value)
    }

    const handleClickTask = (task) => {
        let url = confPantalla.historia.padre.controles.gant.urlclicktask;
        if(url == "/documentos" || url == "documentos"){
            if( cookies.get('nivel') <= 2 ){
                url = "/documentos";
            }else{               
                url = "/documentosenc";
            }
        }
        if(!task.name.includes("DEL!!") && !task.name.includes("NOT FOUND!!")){
            window.open(url+"/"+task.id);
        }
    }

    let gant;
    if( insideSection === false){
        gant = <section className="container-fluid">
                    {
                        loading 
                        ?
                            // <Section titulo={ "" } idPrin={ formulario.id }>
                                <div className="text-center">
                                    <Spinner animation="border" />
                                </div>
                            // </Section>
                        :
                        tasks != null && tasks.length > 0 
                        ?
                            // <Section titulo={ docActual.name } idPrin={ formulario.id }>
                            <Fragment>
                                <MiSelect  
                                    estadoForm=  { "" }
                                    val=         { franjaTemporal  }   
                                    loading=     { loading ? true : false }
                                    id=          { confPantalla.historia.padre.controles.gant.select.id }    
                                    name=        { confPantalla.historia.padre.controles.gant.select.name }   
                                    label=       { confPantalla.historia.padre.controles.gant.select.label }    
                                    placeholder= { confPantalla.historia.padre.controles.gant.select.placeholder }
                                    col=         { confPantalla.historia.padre.controles.gant.select.col } 
                                    isClearable= { confPantalla.historia.padre.controles.gant.select.isclearable }
                                    isSearchable={ confPantalla.historia.padre.controles.gant.select.issearchable }
                                    dataType=    { confPantalla.historia.padre.controles.gant.select.datatype }
                                    data=        { confPantalla.historia.padre.controles.gant.select.data }
                                    required=    { confPantalla.historia.padre.controles.gant.select.required } 
                                    disabled=    { confPantalla.historia.padre.controles.gant.select.disabled }
                                    readOnly =   { confPantalla.historia.padre.controles.gant.select.readonly }
                                    disablethisid={confPantalla.historia.padre.controles.gant.select.disablethisid}
                                    formulario= { {id:0} }   
                                    change= { handleChangeFranTemporal }
                                />
                                <Gantt 
                                    tasks={tasks} 
                                    locale={cookies.get('idioma') == 'en' ? 'en' : 'es'}
                                    TaskListHeader={CustomTaskListHeader}
                                    TaskListTable={CustomTaskListTable}
                                    TooltipContent={e => CustomToolTip(e)}
                                    viewMode={franjaTemporal}
                                    onClick={e => handleClickTask(e)} 
                                    preStepsCount={1}  
                                    columnWidth={95}
                                />
                            </Fragment>
                            // </Section>
                        :
                            // <Section titulo={ "" } idPrin={ formulario.id }>
                                <div className='text-center mt-4 mb-4'>
                                    <i className="fal fa-empty-set fa-6x" style={{color: "#dc3545"}}></i>                                                                
                                </div>
                            // </Section>
                    }
                </section>
    }else{
        gant = <section className="container-fluid">
                    {
                        loading 
                        ?
                            <Section titulo={ "" } idPrin={ formulario.id }>
                                <div className="text-center">
                                    <Spinner animation="border" />
                                </div>
                            </Section>
                        :
                        tasks != null && tasks.length > 0 
                        ?
                            <Section titulo={ docActual.name } idPrin={ formulario.id }>
                                <MiSelect  
                                    estadoForm=  { "" }
                                    val=         { franjaTemporal  }   
                                    loading=     { loading ? true : false }
                                    id=          { confPantalla.historia.padre.controles.gant.select.id }    
                                    name=        { confPantalla.historia.padre.controles.gant.select.name }   
                                    label=       { confPantalla.historia.padre.controles.gant.select.label }    
                                    placeholder= { confPantalla.historia.padre.controles.gant.select.placeholder }
                                    col=         { confPantalla.historia.padre.controles.gant.select.col } 
                                    isClearable= { confPantalla.historia.padre.controles.gant.select.isclearable }
                                    isSearchable={ confPantalla.historia.padre.controles.gant.select.issearchable }
                                    dataType=    { confPantalla.historia.padre.controles.gant.select.datatype }
                                    data=        { confPantalla.historia.padre.controles.gant.select.data }
                                    required=    { confPantalla.historia.padre.controles.gant.select.required } 
                                    disabled=    { confPantalla.historia.padre.controles.gant.select.disabled }
                                    readOnly =   { confPantalla.historia.padre.controles.gant.select.readonly }
                                    disablethisid={confPantalla.historia.padre.controles.gant.select.disablethisid}
                                    formulario= { {id:0} }   
                                    change= { handleChangeFranTemporal }
                                />
                                <Gantt 
                                    tasks={tasks} 
                                    locale={cookies.get('idioma') == 'en' ? 'en' : 'es'}
                                    TaskListHeader={CustomTaskListHeader}
                                    TaskListTable={CustomTaskListTable}
                                    TooltipContent={e => CustomToolTip(e)}
                                    viewMode={franjaTemporal}
                                    onClick={e => handleClickTask(e)} 
                                    preStepsCount={1}  
                                    columnWidth={95}
                                />
                            </Section>
                        :
                            <Section titulo={ "" } idPrin={ formulario.id }>
                                <div className='text-center mt-4 mb-4'>
                                    <i className="fal fa-empty-set fa-6x" style={{color: "#dc3545"}}></i>                                                                
                                </div>
                            </Section>
                    }
                </section>
    }

    return(
        gant
        // <section className="container-fluid">
        //     {
        //         loading 
        //         ?
        //             <Section titulo={ "" } idPrin={ formulario.id }>
        //                 <div className="text-center">
        //                     <Spinner animation="border" />
        //                 </div>
        //             </Section>
        //         :
        //         tasks != null && tasks.length > 0 
        //         ?
        //             <Section titulo={ docActual.name } idPrin={ formulario.id }>
        //                 <MiSelect  
        //                     estadoForm=  { "" }
        //                     val=         { franjaTemporal  }   
        //                     loading=     { loading ? true : false }
        //                     id=          { confPantalla.historia.padre.controles.gant.select.id }    
        //                     name=        { confPantalla.historia.padre.controles.gant.select.name }   
        //                     label=       { confPantalla.historia.padre.controles.gant.select.label }    
        //                     placeholder= { confPantalla.historia.padre.controles.gant.select.placeholder }
        //                     col=         { confPantalla.historia.padre.controles.gant.select.col } 
        //                     isClearable= { confPantalla.historia.padre.controles.gant.select.isclearable }
        //                     isSearchable={ confPantalla.historia.padre.controles.gant.select.issearchable }
        //                     dataType=    { confPantalla.historia.padre.controles.gant.select.datatype }
        //                     data=        { confPantalla.historia.padre.controles.gant.select.data }
        //                     required=    { confPantalla.historia.padre.controles.gant.select.required } 
        //                     disabled=    { confPantalla.historia.padre.controles.gant.select.disabled }
        //                     readOnly =   { confPantalla.historia.padre.controles.gant.select.readonly }
        //                     disablethisid={confPantalla.historia.padre.controles.gant.select.disablethisid}
        //                     formulario= { {id:0} }   
        //                     change= { handleChangeFranTemporal }
        //                 />
        //                 <Gantt 
        //                     tasks={tasks} 
        //                     locale={cookies.get('idioma') == 'en' ? 'en' : 'es'}
        //                     TaskListHeader={CustomTaskListHeader}
        //                     TaskListTable={CustomTaskListTable}
        //                     TooltipContent={e => CustomToolTip(e)}
        //                     viewMode={franjaTemporal}
        //                     onClick={e => handleClickTask(e)} 
        //                     preStepsCount={1}  
        //                     columnWidth={95}
        //                 />
        //             </Section>
        //         :
        //             <Section titulo={ "" } idPrin={ formulario.id }>
        //                 <div className='text-center mt-4 mb-4'>
        //                     <i className="fal fa-empty-set fa-6x" style={{color: "#dc3545"}}></i>                                                                
        //                 </div>
        //             </Section>
        //     }
        // </section>
    )
}

export default Gant;