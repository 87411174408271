import React, { useEffect, useContext, useState } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net';
import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Kpi from '../../componentes/ui/Kpi';
import Button from '../../componentes/ui/Button';
import { Spinner } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import MiSelect from '../../componentes/formulario/MiSelect';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';
import FormInput from '../../componentes/formulario/FormInput';
import FormRow from '../../componentes/formulario/FormRow';
import { Accordion, Card } from "react-bootstrap";
import MiDataTableExecPr from './MiDataTableExecPr';


import { alertService } from '../../services/alertservice';
import { Alert } from '../../componentes/ui/Alert';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, axiosPost, getPantDocFromNivel, getAhorayyyymmdd, MiReplaceAll, getTitle, stringToHtml } from '../../helpers/helperApp';

import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';


$.DataTable = DataTable;

let reordenandoLineas = false;

function ExecPr(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();
    const source4 = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey, centroActivo } = useContext(LoginContext);   
    
    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);    
    const [ acordeonPlantillas, saveAcordeonPlantillas ] = useState(null);
    // Selects
    const [ selectPlanning, saveSelectPlanning ] = useState(null);
    const [ loadingSelectPlanning, saveLoadingSelectPlanning ] = useState(false);
    const [ selectPoe, saveSelectPoe ] = useState(null);
    const [ loadingSelectPoe, saveLoadingSelectPoe ] = useState(false);
    const [ selectAgente, saveSelectAgente ] = useState(null);
    const [ loadingSelectAgente, saveLoadingSelectAgente ] = useState(false);
    const [ forceReloadPoe, saveForceReloadPoe ] = useState(0);
    const [ forceReloadAgente, saveForceReloadAgente ] = useState(0);    
    
    // Tablas
    const [ datosTabla, saveDatosTabla ] = useState([]);    
    const [ loadingTabla, saveLoadingTabla ] = useState(false);
       



    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.execpr.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        if( props.location.state ){
                            let auxPlanning = null;
                            let auxPoe = null;
                            let auxAgente = null;                            
                            if( "planning" in props.location.state  ){
                                saveSelectPlanning(props.location.state.planning);
                                auxPlanning = props.location.state.planning;                
                            }
                            if( "poe" in props.location.state  ){
                                saveSelectPoe(props.location.state.poe); 
                                saveForceReloadPoe(forceReloadPoe => forceReloadPoe+1);
                                auxPoe = props.location.state.poe;
                            }
                            if( "agente" in props.location.state  ){
                                saveSelectAgente(props.location.state.agente);
                                saveForceReloadAgente(forceReloadAgente => forceReloadAgente+1);
                                auxAgente = props.location.state.agente;
                            }                              
                            getDatosTabla(null, null, null, auxPlanning, auxPoe, auxAgente);
                        }                                                                                                                              
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
                source2.cancel();
                source3.cancel();
                source4.cancel();
        } 

    },[confPantalla, formularioHijo.id, centroActivo])

    const handleChangePlanning = (selectedItem, nameOfComponent) => {
        saveDatosTabla([]);
        if( selectedItem ){
            saveSelectPlanning(selectedItem.value);
        }else{
            saveSelectPlanning(null);
            saveSelectPoe(null);
            saveSelectAgente(null);
        }
        saveForceReloadPoe(forceReloadPoe => forceReloadPoe+1);
        saveForceReloadAgente(forceReloadAgente => forceReloadAgente+1);
    }

    const handleChangePoe = (selectedItem, nameOfComponent) => {
        saveDatosTabla([]);
        if( selectedItem ){
            saveSelectPoe(selectedItem.value);
        }else{
            saveSelectPoe(null);
            saveSelectAgente(null);
        }
        saveForceReloadAgente(forceReloadAgente => forceReloadAgente+1);
    }

    const handleChangeAgente = (selectedItem, nameOfComponent) => {
        saveDatosTabla([]);
        if( selectedItem ){
            saveSelectAgente(selectedItem.value);
        }else{
            saveSelectAgente(null);
        }        
    }


    const getDatosTabla = async(opcionTabla=null, selectPlanningToPoe=null, selectPoeToAgente=null, paramPlanning=null, paramPoe=null, paramAgente=null) => {
        saveLoadingTabla(true);

        const data = new FormData();
        const dataPoe = selectPlanningToPoe != null ? selectPlanningToPoe : selectPoe;
        const dataAgente = selectPoeToAgente != null ? selectPoeToAgente : selectAgente;
        data.append("validacion", apiKey());
        data.append("planning", paramPlanning != null ? paramPlanning : selectPlanning );
        data.append("poe", paramPoe != null ? paramPoe : dataPoe );
        data.append("agente", paramAgente != null ? paramAgente : dataAgente );

        const respuesta = await axiosPost(getApiUrl(window.location.origin)+ confPantalla.execpr.controles.tablas.getdata, data, source.token);
        
        if( typeof respuesta.data == "string" ){
            alertService.error(`<i class="fal fa-check-circle mr-1"></i>${respuesta.data}`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            saveDatosTabla(respuesta.data);   
        }

        saveLoadingTabla(false);
    }

    const selectNextCbo = async (e, rowData, id) => {
        if( id =="tabla1" ) {            
            await getDatosTabla(null, rowData.id); 
            await saveSelectPoe(rowData.id);
        }else if( id == "tabla2"){
            await getDatosTabla(null, null, rowData.id_age); 
            await saveSelectAgente(rowData.id_age);
        }        
    }


    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Alert fondo={true} />
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">                
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                        {
                        loadedConf === true && confPantalla !== undefined
                        ?
                            <Section titulo={ confPantalla.execpr.section.titulo } icono={ confPantalla.execpr.section.icono } col={ confPantalla.execpr.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.execpr.section.wiki } >
                                <FormRow>

                                    <MiSelect  
                                        estadoForm=  { "" }
                                        val=         { selectPlanning }   
                                        loading=     { loadingSelectPlanning ? true : false }
                                        id=          { confPantalla.execpr.controles.planning.id }    
                                        name=        { confPantalla.execpr.controles.planning.name }   
                                        label=       { confPantalla.execpr.controles.planning.label }    
                                        placeholder= { confPantalla.execpr.controles.planning.placeholder }
                                        col=         { confPantalla.execpr.controles.planning.col } 
                                        isClearable= { confPantalla.execpr.controles.planning.isclearable }
                                        isSearchable={ confPantalla.execpr.controles.planning.issearchable }
                                        dataType=    { confPantalla.execpr.controles.planning.datatype }
                                        data=        { confPantalla.execpr.controles.planning.data }
                                        required=    { confPantalla.execpr.controles.planning.required } 
                                        disabled=    { confPantalla.execpr.controles.planning.disabled }
                                        readOnly =   { confPantalla.execpr.controles.planning.readonly }
                                        disablethisid={confPantalla.execpr.controles.planning.disablethisid}
                                        formulario= { {id:0} }   
                                        change= { handleChangePlanning }
                                    />
                                    <MiSelect  
                                        estadoForm=  { "" }
                                        val=         { selectPoe }   
                                        loading=     { loadingSelectPoe ? true : false }
                                        id=          { "POE************************"}    
                                        name=        { confPantalla.execpr.controles.poe.name }   
                                        label=       { confPantalla.execpr.controles.poe.label }    
                                        placeholder= { confPantalla.execpr.controles.poe.placeholder }
                                        col=         { confPantalla.execpr.controles.poe.col } 
                                        isClearable= { confPantalla.execpr.controles.poe.isclearable }
                                        isSearchable={ confPantalla.execpr.controles.poe.issearchable }
                                        dataType=    { confPantalla.execpr.controles.poe.datatype }
                                        data=        { confPantalla.execpr.controles.poe.data }
                                        required=    { confPantalla.execpr.controles.poe.required } 
                                        disabled=    { confPantalla.execpr.controles.poe.disabled }
                                        readOnly =   { confPantalla.execpr.controles.poe.readonly }
                                        disablethisid={confPantalla.execpr.controles.poe.disablethisid}
                                        formulario= { {id:forceReloadPoe} }   
                                        change= { handleChangePoe }
                                        loadOnInit={forceReloadPoe > 0 ? true : false}
                                        appendPost={[{key: "planning", value: selectPlanning}, {key: "poe", value: selectPoe}]}
                                    />
                                    <MiSelect  
                                        estadoForm=  { "" }
                                        val=         { selectAgente }   
                                        loading=     { loadingSelectAgente ? true : false }
                                        id=          { confPantalla.execpr.controles.agente.id }    
                                        name=        { confPantalla.execpr.controles.agente.name }   
                                        label=       { confPantalla.execpr.controles.agente.label }    
                                        placeholder= { confPantalla.execpr.controles.agente.placeholder }
                                        col=         { confPantalla.execpr.controles.agente.col } 
                                        isClearable= { confPantalla.execpr.controles.agente.isclearable }
                                        isSearchable={ confPantalla.execpr.controles.agente.issearchable }
                                        dataType=    { confPantalla.execpr.controles.agente.datatype }
                                        data=        { confPantalla.execpr.controles.agente.data }
                                        required=    { confPantalla.execpr.controles.agente.required } 
                                        disabled=    { confPantalla.execpr.controles.agente.disabled }
                                        readOnly =   { confPantalla.execpr.controles.agente.readonly }
                                        disablethisid={confPantalla.execpr.controles.agente.disablethisid}
                                        formulario= { {id:forceReloadAgente} }   
                                        change= { handleChangeAgente }
                                        // loadOnInit={forceReloadAgente > 0 ? true : false}
                                        appendPost={[{key: "planning", value: selectPlanning}, {key: "poe", value: selectPoe}]}
                                    />
                                </FormRow>
                                {
                                    selectPlanning != null && selectPoe == null && selectAgente == null ?
                                        <Fragment>
                                            <Button 
                                                clases={ "col-12 mt-2" }
                                                color={ "primary" }  
                                                type="button" 
                                                icono={ "fal fa-search" }   
                                                id="btngetdatostabla"     
                                                texto={ "" }   
                                                accion={ () => getDatosTabla(1) } 
                                                disabled={ false }
                                            />
                                            <MiDataTableExecPr 
                                                estadoForm= { "" }
                                                formulario={ {id:0} }
                                                id={"tabla1"}
                                                columnas={confPantalla.execpr.controles.tablas.tabla1.columns}
                                                data={datosTabla}
                                                saveData={saveDatosTabla}
                                                buttons={confPantalla.execpr.controles.tablas.tabla1.buttons}
                                                ordering={confPantalla.execpr.controles.tablas.tabla1.ordering}
                                                order={confPantalla.execpr.controles.tablas.tabla1.order}
                                                searching={confPantalla.execpr.controles.tablas.tabla1.searching}
                                                paging={confPantalla.execpr.controles.tablas.tabla1.paging}
                                                select={confPantalla.execpr.controles.tablas.tabla1.select}
                                                pagelength={confPantalla.execpr.controles.tablas.tabla1.pagelength}                                                                
                                                columnactions={confPantalla.execpr.controles.tablas.tabla1.columnactions.title}
                                                linkColumns={confPantalla.execpr.controles.tablas.tabla1.linkColumns}
                                                traduction={confPantalla.config.trad.components.datatable}                                                    
                                                confPantalla={confPantalla}
                                                saveLoading={saveLoadingTabla}
                                                loadingTable={loadingTabla}
                                                propiedades={props}
                                                selectNextCbo={selectNextCbo}                                                
                                            />
                                        </Fragment>
                                    :
                                        null
                                }
                                {
                                    selectPlanning != null && selectPoe != null && selectAgente == null ?
                                        <Fragment>
                                            <Button 
                                                clases={ "col-12 mt-2" }
                                                color={ "primary" }  
                                                type="button" 
                                                icono={ "fal fa-search" }   
                                                id="btngetdatostabla"     
                                                texto={ "" }   
                                                accion={ () => getDatosTabla(2) } 
                                                disabled={ false }
                                            />
                                            <MiDataTableExecPr 
                                                estadoForm= { "" }
                                                formulario={ {id:0} }
                                                id={"tabla2"}
                                                columnas={confPantalla.execpr.controles.tablas.tabla2.columns}
                                                data={datosTabla}
                                                saveData={saveDatosTabla}
                                                buttons={confPantalla.execpr.controles.tablas.tabla2.buttons}
                                                ordering={confPantalla.execpr.controles.tablas.tabla2.ordering}
                                                order={confPantalla.execpr.controles.tablas.tabla2.order}
                                                searching={confPantalla.execpr.controles.tablas.tabla2.searching}
                                                paging={confPantalla.execpr.controles.tablas.tabla2.paging}
                                                select={confPantalla.execpr.controles.tablas.tabla2.select}
                                                pagelength={confPantalla.execpr.controles.tablas.tabla2.pagelength}                                                                
                                                columnactions={confPantalla.execpr.controles.tablas.tabla2.columnactions.title}
                                                linkColumns={confPantalla.execpr.controles.tablas.tabla2.linkColumns}
                                                traduction={confPantalla.config.trad.components.datatable}                                                    
                                                confPantalla={confPantalla}
                                                saveLoading={saveLoadingTabla}
                                                loadingTable={loadingTabla}
                                                propiedades={props}
                                                selectNextCbo={selectNextCbo}                                                
                                            />
                                        </Fragment>
                                    :
                                        null
                                }
                                {
                                    selectPlanning != null && selectPoe != null && selectAgente != null ?
                                        <Fragment>
                                            <Button 
                                                clases={ "col-12 mt-2" }
                                                color={ "primary" }  
                                                type="button" 
                                                icono={ "fal fa-search" }   
                                                id="btngetdatostabla"     
                                                texto={ "" }   
                                                accion={ () => getDatosTabla(3) } 
                                                disabled={ false }
                                            />
                                            <MiDataTableExecPr 
                                                estadoForm= { "" }
                                                formulario={ {id:0} }
                                                id={"tabla3"}
                                                columnas={confPantalla.execpr.controles.tablas.tabla3.columns}
                                                data={datosTabla}
                                                saveData={saveDatosTabla}
                                                buttons={confPantalla.execpr.controles.tablas.tabla3.buttons}
                                                ordering={confPantalla.execpr.controles.tablas.tabla3.ordering}
                                                order={confPantalla.execpr.controles.tablas.tabla3.order}
                                                searching={confPantalla.execpr.controles.tablas.tabla3.searching}
                                                paging={confPantalla.execpr.controles.tablas.tabla3.paging}
                                                select={confPantalla.execpr.controles.tablas.tabla3.select}
                                                pagelength={confPantalla.execpr.controles.tablas.tabla3.pagelength}                                                                
                                                columnactions={confPantalla.execpr.controles.tablas.tabla3.columnactions.title}
                                                linkColumns={confPantalla.execpr.controles.tablas.tabla3.linkColumns}
                                                traduction={confPantalla.config.trad.components.datatable}                                                    
                                                confPantalla={confPantalla}
                                                saveLoading={saveLoadingTabla}
                                                loadingTable={loadingTabla}
                                                propiedades={props}
                                                selectNextCbo={selectNextCbo}                                                
                                            />
                                        </Fragment>
                                    :
                                        null
                                }
                            </Section>
                        :
                            null
                        }
                    </Fragment>
                }
                
            </section>                                    
        </div>
        <Footer />        
        </div>

    );

}
export default ExecPr