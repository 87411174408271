import React, { useEffect, useState, useContext, Fragment } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';

import Spinner from 'react-bootstrap/Spinner'

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getTitle } from '../../helpers/helperApp';

function LoginNvl(props){
    
    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, saveFormularioHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleDeleteItemPrin, handleSubmitPrin, resetFormPrin } = useLogicForm();
    

    useEffect( () => {

        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
        .then( (respuesta) => {
            if( confPantalla === undefined ){
                return getConfApp();
            }else{
                document.title = getTitle(confPantalla.config.trad, confPantalla.loginnvl.title);                
                if( respuesta == 1 ){
                    saveTieneNivel(true);
                    saveEstadoFormPrin(confPantalla.loginnvl.padre.estadoform);
                    saveEstadoFormHijo(confPantalla.loginnvl.hijo.estadoform);                            
                    // ***** Carga inicial de registros *****
                    if( registrosPrin ){
                        if( registrosPrin.length === 0 ){
                            saveLoadingTable(true);
                            regIniciales(saveRegistrosPrin, confPantalla.loginnvl.padre.urls);
                            saveFormularioPrin(confPantalla.loginnvl.padre.modelform);
                            saveFormularioHijo(confPantalla.loginnvl.hijo.modelform);                                
                        }
                    }  
                    // Seleccionar el id que nos llega como parámetro por la url
                    if( props.location.state ){
                        if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                            handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.loginnvl.padre.urls, confPantalla.loginnvl.hijo.urls, confPantalla.loginnvl.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                        }                        
                    }                      
                }
                else if( respuesta == 0 ){
                    saveTieneNivel(false);
                }                
                saveLoadedConf(true);        
            }
        })
        .then( (respuesta) => {
            if( respuesta !== undefined  ){
                saveConfPantalla(respuesta.msg);
                saveConfApp(respuesta.msg);             
            }
        })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
            document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])
   
    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>     
            {
                confPantalla !== undefined
                ?
                    <Fragment>
                        <Menusup 
                            props = { props }
                        />
                        <Sidebar 
                            props = { props }
                        />
                    </Fragment>
                :
                null
            } 
            <div className="content-wrapper">
                <div className="content-header">
                </div>
                <section className="content">
                {
                        !tieneNivel 
                        ?
                            <PermissionRefused
                                traduction={confPantalla.config.trad.components.permissionrefused} 
                            />
                        :
                        <Fragment>
                            {
                                loadedConf === true && confPantalla !== undefined
                                ?
                                <Section titulo={ confPantalla.loginnvl.section.titulo } icono={ confPantalla.loginnvl.section.icono } col={ confPantalla.loginnvl.section.col } wiki={ confPantalla.loginnvl.section.wiki } >
                                <Formulario 
                                            estadoForm= { estadoFormPrin }
                                            loading=    { loadReg ? true : false }
                                            btnNew={ confPantalla.loginnvl.padre.btnform.new }
                                            btnSave={ confPantalla.loginnvl.padre.btnform.save }
                                            btnSaveAndNew={ confPantalla.loginnvl.padre.btnform.saveandnew }
                                            btnDelete={ confPantalla.loginnvl.padre.btnform.delete }
                                            btnClone={ confPantalla.loginnvl.padre.btnform.clone }
                                            handleSubmit = { e => handleSubmitPrin(e, confPantalla.loginnvl.padre.urls, confPantalla.loginnvl.padre.modelform, confPantalla.loginnvl.padre.tabla, confPantalla.loginnvl.hijo.modelform, confPantalla.loginnvl.title) } 
                                            formulario = { formularioPrin } 
                                            resetForm = { e => resetFormPrin(confPantalla.loginnvl.padre.modelform, true, confPantalla.loginnvl.padre.tabla) } 
                                            handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.loginnvl.padre.urls, confPantalla.loginnvl.padre.modelform, confPantalla.loginnvl.padre.tabla, confPantalla.loginnvl.title) } 
                                >
                                    <FormRow clases="" >
                                        <FormInput 
                                            estadoForm= { estadoFormPrin }
                                            value=      { formularioPrin.nombre }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.loginnvl.padre.controles.nombre.id }   
                                            type=       { confPantalla.loginnvl.padre.controles.nombre.type }  
                                            name=       { confPantalla.loginnvl.padre.controles.nombre.name }   
                                            label=      { confPantalla.loginnvl.padre.controles.nombre.label }                                                       
                                            placeholder={ confPantalla.loginnvl.padre.controles.nombre.placeholder }    
                                            col=        { confPantalla.loginnvl.padre.controles.nombre.col } 
                                            required=   { confPantalla.loginnvl.padre.controles.nombre.required }
                                            readonly=   { confPantalla.loginnvl.padre.controles.nombre.readonly }                                                
                                            disabled=   { confPantalla.loginnvl.padre.controles.nombre.disabled }
                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                            onBlur=     {null} 
                                        />
                                        <FormInput                                    
                                            estadoForm= { estadoFormPrin }
                                            value=      { formularioPrin.orden }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.loginnvl.padre.controles.orden.id }   
                                            type=       { confPantalla.loginnvl.padre.controles.orden.type }  
                                            name=       { confPantalla.loginnvl.padre.controles.orden.name }   
                                            label=      { confPantalla.loginnvl.padre.controles.orden.label }                                                       
                                            placeholder={ confPantalla.loginnvl.padre.controles.orden.placeholder }    
                                            col=        { confPantalla.loginnvl.padre.controles.orden.col } 
                                            required=   { confPantalla.loginnvl.padre.controles.orden.required }
                                            readonly=   { confPantalla.loginnvl.padre.controles.orden.readonly }                                                
                                            disabled=   { confPantalla.loginnvl.padre.controles.orden.disabled }
                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                            onBlur=     {null}
                                        />
                                    </FormRow>
                                </Formulario>
                                <MiDataTable
                                    formulario={formularioPrin} 
                                    estadoForm= { estadoFormPrin }
                                    id={"loginnvl"}
                                    columnas={confPantalla.loginnvl.padre.datatable.columns}
                                    data={registrosPrin}
                                    buttons={confPantalla.loginnvl.padre.datatable.buttons}
                                    ordering={confPantalla.loginnvl.padre.datatable.ordering}
                                    order={confPantalla.loginnvl.padre.datatable.order}
                                    searching={confPantalla.loginnvl.padre.datatable.searching}
                                    paging={confPantalla.loginnvl.padre.datatable.paging}
                                    select={confPantalla.loginnvl.padre.datatable.select}
                                    pagelength={confPantalla.loginnvl.padre.datatable.pagelength}
                                    handleEditReg= { e => handleEditRegPrin(e, confPantalla.loginnvl.padre.urls, confPantalla.loginnvl.hijo.urls, confPantalla.loginnvl.padreshijos, confPantalla.loginnvl.hijo.modelform, confPantalla.loginnvl.padre.tabla) }
                                    handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.loginnvl.padre.urls, confPantalla.loginnvl.padre.modelform, confPantalla.loginnvl.padre.tabla, confPantalla.loginnvl.title) }
                                    saveLoadingTable={ saveLoadingTable }
                                    loadingTable={loadingTable}
                                    columnactions={confPantalla.loginnvl.padre.datatable.columnactions.title}
                                    traduction={confPantalla.config.trad.components.datatable}
                                /> 
                                </Section>                                     
                                :
                                <LoadingPage />
                            }
                        </Fragment>
                }
                </section>
            </div>
            <Footer />        
        </div>
    );

}
export default LoginNvl