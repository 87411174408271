import React, { useEffect, useContext, useState, Fragment } from 'react';
import axios from 'axios';
import $ from 'jquery';
import DataTable from 'datatables.net';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import LoadingPage from '../../componentes/ui/LoadingPage';
import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Kpi from '../../componentes/ui/Kpi';
import FormInput from '../../componentes/formulario/FormInput';
import Section from '../../componentes/ui/Section';
import { Alert } from '../../componentes/ui/Alert';
import { Accordion, Card } from "react-bootstrap";
import MiDataTable from '../../componentes/ui/MiDataTable';
import CalendarioYear from '../../componentes/ui/CalendarioYear';
import swal from 'sweetalert';
import { alertService } from '../../services/alertservice';
import Modal from 'react-bootstrap/Modal';
import MiSelect from '../../componentes/formulario/MiSelect';
import Informes from '../../componentes/formulario/informes/Informes';

import { getAhorayyyymmdd, getTitle } from '../../helpers/helperApp';
import FormRow from '../../componentes/formulario/FormRow';

import { getApiUrl, getLinkReadOnly, axiosPost, getNumFromData } from '../../helpers/helperApp';

function CheckListMa(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Context *****
    const { validateSesion, confApp, saveConfApp, getConfApp, apiKey, linkReadOnly, respSimp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ loadingTable, saveLoadingTable] = useState(true);
    const [ showModal, saveShowModal ] = useState(false);
    const [ valTrabajador, saveValTrabajador ] = useState(0);
    const [ valFec2, saveValFec2 ] = useState(getAhorayyyymmdd());
    const [ descripcionDocu, saveDescripcionDocu ] = useState("");
    const [ idPlantilla, saveIdPlantilla ] = useState(0);
    const [ reloadKpi, saveReloadKpi ] = useState(0);
    const [ tieneNivel, saveTieneNivel ] = useState(true);


    const [ datosTablePlantillas, saveDatosTablePlantillas ] = useState([]);
    const [ valCalendarPlantillas, saveValCalendarPlantillas ] = useState([
        {
            id: "",
            name: "", 
            startDate : "", 
            endDate : "", 
        }
    ]);
    const [ datosTableHojas, saveDatosTableHojas ] = useState([]);
    const [ valCalendarHojas, saveValCalendarHojas ] = useState([
        {
            id: "",
            name: "", 
            startDate : "", 
            endDate : "", 
        }
    ]); 
    const [ acordeonPrin, saveAcordeonPrin ] = useState(null);
    const [ acordeonCalPlantillas, saveAcordeonCalPlantillas ] = useState(null);
    const [ acordeonCalHojas, saveAcordeonCalHojas ] = useState(null);  
    const [ valFilter, saveValFilter ] = useState();


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.checklistma.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        // Seleccionar los parámetros que nos llegan por la url
                        if( props.location.state ){
                            if( "filter" in props.location.state ){
                                saveValFilter(props.location.state.filter);                                    
                            }                        
                        }                                                                               
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                                    
                    saveLoadedConf(true);  
                    if( cookies.get('defaultActiveKeyCheckList') ){
                        toogleAcordeon(cookies.get('defaultActiveKeyCheckList'), "acordeonPrin")
                    }      
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          
        
        return () =>{
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
        } 

    },[confPantalla])

    const toogleAcordeon = (id, acordeon) => {
        switch(acordeon){
            case "acordeonPrin":
                if( acordeonPrin == id ){
                    saveAcordeonPrin(null);
                    cookies.set('defaultActiveKey', null, {path: '/'});
                }else{
                    saveAcordeonPrin(id);
                    cookies.set('defaultActiveKey', id, {path: '/'});
                }                
                break;
            case "acordeonCalPlantillas":
                if( acordeonCalPlantillas == id ){
                    saveAcordeonCalPlantillas(null);
                }else{
                    saveAcordeonCalPlantillas(id);
                }                
                break;
            case "acordeonCalHojas":
                if( acordeonCalHojas == id ){
                    saveAcordeonCalHojas(null);
                }else{
                    saveAcordeonCalHojas(id);
                }                
                break;
        }
    }

    const clickHoja = (e, rowData) => {

        let style, id, id_ori, descripcion;

        if( rowData ){
            style = rowData.style;
            id = rowData.id;
            id_ori = rowData.id_ori;
            descripcion = rowData.equipo+" - "+rowData.descripcion;  
        }else{
            style = e.target.dataset.style;
            id = e.target.dataset.iddoc;
            id_ori = e.target.dataset.id_ori;
            descripcion = e.target.dataset.title;          
        }


        switch (style) {

            case "alert-success":
                swal({
                    title: confPantalla.checklistma.controles.hojas.hoja.swal.title,
                    text: confPantalla.checklistma.controles.hojas.hoja.swal.text.title,
                    content: {
                        element : "h6",
                        attributes : {
                            innerHTML : descripcion
                        }
                    },
                    icon: "warning",
                    buttons: {
                        cancel : {text: confPantalla.checklistma.controles.hojas.hoja.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                        confirm : {text: confPantalla.checklistma.controles.hojas.hoja.swal.btnverhoja.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                        verplant : {text: confPantalla.checklistma.controles.hojas.hoja.swal.btnverplant.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                    } 
                }).then(confirmacion => {
                    if( confirmacion == true ){
                        // Ver la hoja
                        if( cookies.get('nivel') <= 2 ){
                            // Los administradores a su propia pantalla en modo edición
                            props.history.push("/documentos/"+id);
                        }else{
                            // El encargado a su propia pantalla solo lectura
                            props.history.push("/documentosenc/"+id+getLinkReadOnly(linkReadOnly));
                        }
                    }else if( confirmacion == "verplant" ){
                        // Ver la plantilla
                        if( cookies.get('nivel') <= 2 ){
                            // Los administradores a su propia pantalla en modo edición
                            window.open("/documentos/"+id_ori);
                        }else{
                            // El encargado a su propia pantalla solo lectura
                            window.open("/documentosenc/"+id_ori+getLinkReadOnly(linkReadOnly));
                        }
                    }
                })     
                break
            case "alert-danger":
                swal({
                    title: confPantalla.checklistma.controles.hojas.hoja.swal.title,
                    text: confPantalla.checklistma.controles.hojas.hoja.swal.text.title,
                    content: {
                        element : "h6",
                        attributes : {
                            innerHTML : descripcion
                        }
                    },
                    icon: "warning",
                    buttons: {
                        cancel : {text: confPantalla.checklistma.controles.hojas.hoja.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                        confirm : {text: confPantalla.checklistma.controles.hojas.hoja.swal.btnverhoja.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                        verplant : {text: confPantalla.checklistma.controles.hojas.hoja.swal.btnverplant.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                    } 
                }).then(confirmacion => {
                    if( confirmacion == true ){
                        // Ver la hoja
                        if( cookies.get('nivel') <= 2 ){
                            // Los administradores a su propia pantalla en modo edición
                            props.history.push("/documentos/"+id);
                        }else{
                            // El encargado a su propia pantalla solo lectura
                            props.history.push("/documentosenc/"+id);
                        }
                    }else if( confirmacion == "verplant" ){
                        // Ver la plantilla
                        if( cookies.get('nivel') <= 2 ){
                            // Los administradores a su propia pantalla en modo edición
                            window.open("/documentos/"+id_ori);
                        }else{
                            // El encargado a su propia pantalla solo lectura
                            window.open("/documentosenc/"+id_ori+getLinkReadOnly(linkReadOnly));
                        }
                    }
                })     
                break
            case "alert-primary":
                swal({
                    title: confPantalla.checklistma.controles.hojas.hoja.swal.title,
                    text: confPantalla.checklistma.controles.hojas.hoja.swal.text.title,
                    content: {
                        element : "h6",
                        attributes : {
                            innerHTML : descripcion
                        }
                    },
                    icon: "warning",
                    buttons: {
                        cancel : {text: confPantalla.checklistma.controles.hojas.hoja.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                        confirm : {text: confPantalla.checklistma.controles.hojas.hoja.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                        verplant : {text: confPantalla.checklistma.controles.hojas.hoja.swal.btnverplant.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                    } 
                }).then(confirmacion => {
                    if( confirmacion == true ){
                        // Iniciar la hoja
                        props.history.push("/checklistwiz/"+id); 
                    }else if( confirmacion == "verplant" ){
                        // Ver la plantilla
                        if( cookies.get('nivel') <= 2 ){
                            // Los administradores a su propia pantalla en modo edición
                            window.open("/documentos/"+id_ori);
                        }else{
                            // El encargado a su propia pantalla solo lectura
                            window.open("/documentosenc/"+id_ori+getLinkReadOnly(linkReadOnly));
                        }
                    }
                })                  
            break            
        }
    }

    const clickPlantilla = (e, rowData) => {

        let id, id_responsable, descripcion;

        if( rowData ){            
            id = rowData.id;           
            id_responsable = rowData.id_responsable; 
            descripcion = rowData.equipo+": "+rowData.descripcion;              
        }else{
            id = e.target.dataset.iddoc;
            id_responsable = e.target.dataset.id_responsable;  
            descripcion = e.target.dataset.equipo+": "+e.target.dataset.descripcion;            
        }

        saveValTrabajador(id_responsable);
        saveIdPlantilla(id);
        saveShowModal(true);
        saveDescripcionDocu(descripcion);
        
    }


    const actionsModal = (action) => {
        switch(action) {
            case 1:
                    // Cancelar
                    saveShowModal(false);              
              break;
            case 2:
                    // Crear hoja
                    createHoja(idPlantilla, false);
              break;
            case 3:
                    // Crear hoJa y ejecutar
                    createHoja(idPlantilla, true);
              break;
            case 4:
                    // Ver la plantilla
                    if( cookies.get('nivel') <= 2 ){
                        // Los administradores a su propia pantalla en modo edición
                        window.open("/documentos/"+idPlantilla);
                    }else{
                        // El encargado a su propia pantalla solo lectura
                        window.open("/documentosenc/"+idPlantilla+getLinkReadOnly(linkReadOnly));
                    }              
              break;              
          }
    }
    
    const createHoja = async(id, goToHoja)=>{
        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", id );
        data.append("idage", valTrabajador );
        data.append("fec2", valFec2 );
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.checklistma.controles.plantillas.plantilla.createchecklist, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.checklistma.controles.plantillas.plantilla.createchecklist, data, source.token);
        
        if( typeof respuesta.data.errorhoja == "string" ){
            // Ya existe una HOJA
            if( cookies.get('nivel') == 3  ){
                // Al responsable lo mandamos a /documentosenc
                alertService.error(`<a href='${window.location.origin}/documentosenc/${respuesta.data.idhoja}'>${respuesta.data.errorhoja}</a>`, {autoClose: false});
            }else if( cookies.get('nivel') == 1 || cookies.get('nivel') == 2 ){
                // A los administradores a /documentos                
                alertService.error(`<a href='${window.location.origin}/documentos/${respuesta.data.idhoja}'>${respuesta.data.errorhoja}</a>`, {autoClose: false});
            }
            return;
        }else if( typeof respuesta.data == "object" ){
            if( !goToHoja ){
                saveReloadKpi(reloadKpi => reloadKpi+1)
                saveShowModal(false);
                alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.checklistma.controles.plantillas.plantilla.successcreatehoja.title}`, {autoClose: true});
            }
            if( goToHoja ){
                // Así lo mantenemos en la misma pantalla y no habría que actualizar la tabla
                props.history.push("/checklistwiz/"+respuesta.data.idClon); 
            }
            
        }else if( typeof respuesta.data == "string" ){
            alertService.error(`${confPantalla.checklistma.controles.plantillas.plantilla.errorcreatehoja.title}`, {autoClose: false});            
        }     
    }
    
    const handleSelectTrabajador = (selectedItem, nameOfComponent) => {

        if( selectedItem ){
            saveValTrabajador(selectedItem.value);  
        }else{
            saveValTrabajador(0);
        }

    }

    const handleFec2 = (e) => {
        const { name, value } = e.target;
        saveValFec2(value)        
    }

    const handleFilter = (selectedItem, nameOfComponent) => {
        
        if( selectedItem ){
            saveValFilter(selectedItem.value);  
            let table = $('#plantillasCheckList').DataTable();
            table.search(selectedItem.value).draw();
        }else{
            saveValFilter("");
            let table = $('#plantillasCheckList').DataTable();
            table.search("").draw();
        }

    }


    return(
        <div className="App wrapper">
                <Alert fondo={true} />    
                {
                    confPantalla !== undefined
                    ?
                        <Fragment>
                            <Menusup 
                                props = { props }
                            />
                            <Sidebar 
                                props = { props }
                            />
                        </Fragment>
                    :
                    null
                } 
            <div className="content-wrapper">
                <div className="content-header"></div>

                <section className="content">
                        {
                            loadedConf === true && confPantalla !== undefined
                            ?
                                <div>
                                    <Kpi
                                        conf={ confPantalla.checklistma.controles.kpi }
                                        getDataTable1 = { confPantalla.checklistma.controles.plantillas.datatable.getdata }
                                        saveCalendar1 = { saveValCalendarPlantillas }
                                        saveDataTable1 = { saveDatosTablePlantillas }
                                        getDataTable2 = { confPantalla.checklistma.controles.hojas.datatable.getdata }
                                        saveCalendar2 = { saveValCalendarHojas }
                                        saveDataTable2 = { saveDatosTableHojas }
                                        saveLoadingTable = { saveLoadingTable }
                                        forceReload={ reloadKpi }
                                    />


                                        <Accordion defaultActiveKey={ cookies.get('defaultActiveKeyCheckList') ? cookies.get('defaultActiveKeyCheckList') : ""  }  >                                            
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => toogleAcordeon('1', "acordeonPrin")} className={ acordeonPrin == "1" ? "activeAcordeon" : "" } >
                                                <i className={ confPantalla.checklistma.controles.hojas.section.icono } ></i> { confPantalla.checklistma.controles.hojas.section.titulo }
                                                {
                                                        acordeonPrin == "1"
                                                        ?
                                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                        :
                                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                    }
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="1" className={"slowAcordeon"}>
                                                    <Card.Body>
                                                            {
                                                                datosTableHojas != null
                                                                ?
                                                                    <Fragment>
                                                                        <div className="row" >
                                                                            <div className="col-12">
                                                                                <div className="row justify-content-md-center"> 
                                                                                    <div className="col-md-12 col-lg-2">
                                                                                        <div className="alert alert-primary alertHelp" role="alert">
                                                                                            {getNumFromData(datosTableHojas, 'alert-primary')}
                                                                                            {confPantalla.checklistma.controles.hojas.leyendapendientes.encurso.title}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-lg-2">
                                                                                        <div className="alert alert-danger alertHelp" role="alert">
                                                                                            {getNumFromData(datosTableHojas, 'alert-danger')}
                                                                                            {confPantalla.checklistma.controles.hojas.leyendapendientes.enrevision.title}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-lg-2">
                                                                                        {/* <div className="alert alert-light alertHelp" role="alert" style={{borderColor: "#888888"}}> */}
                                                                                        <div className="alert alert-secondary alertHelp" role="alert" >
                                                                                            {getNumFromData(datosTableHojas, 'alert-secondary')}
                                                                                            {confPantalla.checklistma.controles.hojas.leyendapendientes.otros.title}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-lg-2">
                                                                                        <div className="alert alert-success alertHelp" role="alert">
                                                                                            {getNumFromData(datosTableHojas, 'alert-success')}
                                                                                            {confPantalla.checklistma.controles.hojas.leyendapendientes.realizado.title}
                                                                                        </div>
                                                                                    </div>                                                       
                                                                                </div>                                                                                    
                                                                                <MiDataTable 
                                                                                    estadoForm= { "" }
                                                                                    formulario={ {id:0} }
                                                                                    id={"hojasCheckList"}
                                                                                    columnas={confPantalla.checklistma.controles.hojas.datatable.columns}
                                                                                    data={datosTableHojas}
                                                                                    linkColumns={confPantalla.checklistma.controles.hojas.datatable.linkColumns}
                                                                                    buttons={confPantalla.checklistma.controles.hojas.datatable.buttons}
                                                                                    ordering={confPantalla.checklistma.controles.hojas.datatable.ordering}
                                                                                    order={confPantalla.checklistma.controles.hojas.datatable.order}
                                                                                    searching={confPantalla.checklistma.controles.hojas.datatable.searching}
                                                                                    paging={confPantalla.checklistma.controles.hojas.datatable.paging}
                                                                                    select={confPantalla.checklistma.controles.hojas.datatable.select}
                                                                                    pagelength={confPantalla.checklistma.controles.hojas.datatable.pagelength}
                                                                                    // saveLoadingTable={ saveLoadingTable }
                                                                                    loadingTable={loadingTable}
                                                                                    columnactions={confPantalla.checklistma.controles.hojas.datatable.columnactions.title}
                                                                                    traduction={confPantalla.config.trad.components.datatable}
                                                                                    functionClickTd={ clickHoja }
                                                                                />                          

                                                                            </div>
                                                                            <div className="col-12 calYearEnc" style={{marginTop: "15px"}}>
                                                                                <Accordion /*defaultActiveKey="0"*/ >
                                                                                    <Card>
                                                                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0', "acordeonCalHojas")}  className={ acordeonCalHojas == "0" ? "activeAcordeon" : "" } >
                                                                                            <i className={ confPantalla.checklistma.controles.hojas.calendario.acordeon.icono } ></i> { confPantalla.checklistma.controles.hojas.calendario.acordeon.title }
                                                                                            {
                                                                                                acordeonCalHojas == "0"
                                                                                                ?
                                                                                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                                                                :
                                                                                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                                                            }                                                                  
                                                                                        </Accordion.Toggle>
                                                                                    </Card>
                                                                                    <Accordion.Collapse eventKey="0" className={"slowAcordeon"}>
                                                                                        <Card.Body>
                                                                                            <CalendarioYear 
                                                                                                data = { valCalendarHojas }
                                                                                                locale = { cookies.get('idioma') }
                                                                                                onDayClick={ true }
                                                                                                colorTitleEvent={ true }
                                                                                                functionClickTitle = { clickHoja }
                                                                                            />
                                                                                        </Card.Body>
                                                                                    </Accordion.Collapse>
                                                                                </Accordion>
                                                                            </div>                                                    
                                                                        </div>
                                                                    </Fragment>
                                                                :
                                                                    null
                                                            }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0', "acordeonPrin")} className={ acordeonPrin == "0" ? "activeAcordeon" : "" }>
                                                    <i className={ confPantalla.checklistma.controles.plantillas.section.icono } ></i> { confPantalla.checklistma.controles.plantillas.section.titulo }
                                                    {
                                                        acordeonPrin == "0"
                                                        ?
                                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                        :
                                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                    }
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0" className={"slowAcordeon"} >
                                                    <Card.Body>
                                                    {
                                                                datosTablePlantillas != null
                                                                ?
                                                                    <Fragment>
                                                                        <div className="row" >
                                                                            <div className="col-12"> 
                                                                                <div className="row" >                                    
                                                                                    <div className="col-4">                                                                                    
                                                                                        <div className="alert alert-success alertHelp text-center" role="alert">    
                                                                                            {getNumFromData(datosTablePlantillas, 'alert-success')}{confPantalla.checklistma.controles.plantillas.helpsuccess.title}
                                                                                        </div>
                                                                                    </div> 
                                                                                    <div className="col-4">                                                                                    
                                                                                        <div className="alert alert-danger alertHelp text-center" role="alert">    
                                                                                            {getNumFromData(datosTablePlantillas, 'alert-danger')}{confPantalla.checklistma.controles.plantillas.helpdanger.title}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4">                                                                                    
                                                                                        <div className="alert alert-secondary alertHelp text-center" role="alert">    
                                                                                            {getNumFromData(datosTablePlantillas, 'alert-secondary')}{confPantalla.checklistma.controles.plantillas.helpsecondary.title}
                                                                                        </div>
                                                                                    </div>
                                                                                    <MiSelect  
                                                                                        estadoForm=  { "" }
                                                                                        val=         { valFilter }   
                                                                                        loading=     { false }
                                                                                        id=          { confPantalla.checklistma.controles.plantillas.filtro.id }    
                                                                                        name=        { confPantalla.checklistma.controles.plantillas.filtro.name }   
                                                                                        label=       { confPantalla.checklistma.controles.plantillas.filtro.label }    
                                                                                        placeholder= { confPantalla.checklistma.controles.plantillas.filtro.placeholder }
                                                                                        col=         { confPantalla.checklistma.controles.plantillas.filtro.col } 
                                                                                        isClearable= { confPantalla.checklistma.controles.plantillas.filtro.isclearable }
                                                                                        isSearchable={ confPantalla.checklistma.controles.plantillas.filtro.issearchable }
                                                                                        dataType=    { confPantalla.checklistma.controles.plantillas.filtro.datatype }
                                                                                        data=        { confPantalla.checklistma.controles.plantillas.filtro.data }
                                                                                        required=    { confPantalla.checklistma.controles.plantillas.filtro.required } 
                                                                                        disabled=  { confPantalla.checklistma.controles.plantillas.filtro.disabled }
                                                                                        readOnly =   { confPantalla.checklistma.controles.plantillas.filtro.readonly }
                                                                                        disablethisid={confPantalla.checklistma.controles.plantillas.filtro.disablethisid}
                                                                                        formulario = { {id:0} }   
                                                                                        change={ handleFilter }   
                                                                                    />                                                                                                    
                                                                                </div>                                                                    
                                                                                <MiDataTable 
                                                                                    estadoForm= { "" }
                                                                                    formulario={ {id:0} }
                                                                                    id={"plantillasCheckList"}
                                                                                    columnas={confPantalla.checklistma.controles.plantillas.datatable.columns}
                                                                                    data={datosTablePlantillas}
                                                                                    linkColumns={confPantalla.checklistma.controles.plantillas.datatable.linkColumns}
                                                                                    buttons={confPantalla.checklistma.controles.plantillas.datatable.buttons}
                                                                                    ordering={confPantalla.checklistma.controles.plantillas.datatable.ordering}
                                                                                    order={confPantalla.checklistma.controles.plantillas.datatable.order}
                                                                                    searching={confPantalla.checklistma.controles.plantillas.datatable.searching}
                                                                                    paging={confPantalla.checklistma.controles.plantillas.datatable.paging}
                                                                                    select={confPantalla.checklistma.controles.plantillas.datatable.select}
                                                                                    pagelength={confPantalla.checklistma.controles.plantillas.datatable.pagelength}                                                                               
                                                                                    // saveLoadingTable={ saveLoadingTable }
                                                                                    loadingTable={loadingTable}
                                                                                    columnactions={confPantalla.checklistma.controles.plantillas.datatable.columnactions.title}
                                                                                    traduction={confPantalla.config.trad.components.datatable}
                                                                                    functionClickTd={ clickPlantilla }
                                                                                    txtFilterTable={ valFilter }
                                                                                />                          
                                                                                </div>
                                                                            <div className="col-12 calYearEnc" style={{marginTop: "15px"}}>
                                                                                <Accordion /*defaultActiveKey="0"*/ >
                                                                                    <Card>
                                                                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0', "acordeonCalPlantillas")} className={ acordeonCalPlantillas == "0" ? "activeAcordeon" : "" } >
                                                                                            <i className={ confPantalla.checklistma.controles.plantillas.calendario.acordeon.icono } ></i> { confPantalla.checklistma.controles.plantillas.calendario.acordeon.title }
                                                                                            {
                                                                                                acordeonCalPlantillas == "0"
                                                                                                ?
                                                                                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                                                                :
                                                                                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                                                            }
                                                                                        </Accordion.Toggle>
                                                                                    </Card>
                                                                                    <Accordion.Collapse eventKey="0" className={"slowAcordeon"} >
                                                                                        <Card.Body>
                                                                                            <CalendarioYear 
                                                                                                data = { valCalendarPlantillas }
                                                                                                locale = { cookies.get('idioma') }
                                                                                                onDayClick={ true }
                                                                                                colorTitleEvent={ true }
                                                                                                functionClickTitle = { clickPlantilla }
                                                                                            />
                                                                                        </Card.Body>
                                                                                    </Accordion.Collapse>
                                                                                </Accordion>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                :
                                                                    null
                                                            }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>                       
                                </div>                             
                            :
                                <LoadingPage />
                        }
                </section>
            </div>
            <Footer />
            {
                    showModal
                    ?
                        <Modal
                            size="lg"
                            show={true}
                            onHide={ () => saveShowModal(false)}                            
                            aria-labelledby="example-custom-modal-styling-title"
                            centered={true}
                            contentClassName="likeSw"
                            backdropClassName="backLikeSw"                                              
                        >                          
                            <Modal.Body>
                                <div className="swal-icon swal-icon--warning">
                                    <span className="swal-icon--warning__body">
                                    <span className="swal-icon--warning__dot"></span>
                                    </span>
                                </div>
                                <div className="swal-text">{confPantalla.checklistma.controles.plantillas.plantilla.modaltitle.title}</div>
                                <h6 className='text-center'>{descripcionDocu}</h6>
                                <MiSelect  
                                    estadoForm=  { "" }
                                    val=         { valTrabajador }   
                                    loading=     { false }
                                    id=          { confPantalla.checklistma.controles.plantillas.idage2.id }    
                                    name=        { confPantalla.checklistma.controles.plantillas.idage2.name }   
                                    label=       { confPantalla.checklistma.controles.plantillas.idage2.label }    
                                    placeholder= { confPantalla.checklistma.controles.plantillas.idage2.placeholder }
                                    col=         { confPantalla.checklistma.controles.plantillas.idage2.col } 
                                    isClearable= { confPantalla.checklistma.controles.plantillas.idage2.isclearable }
                                    isSearchable={ confPantalla.checklistma.controles.plantillas.idage2.issearchable }
                                    dataType=    { confPantalla.checklistma.controles.plantillas.idage2.datatype }
                                    data=        { confPantalla.checklistma.controles.plantillas.idage2.data }
                                    required=    { confPantalla.checklistma.controles.plantillas.idage2.required } 
                                    disabled=    { confPantalla.checklistma.controles.plantillas.idage2.disabled }
                                    readOnly =   { confPantalla.checklistma.controles.plantillas.idage2.readonly }
                                    disablethisid={confPantalla.checklistma.controles.plantillas.idage2.disablethisid}
                                    disableids=   {confPantalla.checklistma.controles.plantillas.idage2.disableids}
                                    formulario= { {id:0} }   
                                    change= { handleSelectTrabajador }
                                />
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { valFec2 }
                                    loading=    { false }
                                    id=         { confPantalla.checklistma.controles.plantillas.fec2.id }   
                                    type=       { "date" }  
                                    name=       { confPantalla.checklistma.controles.plantillas.fec2.name }   
                                    label=      { confPantalla.checklistma.controles.plantillas.fec2.label }                                                       
                                    placeholder={ confPantalla.checklistma.controles.plantillas.fec2.placeholder }    
                                    col=        { confPantalla.checklistma.controles.plantillas.fec2.col } 
                                    required=   { confPantalla.checklistma.controles.plantillas.fec2.required }
                                    readonly=   { confPantalla.checklistma.controles.plantillas.fec2.readonly }                                                
                                    disabled=   { confPantalla.checklistma.controles.plantillas.fec2.disabled }
                                    change=     { handleFec2 }
                                    onBlur=     {null} 
                                />
                                <div className='form-group col-md-12'>
                                    <Informes
                                        select =     { confPantalla.checklistma.controles.plantillas.informes.select }
                                        formulario = { {id: idPlantilla} }
                                        getRegistro = { confPantalla.checklistma.controles.plantillas.informes.getregistro }
                                        loadReg =    { false }
                                        estadoForm=  { "" } 
                                        traduction=  { confPantalla.config.trad.components }
                                        btnGenerar= { confPantalla.checklistma.controles.plantillas.informes.btngenerar.title }
                                    />
                                </div>
                                <div className='text-right'>
                                    <button 
                                        className="swal-button swal-button--cancel btn btn-flat mr-1 mt-1 btn-outline-secondary"
                                        onClick={ e => actionsModal(1) }                                        
                                    >
                                        {confPantalla.checklistma.controles.plantillas.plantilla.btncancelar.title}
                                    </button>
                                    {
                                    respSimp == "0"
                                    ?
                                        <button
                                            className="swal-button swal-button--confirm btn btn-flat mr-1 mt-1 btn-outline-success btnSwalSuccess"
                                            disabled={ valTrabajador == "0" || valFec2 == "" ? true : false }
                                            onClick={ e => actionsModal(2) }
                                        >
                                            {confPantalla.checklistma.controles.plantillas.plantilla.btncreatehoja.title}
                                        </button>
                                    :
                                        null
                                    }
                                    <button 
                                        className="swal-button swal-button--confirm btn btn-flat mr-1 mt-1 btn-outline-success btnSwalSuccess"
                                        disabled={ valTrabajador == "0" || valFec2 == "" ? true : false }
                                        onClick={ e => actionsModal(3) }
                                        >
                                        {confPantalla.checklistma.controles.plantillas.plantilla.btncreatehojaandgo.title}
                                    </button>
                                    <button 
                                        className="swal-button swal-button--verplant btn btn-flat mr-1 mt-1 btn-outline-warning btnSwalWarning"
                                        onClick={ e => actionsModal(4) }
                                    >
                                        {confPantalla.checklistma.controles.plantillas.plantilla.btnverplantilla.title}
                                    </button>                                                                                          
                                </div>
                            </Modal.Body>                            
                        </Modal>                
                    :
                        null
                }        
        </div>
    );
}

export default CheckListMa